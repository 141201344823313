import React from 'react';
import style from './style.module.scss';
import UserInformation from './UserInformation/UserInformation.jsx';
import KycActionButtons from './KycActionButtons';

function KycVerification({ setIsMngView, data, setActiveKey }) {
  console.log('kmkmkmk   ', { data });

  const getKycStatus = (data) => {
    if (data?.user && data?.user?.accountType === 'BUSINESS') {
      const { adminKycStatus, sumsubKycStatus } = data?.kyc?.businessProfile;
      return { adminKycStatus, sumsubKycStatus };
    } else if (data?.user && data?.user?.accountType === 'INDIVIDUAL') {
      const { adminKycStatus, sumsubKycStatus } = data?.kyc?.individualProfile;
      return { adminKycStatus, sumsubKycStatus };
    } else {
      return { adminKycStatus: '', sumsubKycStatus: '' };
    }
  };

  const { adminKycStatus, sumsubKycStatus } = getKycStatus(data);

  return (
    <>
      <div className={style.kycverifcation}>
        <div className={style.kycverifcation_kyc}>
          <p>KYC Verification</p>
            <KycActionButtons
              className={style.kycverifcation_kyc_buttons}
              adminKycStatus={adminKycStatus}
              sumsubKycStatus={sumsubKycStatus}
              setIsMngView={setIsMngView}
              setActiveKey={setActiveKey}
            />
        </div>
        <UserInformation truecheckbox Checkbox={true} data={data} />
      </div>
    </>
  );
}

export default KycVerification;
