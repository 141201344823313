import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Select, Input, DatePicker, Button } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import toast from '../../../services/toast.service';
import { CONSTANTS } from '../../../constants';
import { formatTxnDate } from '../../../utils/common.utils';
import dayjs from 'dayjs';
import { SettingActions } from '../../../redux/features/setting/settingActions';
import {
  finishLoading,
  startLoading,
} from '../../../redux/features/loading/loadingSlice';
import { useDispatch } from 'react-redux';
import ButtonCommon from '../../../Components/Buttoncommon/ButtonCommon';
import InputCustom from '../../../Components/InputCustom/InputCustom';

const SwapTransactionFilter = ({
  getSwapTransactionsOnly,
  setFilterData,
  setPage,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm({
    mode: 'all',
  });

  const location = useLocation();
  const [currencyArr, setCurrencyArr] = useState([]);
  const obj = { ...location.state };
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    coin: obj.coin,
    page: obj.page,
    fromDate: obj.fromDate,
    toDate: obj.toDate,
    txStatus: obj.txStatus,
    type: obj.type,
  });

  console.log({ filter:location.state?.offset });

  const onSubmit = (values) => {
    let isFiltersValid = validateFilters(values);
    if (isFiltersValid) {
      if (values.toDate && !values.fromDate) {
        toast.error('Please select from date.');
      }
      if (values.fromDate && !values.toDate) {
        setValue('toDate', dayjs(new Date()));
      }
      const data = {
        // offset: location.state?.offset || 1,
        offset: 0,
        limit: 10,
        fromDate: values.fromDate ? formatTxnDate(values.fromDate) : '',
        toDate: values.toDate
          ? formatTxnDate(values.toDate)
          : formatTxnDate(new Date()),
        coin: values.coin ? values.coin : '',
        status: values.status ? values.status : '',
        searchBy:values.searchBy
      };

      if (values.status === 'ALL') {
        delete data.status;
      }
      if (!values.fromDate) {
        delete data.toDate;
      }
      setFilterData(data);
      getSwapTransactionsOnly(data);
    }
  };

  const checkEmptyFilters = (data) => {
    let filterMessage = '';
    let isFiltersValid = true;
    isFiltersValid = Object.values(data).some((value) => !!value);
    if (!isFiltersValid) {
      filterMessage = 'Please select filters first';
      toast.error(filterMessage);
    }
    return isFiltersValid;
  };

  const validateFilters = (data) => {
    const {
      VALIDATION_ERRORS: { START_DATE_GREATER_THAN_END_DATE },
    } = CONSTANTS;
    let isFiltersValid = checkEmptyFilters(data);
    let filterMessage = '';
    if (
      new Date(data.fromDate).getDate() > new Date(data.toDate).getDate() &&
      new Date(data.fromDate).getMonth() > new Date(data.toDate).getMonth()
    ) {
      isFiltersValid = false;
      filterMessage = START_DATE_GREATER_THAN_END_DATE;
      toast.error(filterMessage);
    }
    return isFiltersValid;
  };

  const clearFilters = () => {
    let isFiltersValid = checkEmptyFilters(getValues());
    if (isFiltersValid) {
      reset();
      setValue('searchBy','')
      const data = {
        offset: 0,
        limit: 10,
        fromDate: '',
        toDate: '',
      };
      setFilterData({})
      setPage(1);
      getSwapTransactionsOnly(data);
    }
  };

  const setRangePickerValue = () => {
    return {
      fromDate: dayjs(filter.fromDate),
      toDate: dayjs(filter.toDate),
    };
  };

  useEffect(() => {
    console.log('filters:- ', filter);
    setValue('coin', filter.coin);
    if (filter.fromDate) {
      setValue('fromDate', setRangePickerValue().fromDate);
    }
    if (filter.toDate) {
      setValue('toDate', setRangePickerValue().toDate);
    }
    setValue('status', filter.txStatus);
  }, [filter]);

  const getWithdrawFee = async () => {
    try {
      await dispatch(startLoading());

      const res = await dispatch(
        SettingActions.getWithdrawFee({ apiAccessKey: 'ALL_TRANSACTIONS' })
      ).unwrap();
      if (res) {
        const result = res.map((item) => {
          console.log('item::', item);
          return {
            label: item.coin.toUpperCase(),
            value: item.coin.toUpperCase(),
          };
        });
        setCurrencyArr([
          {
            label: 'EUR',
            value: 'EUR',
          },
          ...result,
        ]);
      } else {
        setCurrencyArr([]);
      }
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  useEffect(() => {
    getWithdrawFee();
  }, []);

  return (
    <form
      style={{ display: 'inline-flex', gap: '15px' }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      {/* <Controller
        name="search"
        control={control}
        render={({ field }) => (
          <Input
            style={{ width: "230px" }}
            prefix={<SearchOutlined />}
            type="search"
            {...field}
            placeholder="Search by: TXN ID, Address"
          />
        )}
      /> */}

      <Controller
        name='fromDate'
        control={control}
        render={({ field }) => (
          <DatePicker {...field} placeholder='From date' />
        )}
      />

      <Controller
        name='toDate'
        control={control}
        render={({ field }) => <DatePicker {...field} placeholder='To date' />}
      />
      <div style={{ minWidth: '320px' }}>
        <Controller
          name='searchBy'
          control={control}
          render={({ field }) => (
            <>
              <InputCustom
                type='text'
                settingInput='settingInput'
                custommClass1='custommClass1'
                placeholder={' Client Id / Txn Id'}
                borderRadius={10}
                height={48}
                {...field}
              />
            </>
          )}
        />
      </div>

      <Controller
        name='coin'
        control={control}
        render={({ field }) => (
          <Select
            placeholder='Currencies'
            {...field}
            style={{
              minWidth: '150px',
            }}
            options={currencyArr}
          />
        )}
      />

      <Controller
        name='status'
        control={control}
        render={({ field }) => (
          <Select
            placeholder='Status'
            {...field}
            style={{
              minWidth: '150px',
            }}
            options={[
              // { value: "", label: "", disabled: true },
              {
                value: 'ALL',
                label: 'ALL',
              },
              {
                value: 'COMPLETED',
                label: 'COMPLETED',
              },
              {
                value: 'FAILED',
                label: 'FAILED',
              },
            ]}
          />
        )}
      />
      <ButtonCommon
        // onClick={toggleFilterVisibility}
        text='Submit'
        type='primary'
        transBtn='transBtn'
        htmlType={'submit'}
      />

      <ButtonCommon
        className='filter-btn'
        // onClick={toggleFilterVisibility}
        type={'default'}
        onClick={clearFilters}
        htmlType='button'
        reject={'reject'}
      >
        <CloseOutlined />
      </ButtonCommon>
    </form>
  );
};

export default SwapTransactionFilter;
