import { DatePicker, Select } from 'antd';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputCustom from '../../../../Components/InputCustom/InputCustom';
import ButtonCommon from '../../../../Components/Buttoncommon/ButtonCommon';
import { CloseOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { formatDate } from '../../../../utils/common.utils';
import dayjs from 'dayjs';
import { CONSTANTS } from '../../../../constants';
import { useSelector } from 'react-redux';

function VerificationScreeningFilter({
  setPage,
  setFilterData,
  pageSize,
  getComplianceKyc,
  countryDropDownobj,
}) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm({
    mode: 'all',
  });

  const sanctionVerificationTab = useSelector(
    (state) => state?.compliance?.selectedVerificationTab
  );

  const { COMPLIANCE_KYC_STATUS } = CONSTANTS;
  const USER_TYPE = {
    // ALL: '',
    BUSINESS: 'BUSINESS',
    INDIVIDUAL: 'INDIVIDUAL',
  };

  const setSelectLib = (lib) =>
    Object.keys(lib).map((key) => ({ value: key, label: lib[key] }));
  const validateFilters = (data) => {
    let isFiltersValid = checkEmptyFilters(data);

    return isFiltersValid;
  };
  const onSubmit = (values) => {
    console.log('FILTER DATA:', values);
    let isFiltersValid = validateFilters(values);
    console.log('isFiltersValid::', values);
    if (!isFiltersValid) return;
    values = convertFilterValues(values);
    // values.type = type;
    if (Object.keys(values).length > 0) {
      setFilterData(values);
      getComplianceKyc(values);
      setPage(1);
    }
  };

  const convertFilterValues = (data) => {
    let values = { ...data };

    // if (values.txnStatus === undefined) values.txnStatus = 'ALL';
    values.offset = 0;
    values.limit = pageSize || 10;
    if (values.startDate && !values.endDate)
      values.endDate = formatDate(new Date().toDateString());
    if (values.endDate && !values.startDate)
      return toast.error('Please select from date');

    if (values.startDate) values.startDate = formatDate(values.startDate);
    if (values.endDate) {
      values.endDate = formatDate(values.endDate);
      setValue('endDate', dayjs(values.endDate));
    }
    return values;
  };

  const checkEmptyFilters = (data) => {
    let filterMessage = '';
    let isFiltersValid = true;
    isFiltersValid = Object.values(data).some((value) => !!value);
    if (!isFiltersValid) {
      if (data && data.length === 0) {
        isFiltersValid = true;
      } else {
        filterMessage = 'Please select filters first';
        toast.error(filterMessage);
      }
    }

    return isFiltersValid;
  };

  const clearFilters = () => {
    let isFiltersValid = checkEmptyFilters(getValues());
    if (isFiltersValid) {
      reset();
      setValue('searchBy', '');
      let data = {
        offset: 0,
        limit: 10,
      };
      setPage(1);
      setFilterData(data);
      getComplianceKyc(data);
    }
  };

  useEffect(() => {
   reset()
   setValue('searchBy', '');
  }, [sanctionVerificationTab])
  

  return (
    <form
      style={{ display: 'inline-flex', gap: '15px', flexFlow: 'wrap' }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Controller
        name='startDate'
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            placeholder='From date'
            style={{ height: '48px' }}
          />
        )}
      />
      <Controller
        name='endDate'
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            placeholder='To date'
            style={{ height: '48px' }}
          />
        )}
      />
      <div style={{ minWidth: '220px' }}>
        <Controller
          name='searchBy'
          control={control}
          render={({ field }) => (
            <>
              <InputCustom
                type='text'
                settingInput='settingInput'
                custommClass1='custommClass1'
                placeholder={'User ID / Email / Name '}
                borderRadius={10}
                height={48}
                {...field}
              />
            </>
          )}
        />
      </div>
      <Controller
        name='nationality'
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            placeholder='Nationality'
            style={{ width: '140px' }}
            options={countryDropDownobj}
          />
        )}
      />
      <Controller
        name='accountType'
        control={control}
        render={({ field }) => (
          <Select
            placeholder='Account Type'
            // defaultValue={'ALL'}
            {...field}
            style={{
              minWidth: '150px',
            }}
            options={setSelectLib(USER_TYPE)}
          />
        )}
      />
      <Controller
        name='kycStatus'
        control={control}
        render={({ field }) => (
          <Select
            placeholder='KYC Status'
            // defaultValue={'ALL'}
            {...field}
            style={{
              minWidth: '150px',
            }}
            options={setSelectLib(COMPLIANCE_KYC_STATUS)}
          />
        )}
      />

      <div className='d-flex'>
        <ButtonCommon
          text='Submit'
          type='primary'
          transBtn='transBtn'
          htmlType={'submit'}
        />

        <ButtonCommon
          className='filter-btn'
          reject='reject'
          onClick={clearFilters}
          htmlType='button'
        >
          <CloseOutlined />
        </ButtonCommon>
      </div>
    </form>
  );
}

export default VerificationScreeningFilter;
