import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedComplianceTab,
  setSelectedSanctionTab,
  setSelectedVerificationTab,
} from '../../../redux/features/compliance/complianceSlice';
import {
  finishLoading,
  startLoading,
} from '../../../redux/features/loading/loadingSlice';
import { ComplianceActions } from '../../../redux/features/compliance/complianceAtion';
import { showName } from '../../../services/user.service';
import { formatDateTime } from '../../../utils/common.utils';
import Completed from './Completed/Completed';
import Rejected from './Rejected/Rejected';
import { KycActions } from '../../../redux/features/kyc/kycActions';
import { setSelectedKYC } from '../../../redux/features/kyc/kycSlice';
import KycVerification from '../../KycVerification/KycVerification';
import { UserActions } from '../../../redux/features/user/userActions';
import { setSelectedApiData } from '../../../redux/features/user/userSlice';
import RecentActivityFull from '../../../Components/recentactivity/RecentActivityFull';
import toast from '../../../services/toast.service';
import { useNavigate } from 'react-router-dom';
import SanctionKyc from '../SanctionKyc/SanctionKyc';

const VerificationsScreeningMain = ({ type }) => {
  const [complianceKyc, setComplianceKyc] = useState({
    list: [],
    totalCount: 0,
  });
  const [countryDropDownobj, setCountryDropdownObj] = useState([]);

  const sanctionVerificationTab = useSelector(
    (state) => state?.compliance?.selectedVerificationTab
  );

  const [isMngView, setIsMngView] = useState(false);
  const [activeKey, setActiveKey] = useState('1');

  const onChange = (key) => {
    setActiveKey(key);
    handleSelectedTab(key === '2' ? 'Completed' : 'Rejected');
  };

  const dispatch = useDispatch();

  const handleSelectedTab = (value) => {
    dispatch(setSelectedVerificationTab(value));
  };

  useEffect(() => {
    if (sanctionVerificationTab == 'Rejected') {
      setActiveKey('1');
    } else {
      setActiveKey('2');
    }
  }, [sanctionVerificationTab]);

  console.log(sanctionVerificationTab, 'sanctionVerificationTab');
  const [selectedData, setSelectedData] = useState(null);
  const navigate = useNavigate();
  const getUserDetails = async (id) => {
    try {
      const data = await dispatch(
        ComplianceActions.getUserDetail({ userId: id })
      ).unwrap();
      if (data && data?.user && data?.kyc) {
        setSelectedData(data);
        navigate('/sanction-report-kyc', { state: { data: data } });
        dispatch(setSelectedApiData(data));
      } else {
        toast.error('No data found');
        return;
      }
    } catch (error) {}
  };

  const showVerificationDetails = async (userId) => {
    try {
      await dispatch(setSelectedKYC({ userId }));
      await getUserDetails(userId);
      setIsMngView(true);
      // dispatch(setSelectedSanctionTab("Rejected KYC"));
    } catch (error) {}
  };

  const formatScreeningData = (data) => {
    if (data && data.length > 0) {
      return data.map((x) => {
        return {
          ...x,
          name: showName(x),
          createdAt: formatDateTime(x.createdAt),
          updatedAt: formatDateTime(x.updatedAt),
        };
      });
    }
    return [];
  };

  const getComplianceKyc = async (data) => {
    data.type =
      sanctionVerificationTab === 'Rejected' ? 'REJECTED' : 'APPROVED';
    try {
      dispatch(startLoading());
      const res = await dispatch(
        ComplianceActions.getComplianceKyc(data)
      ).unwrap();

      if (res && (res.list || res.totalCount)) {
        setComplianceKyc({
          list: formatScreeningData(res.list),
          totalCount: res.totalCount,
        });
      } else if (
        res &&
        (+res.totalCount === 0 || !res.list || res.list.length === 0)
      ) {
        setComplianceKyc({
          list: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const [sanctionAnalytics, setSanctionAnalytics] = useState({
    alertData: {
      rows: [],
      count: 0,
    },
  });
  const getComplianceAnalytics = async (data) => {
    try {
      dispatch(startLoading());
      const res = await dispatch(
        ComplianceActions.getComplianceAnalytics(data)
      ).unwrap();

      setSanctionAnalytics({
        alertData: res.allAlerts,
      });
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  const getCountryLists = async () => {
    try {
      await dispatch(startLoading());

      const res = await dispatch(UserActions.getCountryList()).unwrap();
      if (res) {
        let contryObjDropdown = res?.list.map((item) => {
          return {
            label: item.charAt(0).toUpperCase() + item.substr(1),
            value: item,
          };
        });
        setCountryDropdownObj(contryObjDropdown);
      } else {
        setCountryDropdownObj([]);
      }
      await dispatch(finishLoading());
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const items = [
    {
      key: '1',
      label: 'Pending',
      children: (
        <>
          <Rejected
            complianceKyc={complianceKyc}
            showVerificationDetails={showVerificationDetails}
            getComplianceKyc={getComplianceKyc}
            countryDropDownobj={countryDropDownobj}
          />
        </>
      ),
    },
    {
      key: '2',
      label: 'Completed',
      children: (
        <Completed
          complianceKyc={complianceKyc}
          showVerificationDetails={showVerificationDetails}
          getComplianceKyc={getComplianceKyc}
          countryDropDownobj={countryDropDownobj}
        />
      ),
    },
  ];

  useEffect(() => {
    if (!isMngView) {
      let data = {
        offset: 0,
        limit: 10,
        completedByUserId: '',
        type: 'REJECTED',
      };
      if (activeKey === '1') {
        getComplianceKyc(data);
      } else if (activeKey === '2') {
        data.type = 'APPROVED';
        getComplianceKyc(data);
      }
    }
  }, [activeKey, isMngView]);

  useEffect(() => {
    getCountryLists();
  }, []);

  return (
    <>
      <div className='Custom-tabBtn'>
        <Tabs
          defaultActiveKey='1'
          items={items}
          onChange={onChange}
          animated={true}
          activeKey={activeKey}
        />
      </div>
    </>
  );
};

export default VerificationsScreeningMain;
