import React, { useState } from 'react';
import { Table } from 'antd';
import { TransactionFeeService } from '../../../services/transaction-fee.service';

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const LiquidityCrypto = ({ data }) => {
  console.log('data::', data);
  const sortedData = data.sort((a, b) => {
    const coinA = a.coin.split('(')[0].toUpperCase();
    const coinB = b.coin.split('(')[0].toUpperCase();
    return coinA.localeCompare(coinB); // Ascending order
  });
  const columns = [
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      render: (_, record) => {
        let coin = record.coin.split('(');

        return (
          <div>
            <span
              style={{
                display: 'inline-flex',
                gap: '5px',
                alignItems: 'center',
              }}
            >
              <img
                alt={record.coin}
                src={record.coinUrl}
                style={{ width: '30px', height: 'auto' }}
              />
              {coin[0].toUpperCase()}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Network',
      dataIndex: 'network',
      key: 'network',
    },
    // {
    //   title: "Deposit",
    //   dataIndex: "deposit",
    //   key: "deposit",
    // },
    // {
    //   title: "Hot",
    //   dataIndex: "hot",
    //   key: "hot",
    // },
    {
      title: 'Fee Collected',
      dataIndex: 'fee',
      key: 'fee',
      render: (_, record) => {
        let coin = record.coin.split('(');
        return `${record.fee} ${coin[0].toUpperCase()}`;
      },
    },
    {
      title: 'Master Wallet Balance',
      dataIndex: 'totalBalance',
      key: 'totalBalance',
      render: (_, record) => {
        return record.totalBalance;
      },
    },
    {
      title: 'Total price in USDT',
      dataIndex: 'usdValue',
      key: 'usdValue',
      render: (_, record) => {
        return record.usdValue > 0 ? Number(record.usdValue).toFixed(3) : 0;
      },
    },
    {
      // title: "Fee Wallet",
      title: 'Gas Station',
      dataIndex: 'gasFeeBalance',
      key: 'gasFeeBalance',
      render: (_, record) => {
        let coin = record.coin.split('(');
        return `${record.gasFeeBalance ? record.gasFeeBalance : 0} ${coin[0].toUpperCase()}`;
        // return record.gasFeeBalance ? record.gasFeeBalance : 0;
      },
    },
  ];
  return (
    <>
      <div className='table-design'>
        <div className='custom-table'>
          <Table
            dataSource={sortedData}
            columns={columns}
            pagination={false}
            //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>
    </>
  );
};

export default LiquidityCrypto;
