import React from 'react';
import style from '../../KycVerification/style.module.scss';
import PermissionCheck from '../../../Components/PermissionCheck/PermissionCheck';
import KycActionButtons from '../../KycVerification/KycActionButtons';
import UserInformation from '../../KycVerification/UserInformation/UserInformation';
import { useLocation, useNavigate } from 'react-router-dom';

function SanctionKyc() {
  const location = useLocation();
  console.log(location?.state?.data?.user?.accountType, 'ssssssssslocation');
  const getKycStatus = () => {
    if (
      location?.state?.data?.user &&
      location?.state?.data?.user?.accountType === 'BUSINESS'
    ) {
      const { adminKycStatus, sumsubKycStatus } =
        location?.state?.data?.kyc?.businessProfile || {};
      return { adminKycStatus, sumsubKycStatus };
    } else if (
      location?.state?.data?.user &&
      location?.state?.data?.user?.accountType === 'INDIVIDUAL'
    ) {
      const { adminKycStatus, sumsubKycStatus } =
        location?.state?.data?.kyc?.individualProfile || {};
      return { adminKycStatus, sumsubKycStatus };
    } else {
      return { adminKycStatus: '', sumsubKycStatus: '' };
    }
  };

  const { adminKycStatus, sumsubKycStatus } = getKycStatus();

  return (
    <>
      <div className={style.kycverifcation}>
        <div className={style.kycverifcation_kyc}>
          <p>KYC Verification</p>

          {/* <div></div> */}
          <PermissionCheck>
            <KycActionButtons
              className={style.kycverifcation_kyc_buttons}
              adminKycStatus={adminKycStatus}
              sumsubKycStatus={sumsubKycStatus}
            />
          </PermissionCheck>
        </div>
        <UserInformation
          truecheckbox
          Checkbox={true}
          data={location?.state?.data}
        />
      </div>
    </>
  );
}

export default SanctionKyc;
