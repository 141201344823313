import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import { Checkbox, Space, Typography } from 'antd';
import Edit from '../../../Assets/Images/greyEdit.svg';
import EditAddtionalDocuments from './EditAddtionalDocuments';
import { DeleteIcon } from '../../../Assets/Icons/Icons';
import ButtonCommon from '../../../Components/Buttoncommon/ButtonCommon';
import DeleteAdditionalDocuments from './DeleteAdditionalDocuments';
import { useLocation } from 'react-router-dom';
import { formatDocumentName } from '../../../constants/commonFunction';

const { Title } = Typography;

function UserInfo(props) {
  const [documentDocs, setDocumentDocs] = useState([]);
  const [showEditDocModal, setShowEditDocModal] = useState(false);
  const [showDeleteDocModal, SetShowDeleteDocModal] = useState(false);
  const [deleteAdditionalDocDetails, setDeleteAdditionalDocDetails] =
    useState('');
  const [editDocDetails, setEditDocDetails] = useState('');
  const location = useLocation();
  const txnId = location.state?.txnId;

  const createDocumentsFromPromise = async (data) => {
    let newData = await data;
    console.log('props.list',newData);
    let docs = [];
    if (
      location.pathname === '/user-directory-view' ||
      location.pathname === '/user-verification' ||
      location.pathname === '/sanction-report-kyc'
    ) {
      if (props.kycData?.individualProfile) {
        docs = props.kycData?.individualProfile?.individualDocs || [];
      } else if (props.kycData?.businessProfile) {
        docs = props.kycData?.businessProfile?.businessDocs || [];
      }
      
      newData = newData.map((x, index) => {
        let findDoc = docs.find((doc) => doc.id === x.docId);
        return {
          ...x,
          docType: findDoc?.docType || '',
          userId: findDoc?.userId || '',
          id: findDoc?.id || '',
          docName: findDoc?.docName || x.docName,
          docPath: findDoc?.docPath || '',
        };
      });
    } else if (location.pathname.indexOf('transactions-main-detail') !== -1) {
    }
    console.log('new documents ::::',newData);
    
    setDocumentDocs(newData);
  };

  const handleEditAddtionDoc = () => {
    setShowEditDocModal(true);
  };

  const showdeleteAdditionalDocModal = (record) => {
    setDeleteAdditionalDocDetails(record);
    SetShowDeleteDocModal(true);
  };

  const renderLists = () => {
    if (!props.type) {
      // render normal list
      return props.list.map((point) => {
        return (
          <>
            <li key={point.id}>
              <span>{point.title}</span>
              <span>{point.about}</span>
            </li>
          </>
        );
      });
    } else if (props.type === 'promise') {
      // render ubo documents
      return documentDocs.map((doc, index) => (
        <li key={doc.id}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{doc && formatDocumentName(doc)}</span>
            {location.pathname === '/user-directory-view' ||
            location.pathname === '/sanction-report-kyc' ||
            location.pathname === '/user-verification' ? (
              <>
                {doc.docType === 'ADDITIONAL' && (
                  <Space size={0}>
                    <img
                      style={{
                        cursor: 'pointer',
                        marginLeft: '12px',
                        marginTop: '-15px',
                      }}
                      src={Edit}
                      alt="icon"
                      onClick={() => {
                        handleEditAddtionDoc(doc);
                        setEditDocDetails(documentDocs[index]);
                      }}
                    />
                    <div style={{ color: 'red', marginTop: '-10px' }}>
                      <ButtonCommon
                        icon={<DeleteIcon />}
                        onClick={() =>
                          showdeleteAdditionalDocModal(documentDocs[index])
                        }
                        type="text"
                      />
                    </div>
                  </Space>
                )}
              </>
            ) : (
              <Space size={0}>
                <img
                  style={{
                    cursor: 'pointer',
                    marginLeft: '12px',
                    marginTop: '-15px',
                  }}
                  src={Edit}
                  alt="icon"
                  onClick={() => {
                    handleEditAddtionDoc(doc);
                    setEditDocDetails(documentDocs[index]);
                  }}
                />
                <div style={{ color: 'red', marginTop: '-10px' }}>
                  <ButtonCommon
                    icon={<DeleteIcon />}
                    onClick={() =>
                      showdeleteAdditionalDocModal(documentDocs[index])
                    }
                    type="text"
                  />
                </div>
              </Space>
            )}
          </div>
          <span>{doc.about}</span>
        </li>
      ));
    } else if (props.type === 'number') {
      // render list with numbers
      return props.list.map((point) => (
        <>
          <li key={point.id} style={{ display: 'block' }}>
            <span>{point.id}&nbsp; :- </span>
            <span>{point.title}</span>
            <span>{point.about}</span>
          </li>
        </>
      ));
    }
  };

  useEffect(() => {
    if (props.type && props.type === 'promise') {
      createDocumentsFromPromise(props.list);
    }
  }, [props]);

  return (
    <div className={style.userInformation}>
      {props.Checkbox ? (
        <Checkbox>{props.checkdata}</Checkbox>
      ) : (
        <Title level={props.level || 5}>{props.checkdata}</Title>
      )}
      <ul>
        <div style={{ marginTop: '4 0px' }}></div>
        {renderLists()}
      </ul>
      <EditAddtionalDocuments
        showEditDocModal={showEditDocModal}
        setShowEditDocModal={setShowEditDocModal}
        editDocDetails={editDocDetails}
        setEditDocDetails={setEditDocDetails}
      />
      <DeleteAdditionalDocuments
        SetShowDeleteDocModal={SetShowDeleteDocModal}
        showDeleteDocModal={showDeleteDocModal}
        deleteAdditionalDocDetails={deleteAdditionalDocDetails}
        setDeleteAdditionalDocDetails={setDeleteAdditionalDocDetails}
      />
    </div>
  );
}

export default UserInfo;
