import { Pagination, Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  finishLoading,
  startLoading,
} from '../../redux/features/loading/loadingSlice';
import { SettingActions } from '../../redux/features/setting/settingActions';
import Edit from '../../Assets/Images/greyEdit.svg';
import PermissionCheck from '../../Components/PermissionCheck/PermissionCheck';
function ReferralProgramLists({
  setEditreferralItem,
  getReferralList,
  referralProgramList,
}) {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    const referralList = async () => {
      await getReferralList();
    };
    referralList();
  }, []);
  console.log('referralProgramList::', referralProgramList);
  const onStatusUpdate = async (e, values) => {
    try {
      let updatedPayload = {
        // accountType: values?.accountType.toUpperCase(),
        pointsType: values?.pointsType.toUpperCase(),
        refType: values?.refType.toUpperCase(),
        points: Number(values?.points),
        minimumDepositValue: Number(values.minimumDepositValue),
        status: e,
      };

      //   if (updatedPayload?.pointsType !== 'DEPOSIT') {
      //     delete updatedPayload.minimumDepositValue;
      //   }
      await dispatch(startLoading());
      await dispatch(SettingActions.updateReferralList(updatedPayload));
      await getReferralList();
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const handlePageChange = async (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    const data = {
      offset: (page - 1) * pageSize,
      size: pageSize,
      // filter: location.state?.filter,
    };
    await getReferralList();
  };

  const columns = [
    // {
    //   title: 'Account Type',
    //   dataIndex: 'accountType',
    //   key: 'accountType',
    // },

    {
      title: 'Reward Type',
      dataIndex: 'pointsType',
      key: 'pointsType',
      render: (_, record) => {
        return record.pointsType === 'DEPOSIT' ? 'First Deposit' : 'Approved KYC';
      },
    },
    {
      title: 'Minimum Deposit',
      render: (_, record) => {
        return record.pointsType === 'DEPOSIT'
          ? `$ ${record?.minimumDepositValue}`
          : '--';
      },
    },
    {
      title: 'Reward (USD)',
      dataIndex: 'points',
      key: 'points',
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => {
        return new Date(text).toLocaleDateString();
      },
    },
    {
      title: 'Action',
      render: (_, record) => (
       <PermissionCheck>
         <div style={{ display: 'flex', gap: '10px' }}>
          <img
            src={Edit}
            style={{ cursor: 'pointer' }}
            alt='icon'
            onClick={() => {
              setEditreferralItem(record);
            }}
          />
          <Switch
            checked={record.status}
            onChange={async (e) => await onStatusUpdate(e, record)}
          />
        </div>
       </PermissionCheck>
      ),
    },
  ];

  return (
    <div className='custom-table'>
      <Table
        dataSource={referralProgramList?.referralSettings}
        columns={columns}
        pagination={false}
      />
      <div className='pagination_block'>
        <span className='records'>
          Total Records:{' '}
          {referralProgramList?.referralSettings?.length > 0
            ? referralProgramList?.referralSettings?.length
            : 0}
        </span>
        {referralProgramList?.referralSettings?.length > 0 && (
          <Pagination
            defaultCurrent={page}
            total={referralProgramList?.referralSettings?.length}
            pageSize={pageSize}
            onChange={handlePageChange}
            pageSizeOptions={[10, 100, 200, 300, 400, 500]}
            current={page}
          />
        )}
      </div>
    </div>
  );
}

export default ReferralProgramLists;
