import { Button, Input, Select } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  finishLoading,
  startLoading,
} from '../../redux/features/loading/loadingSlice';
import { useDispatch } from 'react-redux';
import { SettingActions } from '../../redux/features/setting/settingActions';
import ButtonCommon from '../../Components/Buttoncommon/ButtonCommon';
import ReferralSupplyCount from './ReferralSupplyCount';
import { Validations } from '../../validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormError } from '../../Components/FormErrors/FormError';
function UpdateReferralprogram({
  editReferralItem,
  getReferralList,
  referralProgramList,
  setEditreferralItem,
}) {
  const dispatch = useDispatch();
  const [pointsType, setPointsType] = useState('');
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    resetField,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(Validations.referralProgramSchema),
    mode: 'all',
  });

  useEffect(() => {
    reset();         // Before setting the form value reset all state.
    setValue('pointType', editReferralItem?.pointsType);
    setPointsType(editReferralItem?.pointsType);
    setValue('points', editReferralItem?.points);
    setValue('minDeposit', editReferralItem?.minimumDepositValue);
  }, [editReferralItem]);

  const onSubmit = async (values) => {
    try {
      let updatedPayload = {
        pointsType: values?.pointType.toUpperCase(),
        refType: 'REFERRER',
        points: Number(values?.points),
        minimumDepositValue: Number(values.minDeposit),
        status: editReferralItem?.status ?? true,
      };
      if (updatedPayload?.pointsType !== 'DEPOSIT') {
        delete updatedPayload.minimumDepositValue;
      }
      await dispatch(startLoading());
      await dispatch(SettingActions.updateReferralList(updatedPayload));
      await getReferralList();
      setPointsType('');
      setEditreferralItem('');
      reset();
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  return (
    <div
      style={
        editReferralItem == null || editReferralItem == ''
          ? { display: 'flex', justifyContent: 'end' }
          : { display: 'flex', justifyContent: 'space-between' }
      }
    >
      {/* //  style={{ display: 'flex', justifyContent: 'space-between' }}> */}
      {editReferralItem && (
        <form
          className='withdrawFee'
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            marginLeft: '30px',
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <label>Reward Type</label>
            <Controller
              name='pointType'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  placeholder='Point Type'
                  {...field}
                  onChange={(value) => {
                    field.onChange(value); // Updates the form state
                    setPointsType(value); // Updates the component state
                  }}
                  style={{
                    minWidth: '150px',
                  }}
                  options={[
                    {
                      value: 'KYC_DONE',
                      label: 'Approved KYC',
                    },
                    {
                      value: 'DEPOSIT',
                      label: 'First Deposit',
                    },
                  ]}
                />
              )}
            />
            <FormError message={errors?.pointType?.message} />
          </div>

          {pointsType === 'DEPOSIT' && (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <label>Minimum Deposit (USD)</label>
              <Controller
                name='minDeposit'
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder='$ 0.00'
                    {...field}
                    style={{ maxWidth: '200px', padding: '11px' }}
                  />
                )}
              />
              <FormError message={errors?.minDeposit?.message} />
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <label>Reward (USD)</label>
            <Controller
              name='points'
              control={control}
              render={({ field }) => (
                <Input
                  placeholder='0.00'
                  {...field}
                  style={{ maxWidth: '200px', padding: '11px' }}
                />
              )}
            />
            <FormError message={errors?.points?.message} />
          </div>
          <div style={{ gap: '10px', display: 'flex', marginTop: '25px' }}>
            <ButtonCommon type='primary' htmlType='submit'>
              {editReferralItem ? 'Update' : 'Submit'}
              {/* Submit */}
            </ButtonCommon>

            <ButtonCommon
              className='filter-btn'
              reject='reject'
              onClick={() => {
                reset();
                setPointsType('');
                setEditreferralItem('');
              }}
              htmlType='button'
            >
              Reset
            </ButtonCommon>
          </div>
        </form>
      )}

      <div
      // style={
      //   editReferralItem == null
      //     ? { display: 'flex', justifyContent: 'end' }
      //     : {}
      // }
      >
        <ReferralSupplyCount referralProgramList={referralProgramList} />
      </div>
    </div>
  );
}

export default UpdateReferralprogram;
