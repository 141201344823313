import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import Fait from "./Fait";
import Crypto from "./Crypto";
import Swap from "./Swap";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoading,
  finishLoading,
} from "../../../redux/features/loading/loadingSlice";
import { Utils } from "../../../utils";
import {
  checkEmptyString,
  checkFalsyValues,
} from "../../../utils/common.utils";
import { CONSTANTS } from "../../../constants";
import toast from "../../../services/toast.service";
import { UserActions } from "../../../redux/features/user/userActions";

const Transations = () => {
  const dispatch = useDispatch();
  const selectedUserData = useSelector((state) => state.user.data);
  const selectedTab = useSelector((state) => state.user.selectedTab);

  const user = selectedUserData?.user;
  const [key, setActiveKey] = useState(1);
  const [transactionData, setTransactionData] = useState({
    list: [],
    totalCount: 0,
  });

  const [fiatTransactions, setFiatTransactions] = useState({
    list: [],
    totalCount: 0,
  });

  console.log({ clientIdDebug: user });

  const { UNDEFINED_CLIENTID } = CONSTANTS.VALIDATION_ERRORS;

  const onChange = (key) => {
    console.log(key);
    setActiveKey(key);
    if (key === "1") {
      const clientId = user?.clientId;
      const data = {
        clientId,
        coin: "",
        page: 1,
        size: 10,
        txStatus: "ALL",
        type: "credit,debit,internal-transfer",
      };
      getFiatTransactions(data);
    }
  };

  const formatTransactionData = (data) => {
    const { formatDateTime } = Utils.common;

    return data.map((obj, index) => {
      console.log(obj, "TRANSACTION OBJ IN USER DIRECTORY ");
      return {
        key: index + 1,
        created: formatDateTime(obj.createdAt),
        amount: checkFalsyValues(obj.amount),
        currency: obj.coin,
        txnId:
          obj.isCrypto === 1
            ? checkEmptyString(obj.typeId)
            : checkEmptyString(obj.typeId),
        clientId: checkEmptyString(obj?.clientId),
        type: obj.type,
        from: checkEmptyString(obj.fromAddress),
        to: checkEmptyString(obj.toAddress),
        id: obj.typeId,
        status: obj.status,
        isCrypto: obj.isCrypto,
        adminApproval: obj.adminApproval,
        approved_by : obj?.approvedBy || null ,
        kytStatus : obj.kytStatus
      };
    });
  };

  const formatSwapTransactionData = (data) => {
    console.log({ cryptoData: data });
    const { formatDateTime, divideBySmallestUnit } = Utils.common;
    return data.map((obj, index) => {
      return {
        key: index + 1,
        ...obj,
        createdAt: formatDateTime(obj.createdAt),
      };
    });
  };

  const getCryptoTransactions = async (data) => {
    try {
      console.log("dataWSS::::",data);
      if (!data.clientId) return;

      await dispatch(startLoading());
      let res = null;
      if (data.clientId) {
        res = await dispatch(UserActions.getCryptoTransactions(data)).unwrap();
      }
      if (res && res.list.length > 0) {
        const txnData = formatTransactionData(res.list);

        setTransactionData({
          list: txnData,
          totalCount: res.totalCount,
        });
      }
    } catch (error) {
      // ;
      toast.error(error);
      setTransactionData({
        list: [],
        totalCount: 0,
      });
    } finally {
      await dispatch(finishLoading());
    }
  };

  const getSwapTransactionsOnly = async (data) => {
    try {
      await dispatch(startLoading());
      const res = await dispatch(
        UserActions.getSwapTransactionsOnly(data)
      ).unwrap();

      if (res && res.totalCount > 0) {
        const txnData = formatSwapTransactionData(res.list);
        setTransactionData({
          list: txnData,
          totalCount: res.totalCount,
        });
      } else {
        setTransactionData({
          list: [],
          totalCount: 0,
        });
      }
    } catch (error) {
       console.log("my ere :  ",error);
    } finally {
      await dispatch(finishLoading());
    }
  };

  const getFiatTransactions = async (data) => {
    try {
      if (!data.clientId) return;
      dispatch(startLoading());
      const res = await dispatch(
        UserActions.getFiatTransactions(data)
      ).unwrap();
      console.log({ fiatRes: res });
      if (res && (res.totalCount > 0 || res.list?.length > 0)) {
        const txnData = formatTransactionData(res.list);
        setFiatTransactions({
          list: txnData,
          totalCount: res.totalCount,
        });
      } else if (res && (!res.list || res.list?.length === 0)) {
        setFiatTransactions({
          list: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const items = [
    {
      key: "1",
      label: "Fiat ",
      children: (
        <Fait
          data={fiatTransactions.list}
          totalCount={fiatTransactions.totalCount}
          getFiatTransactions={getFiatTransactions}
        />
      ),
    },
    {
      key: "2",
      label: "Crypto ",
      children: (
        <Crypto
          data={transactionData.list}
          totalCount={transactionData.totalCount}
          getCryptoTransactions={getCryptoTransactions}
        />
      ),
    },
    {
      key: "3",
      label: "Swap",
      children: (
        <Swap
          data={transactionData.list}
          totalCount={transactionData.totalCount}
          getSwapTransactionsOnly={getSwapTransactionsOnly}
        />
      ),
    },
  ];

  useEffect(() => {
    if (key === "2") {
      const clientId = user?.clientId;
      const data = {
        clientId,
        coin: "",
        page: 1,
        size: 10,
        txStatus: "ALL",
        type: "receive,send,internal-deposit,internal-withdraw,suspicious-trx",
      };
      getCryptoTransactions(data);
    }
    if (key === "3") {
      const userId = user?.userId;
      const data = {
        offset: "0",
        limit: "10",
        userId,
      };
      getSwapTransactionsOnly(data);
    }
  }, [key]);

  useEffect(() => {
    if (selectedTab === "Transactions") {
      setActiveKey("1");
      const clientId = user?.clientId;
      const data = {
        clientId,
        coin: "",
        page: 1,
        size: 10,
        txStatus: "ALL",
        type: "credit,debit,internal-transfer",
      };
      getFiatTransactions(data);
    }
  }, [selectedTab]);

  return (
    <>
      <div className="Custom-tabBtn">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          animated={true}
          activeKey={key}
        />
      </div>
    </>
  );
};

export default Transations;
