import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  finishLoading,
  startLoading,
} from '../../redux/features/loading/loadingSlice';
import { SettingActions } from '../../redux/features/setting/settingActions';
import CustomPagination from '../../Components/CustomPagination/CustomPagination';
import CustomTable from '../../Components/CustomTable/CustomTable';
import { handleHideId } from '../../utils/common.utils';
import CopyToClipboard from '../../Components/CopyToClipboard/CopyToClipboard';
import { UserActions } from '../../redux/features/user/userActions';
import {
  setSelectedApiData,
  setSelectedTab,
} from '../../redux/features/user/userSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function AllReferrals() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [allReferrals, setAllReferrals] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlePageChange = (page, pageSize) => {
    setPageSize(pageSize);
    setPage(page);
  };
  const getReferralList = async () => {
    try {
      const payload = {
        limit: pageSize,
        // offset: page,
        offset: (page - 1) * pageSize,
      };
      dispatch(startLoading());
      const res = await dispatch(
        SettingActions.getAllReferralList(payload)
      ).unwrap(); // need to change URL of API
      if (res?.data?.length > 0) {
        setAllReferrals(res?.data);
        setTotal(res?.totalRowCount);
      } else {
        setAllReferrals([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    getReferralList();
  }, [page, pageSize]);

  const getUserDetails = async (id) => {
    try {
      if (!id) return;
      const data = await dispatch(
        UserActions.getUserDetail({ userId: id })
      ).unwrap();
      if (data && data?.user && data?.kyc) {
        dispatch(setSelectedApiData(data));
        console.log({ viewData: data });
        navigate('/user-directory-view', { state: { fromReward: true } });
      } else {
        toast.error('No data found');
      }
    } catch (error) {}
  };

  const columns = [
    {
      title: 'Referrer Id',
      dataIndex: 'referrerId',
      key: 'referrerId',
      render: (_, record) => {
        return (
          <>
            {record?.referrerId ? (
              <div style={{ display: 'flex', gap: '5px' }}>
                <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    getUserDetails(record?.referrerId);
                    // dispatch(setSelectedTab("Rewards"));
                  }}
                >
                  {handleHideId(record?.referrerId)}
                </p>
                <CopyToClipboard userId={record?.referrerId} position='top' />
              </div>
            ) : (
              '-'
            )}
          </>
        );
      },
      // onclick:(text,record)=>{
      //   console.log("record::",record);
      //   // getUserDetails
      // }
    },
    {
      title: 'Referee Id',
      dataIndex: 'refereeId',
      key: 'refereeId',
      render: (_, record) => {
        return (
          <>
            {record?.refereeId ? (
              <div style={{ display: 'flex', gap: '5px' }}>
                <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    getUserDetails(record?.refereeId);
                    // dispatch(setSelectedTab("Rewards"));
                  }}
                >
                  {handleHideId(record?.refereeId)}
                </p>
                <CopyToClipboard userId={record?.refereeId} position='top' />
              </div>
            ) : (
              '-'
            )}
          </>
        );
      },
    },

    {
      title: 'Reward (USD)',
      dataIndex: 'points',
      key: 'points',
    },
    {
      title: 'Reward Type',
      dataIndex: 'pointsType',
      key: 'pointsType',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => {
        return new Date(text).toLocaleDateString();
        // return text;
      },
    },
  ];
  return (
    <div className='UserDirectory-page' style={{ marginTop: '30px' }}>
      <div className='table-design'>
        <div className='title-filter'>
          <h3>Rewards</h3>
        </div>
        <CustomTable dataSource={allReferrals} columns={columns} />
      </div>
      <CustomPagination
        totalRecords={total}
        defaultCurrent={page}
        total={total}
        pageSize={pageSize}
        onChange={handlePageChange}
        current={page}
      />
    </div>
  );
}

export default AllReferrals;
