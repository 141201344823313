import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Select, Input, DatePicker, Button } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import toast from '../../../services/toast.service';
import { CONSTANTS } from '../../../constants';
import { formatDate, formatTxnDate } from '../../../utils/common.utils';
import dayjs from 'dayjs';
import ButtonCommon from '../../../Components/Buttoncommon/ButtonCommon';
import { SettingActions } from '../../../redux/features/setting/settingActions';
import { useDispatch } from 'react-redux';
import {
  finishLoading,
  startLoading,
} from '../../../redux/features/loading/loadingSlice';
import InputCustom from '../../../Components/InputCustom/InputCustom';

const TransactionFilters = ({
  setPage,
  setFilterData,
  pageSize,
  getCryptoTransactions,
  clientId,
  data,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm({
    mode: 'all',
  });

  const location = useLocation();
  const dispatch = useDispatch();
  const obj = { ...location.state };
  const [trans, setTrans] = useState('all');
  const [currencyArr, setCurrencyArr] = useState([]);

  /** setting default filters coing from props */
  const [filter, setFilter] = useState({
    coin: obj.coin,
    page: obj.page,
    fromDate: obj.fromDate,
    toDate: obj.toDate,
    txStatus: obj.txStatus,
    type: obj.type,
    searchBy:obj.searchBy
  });

  const onSubmit = (values) => {
    // receive,send,internal-deposit,internal-withdraw
    let isFiltersValid = validateFilters(values);
    if (!isFiltersValid) return;
    values = convertFilterValues(values);
    if (Object.keys(values).length > 0) {
      setFilterData(values);
      getCryptoTransactions(values);
      setPage(1);
    }
  };

  const convertFilterValues = (data) => {
    let values = { ...data };

    if (values.fromDate && !values.toDate)
      values.toDate = formatTxnDate(new Date().toDateString());
    if (values.toDate && !values.fromDate)
      return toast.error('Please select from date');
    values.page = 1;
    values.size = pageSize;
    values.txStatus = values.status;
    values.coin = values.coin;
    values.searchBy = values.searchBy;
    values.clientId =
      location.pathname !== '/crypto-transaction' ? clientId : '';

    if (values.fromDate) values.fromDate = formatTxnDate(values.fromDate);
    if (values.toDate) {
      values.toDate = formatTxnDate(values.toDate);
      setValue('toDate', dayjs(values.toDate));
    }
    if (typeof values.status === 'undefined') values.txStatus = 'ALL';
    if (typeof values.type === 'undefined')
      values.type = 'receive,send,internal-deposit,internal-withdraw';
    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        if (
          typeof values[key] === 'undefined' ||
          values[key] == '' ||
          values[key] == null
        )
          delete values[key];
      }
    }
    return values;
  };

  const checkEmptyFilters = (data) => {
    let filterMessage = '';
    let isFiltersValid = true;
    isFiltersValid = Object.values(data).some((value) => !!value);
    if (!isFiltersValid) {
      if (data && data.length === 0) {
        isFiltersValid = true;
      } else {
        filterMessage = 'Please select filters first';
        toast.error(filterMessage);
      }
    }

    return isFiltersValid;
  };

  const validateFilters = (data) => {
    const { VALIDATION_ERRORS } = CONSTANTS;
    let isFiltersValid = checkEmptyFilters(data);
    if (data.fromDate && data.toDate) {
      if (
        new Date(data.fromDate).getDate() > new Date(data.toDate).getDate() &&
        new Date(data.fromDate).getMonth() > new Date(data.toDate).getMonth()
      ) {
        isFiltersValid = false;
        toast.error(VALIDATION_ERRORS.START_DATE_GREATER_THAN_END_DATE);
      }
    }

    return isFiltersValid;
  };

  const clearFilters = () => {
    let isFiltersValid = checkEmptyFilters(getValues());
    if (isFiltersValid) {
      reset();
      setValue('searchBy', '');
      const data = {
        clientId: location.pathname !== '/crypto-transaction' ? clientId : '',
        coin: '',
        page: 1,
        size: pageSize,
        fromDate: '',
        toDate: '',
        txStatus: 'ALL',
        searchBy:'',
        type: 'receive,send,internal-deposit,internal-withdraw',
      };
      setFilterData({})
      setPage(1);
      getCryptoTransactions(data);
     
    }
  };

  const setRangePickerValue = () => {
    return {
      fromDate: dayjs(filter.fromDate),
      toDate: dayjs(filter.toDate),
    };
  };

  useEffect(() => {
    console.log('filters:- ', filter);
    // setValue('coin', filter.coin);
    if (filter.fromDate) {
      setValue('fromDate', setRangePickerValue().fromDate);
    }
    if (filter.toDate) {
      setValue('toDate', setRangePickerValue().toDate);
    }
    // setValue('type', filter.type);
    setValue('status', filter.txStatus);
    // setValue('searchBy',filter.searchBy)
  }, [filter]);

  const getWithdrawFee = async () => {
    try {
      await dispatch(startLoading());

      const res = await dispatch(
        SettingActions.getWithdrawFee({ apiAccessKey: 'ALL_TRANSACTIONS' })
      ).unwrap();
      if (res) {
        const result = res.map((item) => {
          return {
            label: item.coin.toUpperCase(),
            value: item.coin.toUpperCase(),
          };
        });
        console.log('result::', result);
        setCurrencyArr(result);
      } else {
        setCurrencyArr([]);
      }
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  useEffect(() => {
    getWithdrawFee();
  }, []);

  return (
    <form
      style={{ display: 'inline-flex', gap: '15px', flexFlow: 'wrap' }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      {/* <Controller
        name="search"
        control={control}
        render={({ field }) => (
          <Input
            style={{ width: "230px" }}
            prefix={<SearchOutlined />}
            type="search"
            {...field}
            placeholder="Search by: TXN ID, Address"
          />
        )}
      /> */}

      <Controller
        name='fromDate'
        control={control}
        render={({ field }) => (
          <DatePicker {...field} placeholder='From date' />
        )}
      />

      <Controller
        name='toDate'
        control={control}
        render={({ field }) => <DatePicker {...field} placeholder='To date' />}
      />
      <div style={{ minWidth: '320px' }}>
        <Controller
          name='searchBy'
          control={control}
          render={({ field }) => (
            <>
              <InputCustom
                type='text'
                settingInput='settingInput'
                custommClass1='custommClass1'
                placeholder={' Client ID / Txn Id'}
                borderRadius={10}
                height={48}
                {...field}
              />
            </>
          )}
        />
      </div>

      <Controller
        name='type'
        control={control}
        render={({ field }) => (
          <Select
            placeholder='Type'
            {...field}
            style={{
              minWidth: '150px',
            }}
            options={[
              {
                value: 'all',
                label: 'ALL',
              },
              {
                value: 'internal-deposit',
                label: 'INTERNAL-IN',
              },
              {
                value: 'receive',
                label: 'CREDIT',
              },
              {
                value: 'internal-withdraw',
                label: 'INTERNAL-OUT',
              },
              {
                value: 'send',
                label: 'DEBIT',
              },
            ]}
          />
        )}
      />

      <Controller
        name='coin'
        control={control}
        render={({ field }) => (
          <Select
            placeholder='Currencies'
            {...field}
            style={{
              minWidth: '150px',
            }}
            options={currencyArr}
          />
        )}
      />

      <Controller
        name='status'
        control={control}
        render={({ field }) => (
          <Select
            placeholder='Status'
            {...field}
            style={{
              minWidth: '150px',
            }}
            options={[
              // { value: "", label: "", disabled: true },
              {
                value: 'ALL',
                label: 'ALL',
              },
              {
                value: 'PENDING',
                label: 'PENDING',
              },
              {
                value: 'COMPLETED',
                label: 'COMPLETED',
              },
              {
                value: 'FAILED',
                label: 'FAILED',
              },
            ]}
          />
        )}
      />

      <ButtonCommon
        // onClick={toggleFilterVisibility}
        text='Submit'
        type='primary'
        transBtn='transBtn'
        htmlType={'submit'}
      />

      <ButtonCommon
        className='filter-btn'
        // onClick={toggleFilterVisibility}
        type={'default'}
        onClick={clearFilters}
        htmlType='button'
        reject={'reject'}
      >
        <CloseOutlined />
      </ButtonCommon>
    </form>
  );
};

export default TransactionFilters;
