import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import AllScreening from './All/AllScreening';
import FiatScreening from './Fiat/FiatScreening';
import CryptoScreening from './Crypto/CryptoScreening';
import { setSelectedComplianceTab } from '../../../redux/features/compliance/complianceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  finishLoading,
  startLoading,
} from '../../../redux/features/loading/loadingSlice';
import { ComplianceActions } from '../../../redux/features/compliance/complianceAtion';

const TransactionsScreeningMain = () => {
  const { selectedComplianceTab } = useSelector((state) => state?.compliance);
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState('1');
  const [kytDetails, setKytDetails] = useState({
    list: [],
    totalCount: '',
  });

  const navigate = useNavigate();

  const onChange = (key) => {
    console.log(key);
    setSelectedKey(key);
    if (key == '1') {
      dispatch(setSelectedComplianceTab('All'));
    }
    if (key == '2') {
      dispatch(setSelectedComplianceTab('Fiat'));
    }
    if (key == '3') {
      dispatch(setSelectedComplianceTab('Crypto'));
    }
  };

  const handleKytTxnRes = (res) => {
    if (res && ((res.list && res.list.length > 0) || +res.totalCount > 0)) {
      const updatedRes = res.list.map((item) => {
        return {
          ...item,
        };
      });

      setKytDetails({
        list: updatedRes,
        totalCount: res.totalCount,
      });
    } else if (res && (res.list?.length === 0 || +res.totalCount === 0)) {
      setKytDetails({
        list: [],
        totalCount: 0,
      });
    }
  };
  const getKytTxn = async (data) => {
    try {
      const updatedState = { ...location.state, ...data }; // Add or update state
      navigate('/transactions-main', { state: { ...updatedState } }); // Navigate to the same path with new state
      await dispatch(startLoading());
      console.log("data::1",data);
      const res = await dispatch(
        ComplianceActions.getKytDetails(data)
      ).unwrap();
      handleKytTxnRes(res);
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(finishLoading());
    }
  };

  useEffect(() => {
    let data = {
      // offset: (page - 1) * pageSize,
      offset: 0,
      size: 10,
      txnStatus: 'ALL',
      coinName: '',
      type: '',
    };
    if (selectedComplianceTab == 'All') {
      data.type = '';
      data.txnStatus = 'ALL';
      setSelectedKey('1');
      // dispatch(setSelectedComplianceTab("All"));
    }
    if (selectedComplianceTab == 'Fiat') {
      data.type = 'FIAT';
      // dispatch(setSelectedComplianceTab("Fiat"));
      setSelectedKey('2');
    }

    if (selectedComplianceTab == 'Crypto') {
      data.type = 'CRYPTO';
      // dispatch(setSelectedComplianceTab("Crypto"));
      setSelectedKey('3');
    }
    getKytTxn(data);
  }, [selectedComplianceTab]);

  // useEffect(() => {
  //   if (selectedComplianceTab === "Pending") {
  //     onChange("1");
  //   } else {
  //     onChange("2");
  //   }
  // }, [selectedComplianceTab]);
  const items = [
    {
      key: '1',
      label: 'All',
      children: <AllScreening getKytTxn={getKytTxn} kytDetails={kytDetails} />,
    },
    {
      key: '2',
      label: 'Fiat',
      children: <FiatScreening getKytTxn={getKytTxn} kytDetails={kytDetails} />,
    },
    {
      key: '3',
      label: 'Crypto',
      children: (
        <CryptoScreening getKytTxn={getKytTxn} kytDetails={kytDetails} />
      ),
    },
  ];

  return (
    <>
      <div className='Custom-tabBtn'>
        <Tabs
          defaultActiveKey={selectedKey}
          activeKey={selectedKey}
          items={items}
          onChange={onChange}
          animated={true}
        />
      </div>
    </>
  );
};

export default TransactionsScreeningMain;
