import { Collapse, Divider, Image, Modal, Typography } from "antd";
import React, { useState } from "react";
import UserInfo from "./UserInformation/UserInfo";
import { KYCService } from "../../services/kyc.service";
import { Enviroments } from "../../constants/environment";
import { checkEmptyString, isJsonValid } from "../../utils/common.utils";
import { PersonalInfoService } from "../../services/personalInfo.service";
import PersonalInfoList from "./PersonalInfoList/PersonalInfoList";
import { Utils } from "../../utils";
import axios from "axios";
const { Panel } = Collapse;
const { Title } = Typography;

const Ubos = ({ data }) => {
  const mb20 = {
    margin: "20px 0",
  };
  const kycDocUrl = Enviroments.KYC_DOCS_URL;

  const { getUserInformationData, getPermanentAddressData } = KYCService;

  const [activeKey, setActiveKey] = useState(["1"]);
  const [missingFields, setMissingFields] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [docImage, setDocImage] = useState("");

  const onChange = (key) => {
    setActiveKey(key);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOnClick = (path) => {
    showModal();
    setDocImage(path);
  };

  const getDocumentData = async (docData) => {
    if (docData) {
      let docs = docData.map(async (doc, index) => {
        let { data } = await axios.get(kycDocUrl + doc.docPath, {
          responseType: "blob",
        });
        data = await Utils.common.blobToBase64(data);
        return {
          id: index + 1,
          title: doc.docName,
          about: (
            <>
              <Image
                src={data}
                alt="img"
                style={{ width: "150px", height: "auto" }}
                className="cursorpointer"
              />
            </>
          ),
          docName: doc.docName,
        };
      });
      docs = await Promise.all(docs);
      console.log('docs',docs);
      return docs;
    }
  };

  const getQuestionaireData = (data) => {
    let questionaires = "";
    if (isJsonValid(data?.questionairePayload)) {
      questionaires = JSON.parse(data?.questionairePayload);
    }
    return questionaires;
  };

  const getIndividualMissingInfo = (data) => {
    let info = data.individualAccountInf;

    if (isJsonValid(info)) {
      let data = JSON.parse(info);
      let newData = PersonalInfoService.getIndividualPersonalInfo(data);
      return newData;
    }

    return [];
  };

  const formatUboAlerts = (alerts) => {
    return alerts.map((alert, index) => {
      return {
        id: index + 1,
        title: alert,
      };
    });
  };

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <Title level={5}>Ubos</Title>
        <Collapse accordion activeKey={activeKey} onChange={onChange}>
          {data.map((ubo, index) => (
            <Panel
              key={(index + 1).toString()}
              header={`${checkEmptyString(
                (ubo.firstName?.trim() ?? "") +
                  " " +
                  (ubo.lastName?.trim() ?? "")
              )}`}
            >
              <UserInfo
                checkdata="User Information"
                list={getUserInformationData(ubo)}
              />
              <Divider style={mb20} />
              <UserInfo
                checkdata="Permanent Address"
                list={getPermanentAddressData(ubo)}
              />
              {ubo?.individualDocs && ubo?.individualDocs.length > 0 && (
                <>
                  <Divider style={mb20} />
                  <UserInfo
                    checkdata="Document"
                    list={getDocumentData(ubo.individualDocs)}
                    type="promise"
                  />
                </>
              )}

              {/* {ubo?.questionairePayload && (
                <>
                  <Divider style={mb20} />
                  <Questionaire
                    data={getQuestionaireData(ubo)}
                    type={"INDIVIDUAL"}
                  />
                </>
              )} */}
              {ubo?.individualAccountInf && (
                <>
                  <Divider style={mb20} />
                  <PersonalInfoList data={getIndividualMissingInfo(ubo)} />
                </>
              )}

              {ubo?.moderationComment && (
                <>
                  <Divider style={mb20} />
                  <UserInfo
                    checkdata="Alerts"
                    list={formatUboAlerts(ubo?.moderationComment)}
                    type="number"
                  />
                </>
              )}
            </Panel>
          ))}
        </Collapse>
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={403}
        footer={false}
        className="verificationmodal"
        maskClosable={false}
      >
        <div className="identitycard">
          <img src={docImage} alt="imgs" />
        </div>
      </Modal>
    </>
  );
};

export default Ubos;
