import React, { useEffect, useState } from 'react';
import TableFilterTittle from '../../../Components/TableFilterTittle/TableFilterTittle';
import {
  Button,
  Col,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Switch,
  Table,
} from 'antd';
import {
  checkEmptyString,
  formatDateTime,
  createFullName,
} from '../../../utils/common.utils';
import { useDispatch } from 'react-redux';
import { SettingTradeActions } from '../../../redux/features/setting/tradeManagentActions';
import {
  finishLoading,
  startLoading,
} from '../../../redux/features/loading/loadingSlice';
import PermissionCheck from '../../../Components/PermissionCheck/PermissionCheck';
import ScreenBreakpoints from '../../../Components/CustomHook/ScreenBreakpoints.jsx';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import { DeleteIcon } from '../../../Assets/Icons/Icons';
import ButtonCommon from '../../../Components/Buttoncommon/ButtonCommon';
import { FlagOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Validations } from '../../../validations';
import { yupResolver } from '@hookform/resolvers/yup';
import Edit from '../../../Assets/Images/greyEdit.svg';
import { FormError } from '../../../Components/FormErrors/FormError';
import { TransactionFeeService } from '../../../services/transaction-fee.service';
import { SettingActions } from '../../../redux/features/setting/settingActions';
import { BankingActions } from '../../../redux/features/banking/bankingActions';
import { Utils } from '../../../utils';

function TradeManagement() {
  const dispatch = useDispatch();
  const [tradingDetails, setTradingDetails] = useState([]);
  const [isLargeScreen, isSmallScreen] = ScreenBreakpoints();
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [pairRecord, setPairRecord] = useState('');
  const [showDeletePiarModal, setShowDeletePiarModal] = useState(false);
  const [updatePairStatus, setUpdatePairStatus] = useState();
  const [deleteObj, setDeleteObj] = useState({});
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feeDetails, setFeeDetails] = useState({
    name: '',
    role: '',
    updatedAt: '',
  });
  const [selectedPairObject, setSelectedPairObject] = useState(null); // Local state to store the selected object
  const [allTradingPairs, setAllTradingPairs] = useState([]);
  const tableSize = isLargeScreen
    ? 'large'
    : isSmallScreen
    ? 'middle'
    : 'small';

  const getTradDetails = async () => {
    try {
      const data = {
        apiAccessKey: 'TRADE_MANAGEMENT',
      };
      await dispatch(startLoading());
      const res = await dispatch(
        SettingTradeActions.getTradeManagement(data)
      ).unwrap();

      if (res?.length !== 0) {
        setTradingDetails(res);
      } else {
        setTradingDetails([]);
      }
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };
  console.log('selectedCoin:::', selectedCoin);
  useEffect(() => {
    getTradDetails();
    getActivePairsList();
  }, []);

  const handleUpdateStatus = async (checked, details) => {
    setIsActionModalOpen(true);
    setPairRecord(details);
    setUpdatePairStatus(checked);
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    resetField,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(
      isModalOpen
        ? Validations.swapFeeValidation
        : Validations.addpairsValidation
    ),
    mode: isModalOpen ? 'all' : 'onChange',
  });

  const handleActiveInActiveStatus = async () => {
    try {
      const data = {
        apiAccessKey: 'TRADE_MANAGEMENT',
        pairName: pairRecord?.pairName,
        isActive: updatePairStatus,
      };
      await dispatch(startLoading());
      const res = await dispatch(
        SettingTradeActions.updateTradeManagementStatus(data)
      ).unwrap();
      getTradDetails();
      setIsActionModalOpen(false);
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const handleCancelPair = () => {
    setIsActionModalOpen(false);
  };

  const handleDeletePairNameFn = (record) => {
    setDeleteObj(record);
    setShowDeletePiarModal(true);
  };
  const showModal = (record) => {
    console.log({ record });
    setSelectedCoin(record);
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: 'Pair Name',
      dataIndex: 'pairName',
      key: 'pairName',
      render: (_, record) => checkEmptyString(record.pairName),
    },
    {
      title: 'Precision',
      dataIndex: 'baseCurrencyPrecision',
      key: 'baseCurrencyPrecision',
      // render: (_, record) => checkEmptyString(record.baseCurrencyPrecision),
    },
    {
      title: 'Platform Fee (%)',
      dataIndex: 'platFormFee',
      key: 'platFormFee',
    },
    {
      title: 'Min Swap Amount',
      dataIndex: 'minAmount',
      key: 'minAmount',
    },
    {
      title: 'Max Swap Amount',
      dataIndex: 'maxAmount',
      key: 'maxAmount',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (record.isActive == true ? 'ACTIVE' : 'INACTIVE'),
    },
    {
      title: 'created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => formatDateTime(record.createdAt),
    },
    // {
    //   title: 'Updated At',
    //   dataIndex: 'updatedAt',
    //   key: 'updatedAt',
    //   render: (_, record) => formatDateTime(record.updatedAt),
    // },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <PermissionCheck>
              <Switch
                checkedChildren='Inactive'
                unCheckedChildren='Active'
                defaultChecked={record.isActive === true ? true : false}
                checked={record.isActive === true ? true : false}
                onChange={(checked) => handleUpdateStatus(checked, record)}
              />

              <img
                src={Edit}
                style={{ cursor: 'pointer' }}
                alt='icon'
                onClick={() => {
                  showModal(record);
                  const selectedObject = allTradingPairs.find(
                    (item) => item.pairName === record.pairName
                  ); // Find the selected object
                  setSelectedPairObject(selectedObject);
                }}
              />
              {/* <ButtonCommon
                icon={<DeleteIcon style={{ color: 'red' }} />}
                onClick={() => handleDeletePairNameFn(record)}
                type='text'
              /> */}
              {/* <div
                onClick={() => handleDeletePairNameFn(record)}
                style={{ cursor: 'pointer', marginTop: '4px' }}
              >
                <DeleteIcon />
              </div> */}
            </PermissionCheck>
          </div>
        );
      },
    },
  ];

  const onSubmit = async (values) => {
    const payload = {
      pairName: values.pairName,
      baseCurrencyPrecision: values.precision,
      platFormFee: values.platformFee,
      minAmount: values.minAmount,
      maxAmount: values.maxAmount,
    };
    await handleAddPair(payload);
  };

  const handleDeletePair = async () => {
    try {
      const data = {
        apiAccessKey: 'TRADE_MANAGEMENT',
        id: deleteObj.id,
      };
      await dispatch(startLoading());
      const res = await dispatch(
        SettingTradeActions.deleteTradingPair(data)
      ).unwrap();

      getTradDetails();
      setIsActionModalOpen(false);
      setShowDeletePiarModal(false);
      setDeleteObj({});
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const handleAddPair = async (payload) => {
    try {
      const data = {
        apiAccessKey: 'TRADE_MANAGEMENT',
        ...payload,
      };
      await dispatch(startLoading());
      const res = await dispatch(
        SettingTradeActions.addTradingPairs(data)
      ).unwrap();

      getTradDetails();
      setIsActionModalOpen(false);
      setAddModalOpen(false);
      reset();
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const onSubmitEditFee = (obj) => {
    console.log('obj::', obj);
    const data = {
      platFormFee: obj.platformFee,
      pairName: selectedCoin.pairName,
      minAmount: obj.minAmount,
      maxAmount: obj.maxAmount,
      precision: obj.precision,
      id: selectedCoin.id,
    };
    editWithdrawFee(data);
  };

  const editWithdrawFee = async (data) => {
    try {
      await dispatch(startLoading());
      await dispatch(SettingActions.editSwapWithdrawFee(data));
      setIsModalOpen(false);
      await getTradDetails();
      setSelectedCoin({});
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setError('platformFee', '');
    setSelectedCoin({});
    reset();
  };

  const getFeeStatus = async () => {
    try {
      dispatch(startLoading());
      console.log({ selectedCoin });
      let data = {
        moduleId: selectedCoin?.currency,
        module: 'SWAP_FEE_SETTINGS',
      };
      const res = await dispatch(BankingActions.getFeeStatus(data)).unwrap();
      if (res) {
        setFeeDetails({
          name: createFullName(res.admin?.firstName, res.admin?.lastName),
          role: res.admin?.title,
          updatedAt: res.updatedAt,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    setValue('platformFee', selectedCoin?.platFormFee);
    setValue('minAmount', selectedCoin?.minAmount);
    setValue('maxAmount', selectedCoin?.maxAmount);
    setValue('precision', selectedCoin?.baseCurrencyPrecision);

    getFeeStatus();
  }, [selectedCoin]);

  console.log('allTradingPairs:::', allTradingPairs);
  const afterPoints = [
    {
      id: 1,
      title: 'Last Updated On:',
      Detail: Utils.common.formatDateTime(feeDetails?.updatedAt),
    },
    {
      id: 2,
      title: 'Last Updated By:',
      Detail: Utils.common.formatAdminNameWithRole(
        feeDetails?.name,
        feeDetails?.role
      ),
    },
  ];

  const arr = [
    {
      pairName: 'BTC_USD',
      precision: '4',
      minQuantity: '0.0001',
      maxQuantity: '300',
    },
    {
      pairName: 'BTC_EUR',
      precision: '4',
      minQuantity: '0.0001',
      maxQuantity: '300',
    },
    {
      pairName: 'BTC_GBP',
      precision: '4',
      minQuantity: '0.0001',
      maxQuantity: '300',
    },
    {
      pairName: 'ETH_BTC',
      precision: '2',
      minQuantity: '0.005',
      maxQuantity: '5000',
    },
    {
      pairName: 'ETH_USD',
      precision: '4',
      minQuantity: '0.0001',
      maxQuantity: '5000',
    },
    {
      pairName: 'EUR_USD',
      precision: '4',
      minQuantity: '0.0001',
      maxQuantity: '5000000',
    },
    {
      pairName: 'BTC_CAD',
      precision: '4',
      minQuantity: '0.0001',
      maxQuantity: '300',
    },
    {
      pairName: 'BTC_JPY',
      precision: '4',
      minQuantity: '0.0001',
      maxQuantity: '300',
    },
  ];

  const getActivePairsList = async () => {
    try {
      const data = {
        apiAccessKey: 'TRADE_MANAGEMENT',
      };
      await dispatch(startLoading());

      let res = await dispatch(
        SettingTradeActions.getActivePairs(data)
      ).unwrap();
      console.log('resp:::', res);
      setAllTradingPairs(res);
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  console.log('selectedPairObject::', selectedPairObject);

  return (
    <>
      <div className='table-design'>
        <div className='title-filter'>
          <h3>Trade/Swap Management</h3>
          <PermissionCheck>
            <ButtonCommon
              icon={<FlagOutlined />}
              height={40}
              type='primary'
              text='Add Pair'
              onClick={() => {
                setAddModalOpen(true);
                getActivePairsList();
              }}
            />
          </PermissionCheck>
        </div>
        <div className='custom-table'>
          <Table
            className='last-center'
            columns={columns}
            dataSource={tradingDetails}
            pagination={false}
          />
        </div>
      </div>

      <CustomModal
        title={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            Are you sure you want to{' '}
            {pairRecord.isActive == true ? 'INACTIVE' : 'ACTIVE'}
          </div>
        }
        open={isActionModalOpen}
        footer={[
          <Button
            key='cancel'
            type='default'
            htmlType='button'
            onClick={handleCancelPair}
          >
            Cancel
          </Button>,
          <Button
            key='yes'
            type='primary'
            htmlType='button'
            onClick={handleActiveInActiveStatus}
          >
            Yes
          </Button>,
        ]}
      ></CustomModal>
      {/* ******************* Delete pair name model***************** */}
      <CustomModal
        title='Delete Pair'
        open={showDeletePiarModal}
        onOk={handleDeletePair}
        onCancel={() => {
          setShowDeletePiarModal(false);
          setDeleteObj({});
        }}
      >
        <p>Are you sure you want to delete {deleteObj?.pairName}? </p>
      </CustomModal>

      {/* *******************************Add Pair name modal******************************* */}
      <CustomModal
        title='Add Pair Name'
        open={addModalOpen}
        footer={[
          <Button
            type='primary'
            htmlType='submit'
            key='1'
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>,
          <Button
            type='link'
            key='2'
            onClick={() => {
              setAddModalOpen(false);
              reset();
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <Controller
            name='pairName'
            control={control}
            render={({ field }) => (
              <>
                <span
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  Pair Name
                  <Popover
                    placement='topLeft'
                    content='Please set valid pair name'
                  >
                    <QuestionCircleOutlined style={{ color: 'grey' }} />
                  </Popover>
                </span>
                <Select
                  {...field}
                  showSearch
                  placeholder='Select a pair'
                  optionFilterProp='children'
                  onChange={(value) => {
                    const selectedObject = allTradingPairs.find(
                      (item) => item.pairName === value
                    ); // Find the selected object
                    setSelectedPairObject(selectedObject);
                    field.onChange(value); // Update react-hook-form state
                    if (selectedObject) {
                      setValue('precision', selectedObject.precision);
                      setValue('minAmount', selectedObject.minQuantity);
                      setValue('maxAmount', selectedObject.maxQuantity);
                    }
                  }}
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {allTradingPairs.map((item) => (
                    <Select.Option key={item.pairName} value={item.pairName}>
                      {item.pairName}
                    </Select.Option>
                  ))}
                </Select>
                {errors.pairName && (
                  <FormError message={errors.pairName.message} />
                )}
              </>
            )}
          />

          {/* <Space> */}
          <Row gutter={10}>
            <Col md={12}>
              {' '}
              <Controller
                name='precision'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Precision
                      <Popover
                        placement='topLeft'
                        content='Please set the precision between 0 and 18'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='Set Precision'
                      {...field}
                      type='number'
                      max={selectedPairObject?.precision}
                      min={0}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (e.target.value <= selectedPairObject?.precision) {
                          field.onChange(value);
                        }
                      }}
                      suffix={<img src={Edit} alt='icon' />}
                    />
                    {errors.precision && (
                      <FormError message={errors.precision.message} />
                    )}
                  </>
                )}
              />
            </Col>
            <Col md={12}>
              <Controller
                name='platformFee'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Platform Fee(%)
                      <Popover
                        placement='topLeft'
                        content='Please set the platform fee for swap (%)'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field} // Bind input to controller
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^\d*\.?\d{0,3}$/;
                        if (regex.test(value) || value === '') {
                          field.onChange(e); // Only update the field if the value is valid
                        }
                      }}
                      suffix={<img src={Edit} alt='icon' />}
                    />
                    {errors.platformFee && (
                      <FormError message={errors.platformFee.message} />
                    )}{' '}
                  </>
                )}
              />
            </Col>
          </Row>

          {/* </Space> */}
          {/* Min Fee and Max Fee */}
          <Space>
            <Controller
              name='minAmount'
              control={control}
              render={({ field }) => (
                <>
                  <span
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    Min Amount
                    <Popover
                      placement='topLeft'
                      content='Please set the minimum amount for swap'
                    >
                      <QuestionCircleOutlined style={{ color: 'grey' }} />
                    </Popover>
                  </span>
                  <Input
                    placeholder='0.00'
                    {...field} // Bind input to controller
                    onChange={(e) => {
                      let value = e.target.value;

                      field.onChange(value); // Update the form with the trimmed value

                      if (value && !isNaN(value)) {
                        clearErrors('minAmount');
                        clearErrors('maxAmount');
                      }
                    }}
                    suffix={<img src={Edit} alt='icon' />}
                  />
                  {errors.minAmount && (
                    <FormError message={errors.minAmount.message} />
                  )}{' '}
                </>
              )}
            />
            <Controller
              name='maxAmount'
              control={control}
              render={({ field }) => (
                <>
                  <span
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    Max Amount
                    <Popover
                      placement='topLeft'
                      content='Please set the maximum amount for swap'
                    >
                      <QuestionCircleOutlined style={{ color: 'grey' }} />
                    </Popover>
                  </span>
                  <Input
                    placeholder='0.00'
                    {...field} // Bind input to controller
                    onChange={(e) => {
                      let value = e.target.value;

                      field.onChange(value); // Update the form with the trimmed value

                      if (value && !isNaN(value)) {
                        clearErrors('minAmount');
                        clearErrors('maxAmount');
                      }
                    }}
                    suffix={<img src={Edit} alt='icon' />}
                  />
                  {errors.maxAmount && (
                    <FormError message={errors.maxAmount.message} />
                  )}{' '}
                </>
              )}
            />
          </Space>
        </form>
      </CustomModal>

      {/* *******************************Edit swap modal******************************* */}

      <Modal
        className='custom-modal small-btn'
        title='Swap Wallet'
        open={isModalOpen}
        okText='Submit'
        closeIcon={true}
        centered
        footer={[
          <Button
            type='primary'
            htmlType='submit'
            key='1'
            onClick={handleSubmit(onSubmitEditFee)}
          >
            Submit
          </Button>,
          <Button type='link' key='2' onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <div className='modal-inner'>
          <span
            style={{
              display: 'inline-flex',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                display: 'inline-flex',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <b>{checkEmptyString(selectedCoin?.pairName?.toUpperCase())}</b>
            </span>
          </span>

          <form className='withdrawFee'>
            {/* <Space> */}
            <Row gutter={10}>
              <Col md={12}> <Controller
                name='precision'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Precision
                      <Popover
                        placement='topLeft'
                        content='Please set the precision between 0 and 18'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='Set Precision'
                      {...field}
                      type='number'
                      max={selectedPairObject?.precision}
                      min={0}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (e.target.value <= selectedPairObject?.precision) {
                          field.onChange(value);
                        }
                      }}
                      suffix={<img src={Edit} alt='icon' />}
                    />
                    {errors.precision && (
                      <FormError message={errors.precision.message} />
                    )}
                  </>
                )}
              />
              </Col>
             
              <Col md={12}>  <Controller
                name='platformFee'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Platform Fee(%)
                      <Popover
                        placement='topLeft'
                        content='Please set the platform fee for swap (%)'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field} // Bind input to controller
                      suffix={<img src={Edit} alt='icon' />}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^\d*\.?\d{0,3}$/;
                        if (regex.test(value) || value === '') {
                          field.onChange(e); // Only update the field if the value is valid
                        }
                      }}
                    />
                    {errors.platformFee && (
                      <FormError message={errors.platformFee.message} />
                    )}{' '}
                  </>
                )}
              /></Col>
            </Row>

            
            {/* </Space> */}
            {/* Min Fee and Max Fee */}
            <Space>
              <Controller
                name='minAmount'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Min Amount
                      <Popover
                        placement='topLeft'
                        content='Please set the minimum amount for swap'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field} // Bind input to controller
                      onChange={(e) => {
                        let value = e.target.value;
                        const precisionUpto =
                          selectedCoin.baseCurrencyPrecision;
                        // Dynamically create the regex using the RegExp constructor
                        const regex = new RegExp(
                          `^\\d*\\.?\\d{0,${precisionUpto}}$`
                        );
                        // Allow input if it matches the precision or is empty
                        if (regex.test(value) || value === '') {
                          field.onChange(value); // Update the form value if valid
                        }

                        // If user enters more digits than allowed, trim it to match the precision
                        const parts = value.split('.');
                        if (
                          parts.length > 1 &&
                          parts[1].length > precisionUpto
                        ) {
                          // Trim the decimal part to match precision
                          value = `${parts[0]}.${parts[1].slice(
                            0,
                            precisionUpto
                          )}`;
                          field.onChange(value); // Update the form with the trimmed value
                        }

                        // Clear errors if value is valid and a number
                        if (value && !isNaN(value)) {
                          clearErrors('minAmount');
                          clearErrors('maxAmount');
                        }
                      }}
                      suffix={<img src={Edit} alt='icon' />}
                    />
                    <p
                      style={{
                        fontSize: '10px',
                        marginTop: '5px',
                        textAlign: 'start',
                      }}
                    >
                      Min amount for{' '}
                      <span style={{ fontSize: '9px' }}>
                        {selectedPairObject.pairName}
                      </span>{' '}
                      on b2c2 is{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {selectedPairObject.minQuantity}
                      </span>
                    </p>
                    {errors.minAmount && (
                      <FormError message={errors.minAmount.message} />
                    )}{' '}
                  </>
                )}
              />

              <Controller
                name='maxAmount'
                control={control}
                render={({ field }) => (
                  <>
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Max Amount
                      <Popover
                        placement='topLeft'
                        content='Please set the maximum amount for swap'
                      >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                      </Popover>
                    </span>
                    <Input
                      placeholder='0.00'
                      {...field} // Bind input to controller
                      onChange={(e) => {
                        let value = e.target.value;
                        const precisionUpto =
                          selectedCoin.baseCurrencyPrecision;
                        // Dynamically create the regex using the RegExp constructor
                        const regex = new RegExp(
                          `^\\d*\\.?\\d{0,${precisionUpto}}$`
                        );
                        // Allow input if it matches the precision or is empty
                        if (regex.test(value) || value === '') {
                          field.onChange(value); // Update the form value if valid
                        }

                        // If user enters more digits than allowed, trim it to match the precision
                        const parts = value.split('.');
                        if (
                          parts.length > 1 &&
                          parts[1].length > precisionUpto
                        ) {
                          // Trim the decimal part to match precision
                          value = `${parts[0]}.${parts[1].slice(
                            0,
                            precisionUpto
                          )}`;
                          field.onChange(value); // Update the form with the trimmed value
                        }

                        // Clear errors if value is valid and a number
                        if (value && !isNaN(value)) {
                          clearErrors('minAmount');
                          clearErrors('maxAmount');
                        }
                      }}
                      suffix={<img src={Edit} alt='icon' />}
                    />
                    <p
                      style={{
                        fontSize: '10px',
                        marginTop: '5px',
                        textAlign: 'start',
                      }}
                    >
                      Max amount for{' '}
                      <span style={{ fontSize: '9px' }}>
                        {selectedPairObject.pairName}
                      </span>{' '}
                      on b2c2 is{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {selectedPairObject.maxQuantity}
                      </span>
                    </p>
                    {errors.maxAmount && (
                      <FormError message={errors.maxAmount.message} />
                    )}{' '}
                  </>
                )}
              />
            </Space>
            {/* Max. Withdrawal */}

            {/* Daily Withdrawal */}

            {/* Withdrawal Fee */}
          </form>
          <ul style={{ marginTop: '35px' }}>
            {feeDetails.role !== '' && (
              <>
                {afterPoints.map((point) => (
                  <>
                    <li key={point.id}>
                      <span>{point.title}</span>
                      <span>{point.Detail}</span>
                    </li>
                  </>
                ))}
              </>
            )}
          </ul>
        </div>
      </Modal>
    </>
  );
}

export default TradeManagement;
