import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import toast from '../../services/toast.service';
import ButtonCommon from '../../Components/Buttoncommon/ButtonCommon';
import InputCustom from '../../Components/InputCustom/InputCustom';

const UserManagementFilters = ({
  setPage,
  setFilterData,
  pageSize,
  getAdmins,
  type,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm({
    mode: 'all',
  });

  const location = useLocation();

  const options = [
    { value: 'ALL', label: 'ALL' },
    {
      value: 'ACTIVE',
      label: 'ACTIVE',
    },
    {
        value: 'INACTIVE',
        label: 'INACTIVE',
      },
      {
        value: 'DELETE',
        label: 'DELETED',
      },
  ];

  const obj = { ...location.state };

  console.log({ obj });

  const onSubmit = (values) => {
    let isFiltersValid = validateFilters(values);
    if (!isFiltersValid) return;
    values = convertFilterValues(values);
    if (Object.keys(values).length > 0) {
      setFilterData(values);
      getAdmins(values);
      setPage(1);
    }
  };

  const convertFilterValues = (data) => {
    let values = { ...data };
    if (values.status === undefined) values.status = 'ALL';
    values.offset = 0;
    values.size = pageSize || 10;
    return values;
  };

  const checkEmptyFilters = (data) => {
    let filterMessage = '';
    let isFiltersValid = true;
    isFiltersValid = Object.values(data).some((value) => !!value);
    if (!isFiltersValid) {
      if (data && data.length === 0) {
        isFiltersValid = true;
      } else {
        filterMessage = 'Please select filters first';
        toast.error(filterMessage);
      }
    }

    return isFiltersValid;
  };

  const validateFilters = (data) => {
    let isFiltersValid = checkEmptyFilters(data);

    return isFiltersValid;
  };

  const clearFilters = () => {
    let isFiltersValid = checkEmptyFilters(getValues());
    if (isFiltersValid) {
     reset({
        status : 'ALL' , 
        searchBy : ''
     })
      let data = {
        offset: 0,
        size: 10,
      };
      setPage(1);
      setFilterData(data);
      getAdmins(data);
    }
  };

  return (
    <form
      style={{ display: 'inline-flex', gap: '15px', flexFlow: 'wrap' }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Controller
        name="searchBy"
        control={control}
        render={({ field }) => (
          <>
            <InputCustom
              type="text"
              settingInput="settingInput"
              custommClass1="custommClass1"
              placeholder={'Title / First Name / Last Name / Admin Id / Email'}
              borderRadius={10}
              height={48}
              {...field}
            />
          </>
        )}
      />
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <Select
            placeholder="Status"
            defaultValue={'ALL'}
            {...field}
            style={{
              minWidth: '150px',
            }}
            options={options}
          />
        )}
      />
      <div className="d-flex">
        <ButtonCommon
          text="Submit"
          type="primary"
          transBtn="transBtn"
          htmlType={'submit'}
        />

        <ButtonCommon
          className="filter-btn"
          reject="reject"
          onClick={clearFilters}
          htmlType="button"
        >
          <CloseOutlined />
        </ButtonCommon>
      </div>
    </form>
  );
};

export default UserManagementFilters;
