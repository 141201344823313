import { EyeOutlined } from "@ant-design/icons";
import { Modal, Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CopyToClipboard from "../../Components/CopyToClipboard/CopyToClipboard";
import { KycActions } from "../../redux/features/kyc/kycActions";
import { setSelectedKYC } from "../../redux/features/kyc/kycSlice";
import {
  finishLoading,
  startLoading,
} from "../../redux/features/loading/loadingSlice";
import { setSelectedApiData } from "../../redux/features/user/userSlice";
import toastService from "../../services/toast.service";
import { showName } from "../../services/user.service";
import {
  checkEmptyString,
  formatDateTime,
  handleHideId,
  iconColor,
  iconSize,
} from "../../utils/common.utils";
import KycVerification from "../KycVerification/KycVerification";
import Rejectdata from "../KycVerification/Rejectdata.jsx";
import Filttercustom from "../UserDirectory/Filttercustom.jsx";
import { UserActions } from '../../redux/features/user/userActions';
import { toast } from 'react-toastify';
const UserVerification = () => {
  const [isModalOpenReject, setIsModalOpenReject] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state);
  const { isInitialPageLoaded } = useSelector((state) => state.dashboard);
  const [pageSize, setPageSize] = useState(10);
  const [countryDropDownobj,setCountryDropdownObj] = useState([])

  const showModalReject = () => {
    setIsModalOpenReject(true);
  };

  const handleOkReject = () => {
    setIsModalOpenReject(false);
  };

  const handleCancelReject = () => {
    setIsModalOpenReject(false);
  };

 const [isModalOpenApprove, setIsModalOpenApprove] = useState(false);

  const showVerificationDetails = async (userId) => {
    try {
      await dispatch(setSelectedKYC({ userId }));
      await getUserDetails(userId);

      setIsMngView(true);
    } catch (error) {
      toastService.error(error);
    }
  };

  const handleOkApprove = () => {
    setIsModalOpenApprove(false);
  };

  const handleCancelApprove = () => {
    setIsModalOpenApprove(false);
  };
  // ================================================================
  const [isModalOpen, setIsModalOpen] = useState(false);

  const viewKYCApplication = async (userId) => {
    try {
      await dispatch(setSelectedKYC({ userId }));
      await getUserDetails(userId, false);
      setIsMngView(true);
    } catch (error) {}
  };

  const getUserDetails = async (id) => {
    try {
      const data = await dispatch(
        KycActions.getUserDetail({ userId: id })
      ).unwrap();
      if (data && data?.user && data?.kyc) {
        dispatch(setSelectedApiData(data));
        setSelectedData(data);
      } else {
        return;
      }
    } catch (error) {
      throw new Error("No data found");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // =======================================================
  const [isMngView, setIsMngView] = useState(false);

  const getUserDetailsInfo = async (id) => {
    try {
      if (!id) return;
      const data = await dispatch(
        UserActions.getUserDetail({ userId: id })
      ).unwrap();
      if (data && data?.user && data?.kyc) {
        dispatch(setSelectedApiData(data));
        console.log({ viewData: data });
        navigate('/user-directory-view');
      } else {
        toast.error('No data found');
      }
    } catch (error) {}
  };

  const columns = [
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
      render: (_, record) => {
        return (
          <>
            {record?.userId ? (
              <>
                <span
                  onClick={() => getUserDetailsInfo(record.userId)}
                  style={{ cursor: "pointer" }}
                >
                  {handleHideId(record?.userId)}
                </span>

                <CopyToClipboard userId={record?.userId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Email ID",
      dataIndex: "emailId",
      key: "emailId",
      render: (_) => checkEmptyString(_),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Nationality",
      dataIndex: "nationality",
      key: "nationality",
      width: 100,
      render: (_) => checkEmptyString(_),
    },
    {
      title: "Kyc Status(ADMIN)",
      dataIndex: "adminKycStatus",
      key: "adminKycStatus",
      width: 140,
    },
    {
      title: "Kyc Status(SUMSUB)",
      dataIndex: "sumsubKycStatus",
      key: "sumsubKycStatus",
      width: 150,
    },
    {
      title: "Account Type",
      dataIndex: "accountType",
      key: "accountType",
      width: 140,
      render: (_) => checkEmptyString(_),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <div className="approvereject cursorpointer">
          {/* <span
            onClick={() => {
              showVerificationDetails(record.userId);
            }}
          >
            <Button type="primary">More Details</Button>
          </span> */}
          <EyeOutlined
            style={{ color: iconColor, fontSize: iconSize }}
            onClick={() => showVerificationDetails(record.userId)}
          />
        </div>
      ),
    },
  ];

  const [kycData, setKycData] = useState({
    list: [],
    totalCount: 0,
  });

  const dispatch = useDispatch();

  const formatUserData = (data) => {
    // const { checkEmptyString } = Utils.common;
    return data.list.map((user, index) => {
      return {
        number: index + 1,
        userId: user.userId,
        accountType: user.accountType,
        adminKycStatus: user?.adminKycStatus,
        sumsubKycStatus: user.sumsubKycStatus,
        nationality: user.nationality,
        createdAt: formatDateTime(user.createdAt),
        emailId: user.email,
        name: showName(user),
      };
    });
  };

  const getKycUsers = async (obj = {}) => {
    console.log({ filterData: obj });
    try {
      await dispatch(startLoading());
      const updatedState = { ...location.state, ...obj }; // Add or update state
      navigate("/user-verification", { state: { ...updatedState } }); // Navigate to the same path with new state
      setPage(Math.ceil(obj.offset / pageSize) + 1 || 1);
      const res = await dispatch(
        KycActions.getKycs({
          ...obj,
        })
      ).unwrap();
      if (res && +res.totalCount > 0) {
        const formattedUserData = formatUserData(res);
        setKycData({
          list: formattedUserData,
          totalCount: +res.totalCount,
        });
      } else {
        setKycData({
          list: [],
          totalCount: 0,
        });
      }
      await dispatch(finishLoading());
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const handlePageChange = (page, pageSize) => {
    const obj = {
      limit: pageSize,
      offset: (page - 1) * pageSize,
      filter: location.state?.filter || {},
    };

    setPageSize(pageSize);
    getKycUsers({ ...obj });
  };

  const showAdminActions = (status) => {
    switch (status) {
      case "REJECTED":
        return <span className="green">Approve</span>;
      case "APPROVED":
        return <span className="red">Reject</span>;
      case "PENDING":
        return (
          <>
            <span className="green">Approve</span>
            <span className="red">/Reject</span>
          </>
        );
    }
  };

  const obj = { ...location.state };
  useEffect(() => {
    if (!isMngView) {
     
      if (Object.keys(obj).length > 0) {
        setPage(Math.ceil(obj.offset / pageSize) + 1 || 1);
        getKycUsers(obj);
      } else {
        let newObj = {
          offset: 0 * pageSize,
          limit: pageSize,
          filter: {},
        };
        setPage(1);
        getKycUsers(newObj);
      }
    }
  }, [isMngView]);

  useEffect(() => {
    setIsMngView(false);
  }, [isInitialPageLoaded]);

  const getCountryLists = async () => {
    try {
      await dispatch(startLoading());
      
      const res = await dispatch(UserActions.getCountryList()).unwrap();
      if (res) {
        let contryObjDropdown = res?.list.map((item) => {
          return {
            label:  item.charAt(0).toUpperCase() + item.substr(1),
            value:item
          };
        });
        setCountryDropdownObj(contryObjDropdown)
      }else{
        setCountryDropdownObj([])
      }
      await dispatch(finishLoading());
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };
useEffect(() => {
  getCountryLists()
}, [])


  return (
    <>
      {isMngView ? (
        <KycVerification
          userInformatoin={true}
          datamodalapprove={true}
          datamodalreject={true}
          innervalue={true}
          setIsMngView={setIsMngView}
          data={selectedData}
        />
      ) : (
        <>
          <div className="table-design">
            <div className="title-filter">
              <h3>Client Verification</h3>
              <div style={{ display: "inline-flex", gap: "15px" }}>
                {/* <Input
                  style={{ width: "145px" }}
                  prefix={<SearchOutlined />}
                  type="search"
                  placeholder="Search"
                /> */}
                <Filttercustom
                  filterData={getKycUsers}
                  placeholder="Account type"
                  pageSize={pageSize}
                  total={kycData.totalCount}
                  setPage={setPage}
                  data={kycData.list}
                  countryDropDownobj={countryDropDownobj}
                />
              </div>
            </div>
            <div className="custom-table">
              <Table
                dataSource={kycData.list}
                columns={columns}
                pagination={false}
                //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
              />
            </div>
          </div>
          <div className="pagination_block">
            <span className="records" style={{ marginTop: "10px" }}>
              Total Records: {kycData.totalCount}
            </span>
            {kycData?.totalCount > 10 && (
              <Pagination
                defaultCurrent={page}
                total={kycData.totalCount}
                pageSize={pageSize}
                onChange={handlePageChange}
                current={page}
              />
            )}
          </div>
        </>
      )}

      {/* ======================================aprove==== */}
      <Modal
        className="custom-modal verificationmodal"
        okText="Approve"
        width={403}
        open={isModalOpenApprove}
        onOk={handleOkApprove}
        onCancel={handleCancelApprove}
        centered
        closeIcon={true}
        maskClosable={false}
      >
        <p style={{ padding: "48px auto 18px auto", textAlign: "center" }}>
          Are you sure you want to approve?
        </p>
      </Modal>
      {/* ======================================================reject */}
      <Modal
        okText="Reject"
        className="custom-modal verificationmodal red-btn"
        width={606}
        open={isModalOpenReject}
        onOk={handleOkReject}
        onCancel={handleCancelReject}
        centered
        closeIcon={true}
        maskClosable={false}
      >
        <Rejectdata />
      </Modal>
    </>
  );
};

export default UserVerification;
