import React, { useEffect, useState } from 'react';
import { Table, Pagination, Space } from 'antd';
import { WalletActions } from '../../../redux/features/wallet/walletActions.js';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ButtonCommon from '../../../Components/Buttoncommon/ButtonCommon';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { CONSTANTS } from '../../../constants/index.js';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import {
  formatCryptoTypeId,
  formatTxnType,
  getUserIdFromTransaction,
} from '../../../services/transaction.service.js';
import {
  handleHideId,
  formatFiatValues,
  iconColor,
  iconSize,
} from '../../../utils/common.utils.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { TransactionFeeService } from '../../../services/transaction-fee.service.js';
import CopyToClipboard from '../../../Components/CopyToClipboard/CopyToClipboard.jsx';
import DownloadFile from '../../../Components/Common/DownloadFile.jsx';
import AllTransactionFilters from './AllTransactionFilters.jsx';
import { BankingActions } from '../../../redux/features/banking/bankingActions.js';
import { EyeOutlined } from '@ant-design/icons';

import {
  finishLoading,
  startLoading,
} from '../../../redux/features/loading/loadingSlice.js';
import ApproveFiatTxnModal from './ApproveFiatTxnModal.jsx';
import { KycActions } from '../../../redux/features/kyc/kycActions.js';
import { setDocList } from '../../../redux/features/kyc/kycSlice.js';
import { UserActions } from '../../../redux/features/user/userActions.js';
import { setTransactionDetail } from '../../../redux/features/compliance/complianceSlice.js';

const All = ({ data, totalCount, getAllTransactions }) => {
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const download = useSelector((state) => state.download);
  const [sortedInfo, setSortedInfo] = useState({});

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterData, setFilterData] = useState({});
  const selectedUserData = useSelector((state) => state.user.data);
  const user = selectedUserData?.user;
  const state = useSelector((state) => state);
  const location = useLocation();
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [selectedType, setSelectedType] = useState({
    type: -1,
    isCrypto: 0,
  });

  const [clickedDetail, setClickedDetail] = useState(null);
  const [showAllTransactionRejectModal, setShowAllTransactionRejectModal] =
    useState(false);
  const [showApproveFiatTxn, setShowAllTransactionApproval] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getUploadedDocList = async (payload) => {
    try {
      const data = await dispatch(
        KycActions.getKytDocListByTxnId(payload)
      ).unwrap();
      return data;
    } catch (error) {
      return {};
    }
  };

  const getTransactionDetails = async (obj) => {
    let userData = {};
    console.log({ transactionDetailsObj: obj });
    const clientId =
      location.pathname === '/all-transaction'
        ? obj?.clientId
        : selectedUserData?.user?.clientId;
    if (!clientId) return;
    setClickedDetail(obj.currency);
    setSelectedType({
      type: obj?.type,
      isCrypto: obj?.isCrypto,
    });
    let type = checkType(obj);

    console.log('TYPE',type);
    
    let res = null;

    let userId = '';
    dispatch(startLoading());
    dispatch(setDocList({}));
    try {
      if (type === 'FIAT') {
      } else if (type === 'CRYPTO' || type === 'DEFAULT') {
        let apiAccessKey = 'ALL_TRANSACTIONS';
        userData = await dispatch(
          UserActions.getUserIdByClientId({ clientId: clientId, apiAccessKey })
        ).unwrap();
        userId = userData.userId;
      }

      if (type === 'SWAP') {
        res = await getSwapTransactionDetails(obj);
        userId = res.userId;
      } else if (type === 'CRYPTO') {
        res = await getAllTransactionDetails(obj, clientId);
      } else if (type === 'FIAT') {
        res = await getFiatTransactionDetails(obj);
        userId = getUserIdFromTransaction(res, type, obj.type);
      } else if (type === 'DEFAULT') {
        res = obj;
      }
      let newRes = {};
      if (type === 'CRYPTO' || type === 'FIAT' || type === 'DEFAULT') {
        newRes = await dispatch(
          KycActions.getKycTxnDetails({
            [type !== 'FIAT' ? 'typeId' : 'txnId']:
              type !== 'FIAT' && obj?.type !== 0
                ? formatCryptoTypeId(obj?.txnId)
                : obj?.txnId,
            apiAccessKey: 'ALL_TRANSACTIONS',
          })
        ).unwrap();
      }

      let data = await getUploadedDocList({
        limit: '10',
        offset: '0',
        userId: userId,
        txnId:
          obj.type === 'CRYPTO' ? formatCryptoTypeId(obj.txnId) : obj.txnId,
        docType: 'ADDITIONAL',
      });

      dispatch(setDocList(data));

      navigate('transactions-main-detail', {
        state: {
          data: res,
          type,
          typeValue: obj.type,
          sumsubTxnFullPayload: newRes,
          userId: userId,
          txnId:
            obj.type === 'FIAT' ? obj.txnId : formatCryptoTypeId(obj.txnId),
        },
      });
    } catch (error) {
      navigate('transactions-main-detail', {
        state: {
          data: obj,
          type: 'DEFAULT',
          typeValue: obj.type,
          sumsubTxnFullPayload: {},
          userId: userId,
          txnId: type === 'FIAT' ? obj.txnId : formatCryptoTypeId(obj.txnId),
        },
      });
    } finally {
      dispatch(finishLoading());
    }
  };

  const getFiatTransactionDetails = async (obj) => {
    try {
      const res = await dispatch(
        BankingActions.getFiatTransactionDetails({
          transactionId: obj.txnId,
          apiAccessKey: 'ALL_TRANSACTIONS',
        })
      ).unwrap();

      if (res && res.data) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSwapTransactionDetails = async (obj) => {
    try {
      const res = await dispatch(
        WalletActions.getSwapTransactionDetails({
          typeId: obj.id,
          apiAccessKey: 'ALL_TRANSACTIONS',
        })
      ).unwrap();

      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const getAllTransactionDetails = async (obj, clientId) => {
    try {
      let res = null;
      const selectedCoin = obj.currency;
      if (selectedCoin === 'EUR') {
        res = await dispatch(
          BankingActions.getFiatTransactionDetails({
            transactionId: obj.id,
          })
        ).unwrap();
      } else {
        res = await dispatch(
          WalletActions.getTransactionsDetailsById({
            coin: obj?.currency,
            // clientId: "646ffbcc-bea2-11ee-8f44-42010ac96004",
            clientId,
            type: obj.type, // WITHRAW, DEPOSIT
            apiKey: CONSTANTS.ENV.TRANSACTION_KEY,
            typeId: obj?.id,
            from: obj.from,
            to: obj.to,
          })
        ).unwrap();
      }
      return res;
    } catch (error) {
      throw error;
    }
  };

  const showAllTransctionRejectionModal = (record) => {
    setShowAllTransactionRejectModal(true);
    setSelectedTransaction(record);
  };

  const showAllTransactionApproveModal = (record) => {
    setShowAllTransactionApproval(true);
    setSelectedTransaction(record);
  };

  const handleFiatRejection = async () => {
    if (showAllTransactionRejectModal) {
      try {
        dispatch(startLoading());
        const res = await dispatch(
          BankingActions.cancelFiatTxnStatus({
            status: 'REJECTED',
            transactionId: selectedTransaction?.txnId,
            apiAccessKey: 'ALL_TRANSACTIONS',
          })
        ).unwrap();
        setShowAllTransactionRejectModal(false);
        const data = {
          clientId: '',
          coin: '',
          txStatus: filterData.status || 'ALL',
          type: filterData.type || 'all',
          trxnType: 'ALL',
          page: filterData.page || 1,
          size: filterData.size || 10,
        };
        dispatch(getAllTransactions(data));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(finishLoading());
      }
    }
  };

  const columns = [
    {
      title: 'Txn Id',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => {
        return (
          <>
            {record?.id ? (
              <>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => getTransactionDetails(record)}
                >
                  {handleHideId(record?.id)}
                </span>
                <CopyToClipboard userId={record?.id} position='top' />
              </>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Client Id',
      dataIndex: 'clientId',
      key: 'clientId',
      render: (_, record) => {
        return (
          <>
            {record?.clientId ? (
              <>
                <span
                >
                  {handleHideId(record?.clientId)}
                </span>
                <CopyToClipboard userId={record?.clientId} position='top' />
              </>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      render: (_, record) => {
        return typeof record.currency === 'string'
          ? record.currency.toUpperCase()
          : '-';
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => {
        let amount = TransactionFeeService.getFeeAmountAfterPrecision(
          record.currency,
          record.amount
        );

        return record.isCrypto === 1 ? (
          TransactionFeeService.checkIsNumberExponential(amount)
        ) : (
          <>
            <p>{formatFiatValues(Number(record?.amount))}</p>
          </>
        );
      },
    },

    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => {
        return (
          <>
            <p>
              {formatTxnType(record?.type) === 'SWAP-IN' ||
              formatTxnType(record?.type) === 'SWAP-OUT'
                ? 'SWAP'
                : formatTxnType(record?.type)}
            </p>
          </>
        );
      },
    },
    {
      title: 'Created on',
      dataIndex: 'created',
      key: 'created',
      sorter: (a, b) => a.created.localeCompare(b.created),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 200,
      render: (_, record) => {
        return (
          <>
            <Space className='groupTxnBtn'>
              {record && record?.isCrypto === 0 ? (
                <>
                  {!record?.kytStatus &&
                    record?.status === 'PENDING' &&
                    record?.adminApproval === 1 && (
                      <>
                        <ButtonCommon
                          className='filter-btn'
                          reject='approve'
                          width={45}
                          onClick={() => showAllTransactionApproveModal(record)}
                        >
                          <CheckOutlined />
                        </ButtonCommon>

                        <ButtonCommon
                          className='filter-btn'
                          reject='reject'
                          onClick={() =>
                            showAllTransctionRejectionModal(record)
                          }
                        >
                          <CloseOutlined />
                        </ButtonCommon>
                      </>
                    )}
                </>
              ) : (
                <>
                  {record?.approved_by === null &&
                    record?.status === 'PENDING' &&
                    record?.adminApproval === 1 && (
                      <>
                        <ButtonCommon
                          className='filter-btn'
                          reject='approve'
                          width={45}
                          onClick={() => showAllTransactionApproveModal(record)}
                        >
                          <CheckOutlined />
                        </ButtonCommon>

                        <ButtonCommon
                          className='filter-btn'
                          reject='reject'
                          onClick={() =>
                            showAllTransctionRejectionModal(record)
                          }
                        >
                          <CloseOutlined />
                        </ButtonCommon>
                      </>
                    )}
                </>
              )}
              <EyeOutlined
                style={{ color: iconColor, fontSize: iconSize }}
                onClick={() => {
                  getTransactionDetails(record);
                  {
                    record?.approved_by === null &&
                      record?.status === 'PENDING' &&
                      record?.adminApproval === 1 &&
                      dispatch(setTransactionDetail(record));
                  }
                }}
              />
            </Space>
          </>
        );
      },
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handlePageChange = (page, pageSize) => {
    console.log(filterData, 'filterDatass');
    console.log(pageSize, 'pageSizess');
    if (!download.isEnabled) {
      setPage(page);
      setPageSize(pageSize);
    }

    let data = {
      clientId: location.pathname !== '/all-transaction' ? user?.clientId : '',
      coin: '',
      page,
      size: pageSize,
      txStatus: 'ALL',
      type: 'all',
    };

    if (Object.keys(filterData).length > 0) {
      data = { ...filterData, page, size: pageSize };
    }

    getAllTransactions(data);
  };

  const handleCancel = () => {
    setShowTransactionDetails(false);
  };

  useEffect(() => {
    console.log({ clickedDetail });
  }, [clickedDetail]);

const checkType = (obj = selectedType) => {
  if (CONSTANTS.SWAP_TYPES.includes(obj.type)) {
    return 'SWAP';
  }

  if (obj.isCrypto === 1) {
    return obj.type === 11 ? 'DEFAULT' : 'CRYPTO';
  }

  if (obj.isCrypto === 0) {
    return 'FIAT';
  }

  return '';
};

  const onSubmit = async (fiatAmount) => {
    try {
      dispatch(startLoading());
      const res = await dispatch(
        BankingActions.cancelFiatTxnStatus({
          status: 'APPROVED',
          transactionId: selectedTransaction?.txnId,
          amount: fiatAmount,
          apiAccessKey: 'ALL_TRANSACTIONS',
        })
      ).unwrap();
      setShowAllTransactionApproval(false);
      console.log({ filterData });
      let data = {};
      if (Object.keys(filterData).length > 0) {
        data = { ...filterData };
      } else {
        data = {
          clientId: '',
          coin: '',
          page: 1,
          size: 10,
          txStatus: 'ALL',
          type: 'all',
        };
      }
      getAllTransactions(data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    const { isEnabled, path, headers, rows } = download;
    if (
      isEnabled &&
      path === 'ALL_TRANSACTIONS' &&
      headers.length === 0 &&
      rows.length === 0
    ) {
      handlePageChange(1, totalCount);
    }
  }, [download]);

  const sendFormattedTxnType = (type) => {
    switch (type) {
      case 1:
      case 18:
        return 'CRYPTO_WITHDRAW';
      case 10:
        case 26:
        return 'CRYPTO_INTERNAL_WITHDRAW';
    }
  };
  console.log('selectedTransaction::', selectedTransaction);
  const handleCryptoApprove = async (amount) => {
    try {
      dispatch(startLoading());
      const res = await dispatch(
        WalletActions.updateCryptoTxnStatus({
          coin: selectedTransaction?.currency,
          typeId: selectedTransaction?.txnId,
          type: selectedTransaction?.type,
          status: 'COMPLETED',
          clientId: selectedTransaction?.clientId,
          trxType: sendFormattedTxnType(selectedTransaction?.type),
          transactionId: selectedTransaction?.txnId,
          amount: amount,
        })
      ).unwrap();
      setShowAllTransactionApproval(false);
      console.log({ filterData });
      let data = {};
      if (Object.keys(filterData).length > 0) {
        data = { ...filterData };
      } else {
        data = {
          clientId: '',
          coin: '',
          page: 1,
          size: 10,
          txStatus: 'ALL',
          type: 'all',
          trxnType: 'ALL',
        };
      }
      getAllTransactions(data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const handleCryptoReject = async () => {
    try {
      dispatch(startLoading());
      const res = await dispatch(
        WalletActions.updateCryptoTxnStatus({
          coin: selectedTransaction?.currency,
          typeId: selectedTransaction?.txnId,
          type: selectedTransaction?.type,
          status: 'FAILED',
          clientId: selectedTransaction?.clientId,
          trxType: sendFormattedTxnType(selectedTransaction?.type),
          transactionId: selectedTransaction?.txnId,
        })
      ).unwrap();
      setShowAllTransactionRejectModal(false);
      console.log({ filterData });
      let data = {};
      if (Object.keys(filterData).length > 0) {
        data = { ...filterData };
      } else {
        data = {
          clientId: '',
          coin: '',
          page: 1,
          size: 10,
          txStatus: 'ALL',
          type: 'all',
          trxnType: 'ALL',
        };
      }
      getAllTransactions(data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };
  return (
    <>
      <div className='table-design'>
        <div className='title-filter flexEnd'>
          <div className='title-filter_rightSec'>
            <AllTransactionFilters
              setFilterData={setFilterData}
              pageSize={pageSize}
              getAllTransactions={getAllTransactions}
              clientId={user?.clientId}
              setPage={setPage}
              data={data}
            />
            <DownloadFile
              fileName={`all_txns_${new Date().toDateString()}_${new Date().toTimeString()}.csv`}
              type='CSV'
              path='ALL_TRANSACTIONS'
            />
          </div>
        </div>
        <div className='custom-table'>
          <Table
            className='last-center'
            columns={columns}
            dataSource={data}
            onChange={handleChange}
            pagination={false}
            sortDirections={['ascend', 'descend', 'ascend']}
          />
        </div>
      </div>
      <div className='pagination_block'>
        <span className='records' style={{ marginTop: '10px' }}>
          Total Records: {totalCount}
        </span>

        {totalCount > 10 && (
          <Pagination
            defaultCurrent={page}
            total={totalCount}
            pageSize={pageSize}
            onChange={handlePageChange}
            current={page}
          />
        )}
      </div>

      <CustomModal
        title='Are you sure, you want to cancel this transaction?'
        open={showAllTransactionRejectModal}
        onOk={
          selectedTransaction?.isCrypto
            ? handleCryptoReject
            : handleFiatRejection
        }
        onCancel={() => setShowAllTransactionRejectModal(false)}
      ></CustomModal>

      <ApproveFiatTxnModal
        amountToVerify={
          selectedTransaction?.isCrypto
            ? TransactionFeeService.getFeeAmountAfterPrecision(
                selectedTransaction.currency,
                selectedTransaction.amount
              )
            : formatFiatValues(Number(selectedTransaction?.amount))
        }
        showApproveFiatTxn={showApproveFiatTxn}
        setShowApproveFiatTxn={setShowAllTransactionApproval}
        callback={
          selectedTransaction?.isCrypto ? handleCryptoApprove : onSubmit
        }
      />
    </>
  );
};

export default All;
