import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AdminActions } from '../../redux/features/admin/adminActions';
import {
  finishLoading,
  startLoading,
} from '../../redux/features/loading/loadingSlice';

function GetOTP() {
  const [optList, setOtpList] = useState();
  const dispatch = useDispatch();

  const getOtp = async () => {
    try {
      await dispatch(startLoading());
      let res = await dispatch(AdminActions.getAllOTP()).unwrap();
      console.log('resp:::', res);
      setOtpList(res);
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  useEffect(() => {
    getOtp();
  }, []);

  return (
    <div>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black', padding: '8px' }}>
              Sr No.
            </th>

            <th style={{ border: '1px solid black', padding: '8px' }}>Email</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>OTP</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Time</th>
          </tr>
        </thead>
        <tbody>
          {optList?.map((item, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid black', padding: '8px' }}>
                {index + 1}
              </td>
              <td style={{ border: '1px solid black', padding: '8px' }}>
                {item.email}
              </td>
              <td style={{ border: '1px solid black', padding: '8px' }}>
                {item.otp}
              </td>
              <td style={{ border: '1px solid black', padding: '8px' }}>
                {new Date(item.createdAt).toLocaleDateString()} {new Date(item.createdAt).toLocaleTimeString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default GetOTP;
