import React, { useEffect } from 'react';
import { Table, Input, Button, Popover } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import Edit from '../../../Assets/Images/greyEdit.svg';
import { FormError } from '../../../Components/FormErrors/FormError';
import TableFilterTittle from '../../../Components/TableFilterTittle/TableFilterTittle';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '../../../redux/features/user/userActions';
import { Validations } from '../../../validations';
import { yupResolver } from '@hookform/resolvers/yup';
import FiatLimitForm from './FiatLimitForm';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { checkPermissionFn } from '../../../constants/commonFunction';
import { useLocation } from 'react-router-dom';

const formBox = {
  width: '30%',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
};

const CryptoLimitForm = ({ key }) => {
  const { transactionDetail, selectedTab } = useSelector(
    (state) => state?.user
  );
  const location = useLocation();
  const dashboardData = useSelector((state) => state.dashboard);
  const adminData = useSelector((state) => state.admin);
  const role = adminData.data?.title;

  const { routes } = dashboardData;
  const currentRoute = location.pathname;

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
    trigger,
    reset,
    clearErrors,
  } = useForm({
    resolver: yupResolver(Validations.cryptoTransactionLimitValidation),
    mode: 'all',
  });

  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    const result = await dispatch(
      UserActions.updateCryptoTransactionLimitsDetail({
        dailyLimit: Number(values.cryptoTransactionLimit),
        businessDailyLimit: Number(values.businessDailyLimit),
        setGlobal: true,
        apiAccessKey: 'FEE_SETTING',
      })
    ).unwrap();
    setDefaultTransactionLimit();
    console.log(result, 'result');
  };

  // useEffect(() => {
  //   console.log('selectedTab', selectedTab);
  //   if (selectedTab === 'Settings') {
  //     setDefaultTransactionLimit();
  //   }
  // }, [selectedTab]);

  useEffect(() => {
    setDefaultTransactionLimit();
  }, [key]);

  const setDefaultTransactionLimit = async () => {
    const data = await dispatch(
      UserActions.getCryptoTransactionLimitsDetail({
        apiAccessKey: 'FEE_SETTING',
      })
    ).unwrap();
    setValue('cryptoTransactionLimit', data?.globalTrnxLimit?.dailyLimit || 0);
    setValue(
      'businessDailyLimit',
      data?.globalTrnxLimit?.businessDailyLimit || 0
    );
  };

  const columns = [
    {
      title: 'Fiat Transaction Limit',
      dataIndex: 'transactionLimit',
      key: 'transactionLimit',
      render: (_, record) => {
        return <FiatLimitForm />;
      },
    },

    {
      title: 'Crypto Transaction Limit',
      dataIndex: 'cryptoTransactionLimit',
      key: 'cryptoTransactionLimit',
      render: (_, record) => {
        return (
          <form style={formBox} onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
            >
              {/* Individual Limit Section */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <label
                    htmlFor='cryptoTransactionLimit'
                    style={{ minWidth: '100px' }}
                  >
                    Individual Limit
                  </label>
                  <Controller
                    name='cryptoTransactionLimit'
                    control={control}
                    render={({ field }) => (
                      <Input
                        id='cryptoTransactionLimit'
                        placeholder='0'
                        {...field}
                        suffix={<span style={{ color: 'grey' }}>USD</span>}
                        prefix='$'
                        style={{ minWidth: '200px' }}
                      />
                    )}
                  />
                </div>
                <div style={{ marginLeft: '110px', marginTop: '5px' }}>
                  {errors.cryptoTransactionLimit && (
                    <FormError
                      message={errors.cryptoTransactionLimit.message}
                    />
                  )}
                </div>
              </div>

              {/* Business Limit Section */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <label
                    htmlFor='businessDailyLimit'
                    style={{ minWidth: '100px' }}
                  >
                    Business Limit
                  </label>
                  <Controller
                    name='businessDailyLimit'
                    control={control}
                    render={({ field }) => (
                      <Input
                        id='businessDailyLimit'
                        placeholder='0'
                        {...field}
                        suffix={<span style={{ color: 'grey' }}>USD</span>}
                        prefix='$'
                        style={{ minWidth: '200px' }}
                      />
                    )}
                  />
                </div>
                <div style={{ marginLeft: '110px', marginTop: '5px' }}>
                  {errors.businessDailyLimit && (
                    <FormError message={errors.businessDailyLimit.message} />
                  )}
                </div>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent:'end' }}
              >
                <Button
                  type='primary'
                  htmlType='submit'
                  key='1'
                  style={{ marginTop: '20px' }}
                  disabled={!checkPermissionFn(routes,currentRoute,role)}
                >
                  Update
                </Button>
              </div>
            </div>
          </form>
        );
      },
    },
  ];

  return (
    <div>
      <div className='table-design'>
        <TableFilterTittle title='Daily Transactions Limit' />
        <div className='custom-table'>
          <Table
            style={{ height: '300px' }}
            dataSource={[1]}
            columns={columns}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default CryptoLimitForm;
