import { createAsyncThunk } from '@reduxjs/toolkit';
import { CONSTANTS } from '../../../constants';
import { Utils } from '../../../utils';

const { COMPLIANCE, KYC, ADMIN } = CONSTANTS.API_ENDPOINTS;

const getKytDetails = createAsyncThunk(
  'complice/getKytDetails',
  async (data, { rejectWithValue, dispatch }) => {
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${COMPLIANCE.GET_KYT_DETAILS}/${data.offset}/${data.size}`,

        method: 'GET',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'TRANSACTIONS_SCREENING',
        },
        params: {
          type: data.type,
          txnStatus: data.txnStatus,
          searchBy: data.searchBy,
          startDate: data.startDate,
          endDate: data.endDate,
        },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);

const updateToKytStatus = createAsyncThunk(
  'complice/updateToKytStatus',
  async (data, { rejectWithValue, dispatch }) => {
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${COMPLIANCE.UPDATE_KYT_STATUS}`,
        data: data,
        method: 'POST',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'TRANSACTIONS_SCREENING',
        },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);

const getComplianceKyc = createAsyncThunk(
  'complice/getComplianceKyc',
  async (data, { rejectWithValue, dispatch }) => {
    // let { completedByUserId } = data;
    console.log('data::in api::', data);
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${COMPLIANCE.GET_COMPLIANCE_KYC}/${data.offset}/${data.limit}/${data.type}`,
        data,
        method: 'POST',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'VERIFICATIONS_SCREENING',
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);

const getComplianceAnalytics = createAsyncThunk(
  'complice/getComplianceAnalytics',
  async (data, { rejectWithValue, dispatch }) => {
    const { userType, limit, offset } = data;
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${COMPLIANCE.GET_COMPLIANCE_DATA}`,

        method: 'GET',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'VERIFICATIONS_SCREENING',
        },
        params: {
          userType,
          limit,
          offset,
        },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      return res;
    }
  }
);

const getUserDetail = createAsyncThunk(
  'compliance/getUserDetail',
  async ({ userId = -1 }, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${COMPLIANCE.GET_USER_DETAIL}/${userId}`,
        data: {},
        method: 'GET',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'VERIFICATIONS_SCREENING',
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const updateKycStatus = createAsyncThunk(
  'compliance/updateKycStatus',
  async (data, { rejectWithValue, dispatch }) => {
    return Utils.api.baseApiCall(
      {
        endpoint: `${COMPLIANCE.UPDATE_KYC_STATUS}`,
        data,
        method: 'POST',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'VERIFICATIONS_SCREENING',
        },
      },
      { rejectWithValue, dispatch }
    );
  }
);

const getTransactionStatus = createAsyncThunk(
  'compliance/getTransactionStatus',
  async (data, { rejectWithValue, dispatch }) => {
    return Utils.api.baseApiCall(
      {
        endpoint: `${COMPLIANCE.GET_TRANSACTION_STATUS}`,
        data,
        method: 'POST',
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'TRANSACTIONS_SCREENING',
        },
      },
      { rejectWithValue, dispatch }
    );
  }
);

const uploadKycDoc = createAsyncThunk(
  'compliance/uploadKycDoc',
  async (data, { rejectWithValue, dispatch }) => {
    return Utils.api.baseApiCall(
      {
        // endpoint: `${KYC.UPLOAD_KYC_DOC}`,
        endpoint: `${ADMIN.UPLOAD_KYT_DOC}`,
        data,
        method: 'POST',
        assignedHeaders: {
          withToken: true,
          isMultipart: true,
          apiAccessKey: 'TRANSACTIONS_SCREENING',
        },
      },
      { rejectWithValue, dispatch }
    );
  }
);

export const ComplianceActions = {
  getKytDetails,
  updateToKytStatus,
  getComplianceKyc,
  getComplianceAnalytics,
  getUserDetail,
  updateKycStatus,
  getTransactionStatus,
  uploadKycDoc,
};
