import React, { useEffect, useState } from 'react';
import UserInfo from './UserInfo';
import style from './style.module.scss';
import { Divider, Modal, Typography, Image } from 'antd';
import { Enviroments } from '../../../constants/environment';
import CustomUpload from '../../../Components/customUpload/CustomUpload';
import Questionaire from '../Questionaire/Questionaire';
import Ubos from '../Ubos';
import { KYCService } from '../../../services/kyc.service';
import PersonalInfoList from '../PersonalInfoList/PersonalInfoList';
import { PersonalInfoService } from '../../../services/personalInfo.service';
import axios from 'axios';
import { Utils } from '../../../utils';
import KycUpload from './KycUpload';
import { FilePdfOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { CONSTANTS } from '../../../constants';
import {
  createFullName,
  formatAdminNameWithRole,
  formatDateTime,
  isJsonValid,
} from '../../../utils/common.utils';
import PermissionCheck from '../../../Components/PermissionCheck/PermissionCheck';

function UserInformation(props) {
  console.log('props::', props);
  const { Title } = Typography;
  const kycDocUrl = Enviroments.KYC_DOCS_URL;
  const userData = props.data?.user;
  const mb20 = { margin: '20px 0' };
  const {
    getOrganizationData,
    getPermanentAddressData,
    getUserInformationData,
  } = KYCService;
  const [documentDocs, setDocumentDocs] = useState([]);
  const kycData = useSelector((state) => {
    return state.user.data?.kyc;
  });

  const [alerts, setAlerts] = useState([]);

  const getKycDocsData = () => {
    if (userData?.accountType === 'INDIVIDUAL') {
      return kycData?.individualProfile?.individualDocs;
    } else {
      return kycData?.businessProfile?.businessDocs;
    }
  };

  const getKycProfileData = () => {
    if (userData?.accountType === 'INDIVIDUAL') {
      return props.data?.kyc?.individualProfile;
    } else {
      return props.data?.kyc?.businessProfile;
    }
  };
  const kycProfileData = getKycProfileData();
  const kycDocsData = getKycDocsData();

  const getStatus = () => {
    if (kycProfileData) {
      return kycProfileData;
    }
    return { adminKycStatus: '', sumsubKycStatus: '' };
  };

  const { adminKycStatus, sumsubKycStatus } = getStatus();

  const questionairePayload = kycProfileData?.questionairePayload;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [docImage, setDocImage] = useState('');
  const [questionaireData, setQuestionaireData] = useState(null);
  const [ubos, setUbos] = useState([]);

  const [missingFields, setMissingFields] = useState(null);
  console.log('missingFields::', missingFields);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOnClick = (path) => {
    showModal();
    setDocImage(path);
  };

  const getQuestionaireData = (payload) => {
    if (isJsonValid(payload)) {
      if (kycProfileData?.accountType === 'INDIVIDUAL') {
        setQuestionaireData(JSON.parse(payload));
      } else {
        let obj = JSON.parse(payload);
        let data = obj;
        setQuestionaireData(data);
      }
    } else {
      setQuestionaireData(null);
    }
  };

  const doc = [
    {
      id: 1,
      title: (
        <>
          Upload Additional Document
          <br style={{ marginBottom: '10px' }} />
          <CustomUpload />
        </>
      ),
    },
    { id: 2, title: 'Approved By', about: 'DeeJay (Sub-Admin)' },
    { id: 3, title: 'Verified On', about: '12-12-2023 12:01:22 UTC' },
  ];

  const notifactioonPoint = [
    'Alert 1 : Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
    'Alert 1 : Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
  ];

  const documentData = async () => {
    const documentsWithDocPath = kycDocsData.filter((i) =>
      i.hasOwnProperty('docPath')
    );
    if (doc) {
      let docs = documentsWithDocPath.map(async (doc, index) => {
        let fullDocPath = kycDocUrl + doc.docPath;
        let ext = fullDocPath.substring(fullDocPath.lastIndexOf('.'));
        let isPdf = false;
        if (ext === '.pdf') {
          isPdf = true;
        }
        let { data } = await axios.get(kycDocUrl + doc.docPath, {
          responseType: 'blob',
        });
        data = await Utils.common.blobToBase64(data);

        let fullName = createFullName(
          doc.log?.admin?.firstName,
          doc.log?.admin?.lastName
        );
        let role = doc.log?.admin?.title;

        const out = {
          id: index + 1,
          title: (
            <>
              <p>{doc.docName}</p>
              {!!fullName && !!role && doc.docType === 'ADDITIONAL' && (
                <>
                  <p>
                    <strong>Uploaded By :- </strong>
                    {formatAdminNameWithRole(fullName, role)}
                  </p>
                  <p>
                    <strong>Uploaded On :- </strong>
                    {formatDateTime(doc.log?.updatedAt)}
                  </p>
                </>
              )}
            </>
          ),
          about: !isPdf ? (
            <>
              <Image
                src={data}
                alt='img'
                style={{ width: '150px', height: 'auto' }}
                className='cursorpointer'
              />
            </>
          ) : (
            <>
              <FilePdfOutlined style={{ fontSize: '50px' }} />
            </>
          ),
          docType: doc?.docType,
          userId: doc?.userId,
          docId: doc?.id,
          docPath: doc?.docPath,
        };
        return out;
      });

      docs = await Promise.all(docs);
      setDocumentDocs(docs);
    }
  };

  const getUbos = () => {
    setUbos(kycProfileData?.ubos || []);
  };

  const getIndividualMissingInfo = () => {
    let info = kycProfileData?.individualAccountInf;
    if (isJsonValid(info)) {
      if (userData?.accountType === 'INDIVIDUAL') {
        let data = JSON.parse(info);
        let newData = PersonalInfoService.getIndividualPersonalInfo(data);
        setMissingFields(newData);
      } else if (userData?.accountType === 'BUSINESS') {
        let data = JSON.parse(info);
        let newData = PersonalInfoService.getBusinessPersonalInfo(data);
        setMissingFields(newData);
      }
    } else {
      setMissingFields(null);
    }
  };

  const getAlerts = () => {
    if (kycData?.moderationComments) {
      setAlerts(kycData?.moderationComments);
    }
  };

  useEffect(() => {
    getQuestionaireData(questionairePayload);
    getUbos();
    getIndividualMissingInfo();
    documentData();
    getAlerts();
  }, [questionairePayload]);

  useEffect(() => {
    documentData();
  }, [kycData]);

  return (
    <>
      <div className={style.userinfortop}>
        <UserInfo
          checkdata='User Information'
          list={getUserInformationData(userData)}
        />
        {userData?.accountType === 'BUSINESS' && (
          <>
            <Divider style={mb20} />
            <UserInfo
              checkdata='Organization Information'
              list={getOrganizationData(userData)}
            />
          </>
        )}

        <Divider style={mb20} />
        <UserInfo
          checkdata='Permanent Address'
          list={getPermanentAddressData(userData)}
        />
        {documentDocs.length > 0 && (
          <>
            <Divider style={mb20} />
            <UserInfo
              checkdata='Document'
              list={documentDocs}
              type='promise'
              kycData={kycData}
            />
          </>
        )}

        <PermissionCheck>
          <KycUpload userData={userData} />
        </PermissionCheck>

        {questionaireData && Object.keys(questionaireData).length > 0 && (
          <>
            <Divider style={mb20} />
            <Questionaire
              data={questionaireData}
              type={kycProfileData?.accountType}
            />
          </>
        )}

        {missingFields && Object.keys(missingFields).length > 0 && (
          <>
            <Divider style={mb20} />
            <PersonalInfoList data={missingFields} />
          </>
        )}

        {ubos && ubos.length > 0 && (
          <>
            <Divider style={mb20} />
            <Ubos data={ubos} />
          </>
        )}
        {/* <Divider style={mb20} /> */}

        {(sumsubKycStatus === CONSTANTS.KYC_STATUS.TEMPORARY_REJECTED ||
          sumsubKycStatus === CONSTANTS.KYC_STATUS.FINAL_REJECTED) && (
          <>
            {alerts && alerts.length > 0 && (
              <div
                className='alert-notification'
                style={{ marginBottom: '20px' }}
              >
                <Title level={5}>Alerts</Title>
                {alerts.map((point, index) => (
                  <p>
                    {`${index + 1} :- `}
                    {point}
                  </p>
                ))}
              </div>
            )}
          </>
        )}
      </div>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={403}
        footer={false}
        className='verificationmodal'
        maskClosable={false}
      >
        <div className='identitycard'>
          <img src={docImage} alt='imgs' />
        </div>
      </Modal>
    </>
  );
}

export default UserInformation;
