import React, { useState } from 'react';
import { Table } from 'antd';
import { Utils } from '../../../utils';
import { CONSTANTS } from '../../../constants';
import FiatSettingsModal from './FiatSettingsModal';
import Edit from '../../../Assets/Images/greyEdit.svg';
import PermissionCheck from '../../../Components/PermissionCheck/PermissionCheck';

const FaitTransationSystem = ({ settings, getFiatFee }) => {
  const { formatFiatValues, checkEmptyString } = Utils.common;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(null);

  const openFiatUpdateModal = (obj) => {
    console.log({ selectedObj: obj });
    setSelectedCoin(obj);
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      render: (_, record) => (
        <div>
          <span
            style={{ display: 'inline-flex', gap: '5px', alignItems: 'center' }}
          >
            <img
              src={`${
                CONSTANTS.ENV.KYC_DOCS_URL
              }/stage-fcbank/all_icons/${record.currency?.toLowerCase()}.png`}
              style={{ width: '30px', height: 'auto' }}
            />
            {record.currency}
          </span>
        </div>
      ),
    },
    // {
    //   title: "Fee",
    //   dataIndex: "fee",
    //   key: "fee",
    //   render: (_, record) => {
    //     return  formatFiatValues(record.fee);
    //   },
    // },
    {
      title: 'Fee Type',
      dataIndex: 'feeType',
      key: 'feeType',
      render: (_, record) => {
        return checkEmptyString(record.feeType);
      },
    },

    {
      title: 'External Transfer Fee',
      dataIndex: 'externalFee',
      key: 'externalFee',
      render: (_, record) => formatFiatValues(record.externalFee),
    },

    {
      title: 'Internal Transfer Fee',
      dataIndex: 'internalFee',
      key: 'internalFee',
      render: (_, record) => {
        return formatFiatValues(record.internalFee);
      },
    },
    {
      title: 'Min External Withdraw',
      dataIndex: 'minExternalWithdraw',
      key: 'minExternalWithdraw',
      render: (_, record) => {
        console.log('record', record);
        return formatFiatValues(record?.minExternalWithdraw) || '-';
      },
    },
    {
      title: 'Max External Withdraw',
      dataIndex: 'maxExternalWithdraw',
      key: 'maxExternalWithdraw',
      render: (_, record) => {
        return formatFiatValues(record?.maxExternalWithdraw) || '-';
      },
    },
    {
      title: 'Min Internal Withdraw',
      dataIndex: 'minInternalWithdraw',
      key: 'minInternalWithdraw',
      render: (_, record) => {
        return formatFiatValues(record?.minInternalWithdraw) || '-';
      },
    },
    {
      title: 'Max Internal Withdraw',
      dataIndex: 'maxInternalWithdraw',
      key: 'maxInternalWithdraw',
      render: (_, record) => {
        return formatFiatValues(record?.maxInternalWithdraw) || '-';
      },
    },
    // {
    //   title: 'Daily Limit',
    //   dataIndex: 'dailyTrxLimit',
    //   key: 'dailyTrxLimit',
    //   render: (_, record) => {
    //     return formatFiatValues(record?.dailyTrxLimit) || '-';
    //   },
    // },
    {
      title: 'Doc Maximum Withdraw',
      dataIndex: 'docMaximumWithdraw',
      key: 'docMaximumWithdraw',
      render: (_, record) => {
        return formatFiatValues(record?.docMaximumWithdraw) || '-';
      },
    },

    {
      title: 'Action',
      render: (_, record) => {
        const isDisabled = record?.txnType === 'DEPOSIT';
        return (
          <>
          <PermissionCheck>
            <img
              src={Edit}
              aria-disabled={record?.txnType === 'DEPOSIT'}
              style={{ cursor: 'pointer' }}
              alt="icon"
              onClick={() => {
                if (!isDisabled) {
                  openFiatUpdateModal(record);
                }
              }}
            />
            </PermissionCheck>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="table-design">
        <div className="title-filter">
          <h3>Fiat Wallets</h3>
        </div>
        <div className="custom-table">
          <Table
            dataSource={settings}
            columns={columns}
            pagination={false}
            //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>

      <FiatSettingsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        selectedCoin={selectedCoin}
        getFiatFee={getFiatFee}
      />
    </>
  );
};

export default FaitTransationSystem;
