import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../constants";
import { Utils } from "../../../utils";

const { SETTING } = CONSTANTS.API_ENDPOINTS;

const getTradeManagement = createAsyncThunk(
  "setting/trade/management",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.TRADE_MANAGEMENT}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getActivePairs = createAsyncThunk(
  "setting/get-all-trading-pairs",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.ACTIVE_TRADING_PAIRS}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const updateTradeManagementStatus = createAsyncThunk(
  "setting/update/trade/management",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey, ...restData } = data;

    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.UPDATE_TRADE_MANAGEMENT_STATUS}`,
        data: restData,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const deleteTradingPair = createAsyncThunk(
  "setting/deletePairName",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey, ...restData } = data;
console.log("restData::",restData);
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.DELETE_TARING_PAIR}/${data.id}`,
        // data: restData,
        method: "DELETE",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);
const addTradingPairs = createAsyncThunk(
  "setting/add/trade/pair",
  async (data, { rejectWithValue, dispatch }) => {
    const { apiAccessKey, ...restData } = data;

    const res = Utils.api.baseApiCall(
      {
        endpoint: `${SETTING.ADD_TRADE_PAIR}`,
        data: restData,
        method: "POST",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

export const SettingTradeActions = {
  getTradeManagement,
  updateTradeManagementStatus,
  deleteTradingPair,
  addTradingPairs,
  getActivePairs
};
