import React, { useEffect, useState } from "react";
import TableFilterTittle from "../../Components/TableFilterTittle/TableFilterTittle";
import { Modal, Table } from "antd";
import { useDispatch } from "react-redux";
import { Pagination, Button } from "antd";
import ButtonCommon from "../../Components/Buttoncommon/ButtonCommon";
import CustomModal from "../../Components/CustomModal/CustomModal";
import ActivityLogFilters from "./filterCustom";
import {
  checkEmptyString,
  handleHideId,
  sliceString,
  formatDateTime,
  iconColor,
  iconSize,
} from "../../utils/common.utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  startLoading,
  finishLoading,
} from "../../redux/features/loading/loadingSlice";
import ActivityLogDetails from "./activityLogDetail";
import { ActivityActions } from "../../redux/features/activity/activityActions";
import { EyeOutlined } from "@ant-design/icons";

const Logs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showtActivityLogDetail, setShowtActivityLogDetail] = useState(false);
  const [selectedActivityLog, setSelectedActivityLog] = useState(null);
  const [filterData, setFilterData] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [activities, setActivities] = useState({
    list: [],
    totalCounts: 0,
  });

  const location = useLocation();
  const handleCancel = () => {
    setShowtActivityLogDetail(false);
  };

  const getActivityLogDetails = async (obj) => {
    try {
      const res = await dispatch(
        ActivityActions.getActivityByLogId({ logId: obj._id })
      ).unwrap();
      setShowtActivityLogDetail(true);

      setSelectedActivityLog({ ...obj });
    } catch (error) { }
  };

  const getActivities = async (data) => {
    navigate("/logs", {
      state: {
        ...data,
      },
    });

    dispatch(startLoading());
    try {
      const res = await dispatch(ActivityActions.getActivities({ ...data, index: data?.filter && data?.filter?.serviceName == 'admin' ? 'adminactivitylogs' : 'activitylogs' })).unwrap();
      if (res.totalRowCount > 0) {
        const formattedActivityData = formatActivityLogData(res.data);
        setActivities({
          ...activities,
          list: formattedActivityData,
          totalCount: res.totalRowCount,
        });
      } else {
        setActivities({
          list: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const handlePageChange = async (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    const data = {
      offset: (page - 1) * pageSize,
      size: pageSize,
      filter: location.state?.filter,
    };
    getActivities(data);
  };

  const formatActivityLogData = (data) => {
    return data.map((log, index) => {
      return {
        ...log,
        createdAt: formatDateTime(log.createdAt),
      };
    });
  };

  useEffect(() => {
    const obj = location.state;
    let data = {};
    if (obj) {
      if (Object.keys(obj).length === 0) {
        data = {
          offset: 0,
          size: 10,
        };
        getActivities(data);
      } else {
        getActivities(obj);
      }
    } else {
      data = {
        offset: 0,
        size: 10,
      };
      getActivities(data);
    }
  }, []);

  useEffect(() => { }, [location.key]);

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => {
        return checkEmptyString(record.createdAt);
      },
    },
    {
      title: "IP",
      dataIndex: "ipAddress",
      key: "ipAddress",
      render: (_, record) => {
        return checkEmptyString(record?.ipAddress);
      },
    },
    {
      title: "API Slug",
      dataIndex: "slug",
      key: "slug",
      render: (_, record) => {
        return checkEmptyString(sliceString(record.slug, 50));
      },
    },
    {
      title: "Action",
      dataIndex: "title",
      key: "title",
      render: (_, record) => {
        return checkEmptyString(record?.title);
      },
    },
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
      render: (_, record) => {
        return (
          <>
            {record?.userId ? (
              <>{handleHideId(checkEmptyString(record.userId))}</>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "requestType",
      key: "requestType",
      render: (_, record) => {
        return `${checkEmptyString(record?.requestType)} (${record?.statusCode})`
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <EyeOutlined
          style={{ color: iconColor, fontSize: iconSize }}
          onClick={() => getActivityLogDetails(record)}
        />
      ),
    },
  ];

  return (
    <>
      <div className="table-design">
        <div className="title-filter">
          <h3>Activity Logs</h3>
          <div style={{ display: "inline-flex", gap: "15px" }}>
            <ActivityLogFilters
              setFilterData={setFilterData}
              pageSize={pageSize}
              setPageSize={setPageSize}
              getActivities={getActivities}
              logId={selectedActivityLog?._id}
              setPage={setPage}
              data={activities.list}
            />
          </div>
        </div>
        <div className="custom-table">
          <Table
            dataSource={activities.list}
            columns={columns}
            pagination={false}
          //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>
      <div className="pagination_block">
        <span className="records">
          Total Records: {activities.totalCount > 0 ? activities.totalCount : 0}
        </span>
        {activities.totalCount > 0 && (
          <Pagination
            defaultCurrent={page}
            total={activities.totalCount}
            pageSize={pageSize}
            onChange={handlePageChange}
            pageSizeOptions={[10, 100, 200, 300, 400, 500]}
            current={page}
          />
        )}
      </div>
      {/* </div> */}
      <Modal
        text="More Details"
        title="Complete Activity Log"
        onCancel={handleCancel}
        open={showtActivityLogDetail}
        maskClosable={false}
        width={"80%"}
        footer={[
          <Button
            type="primary"
            htmlType="button"
            key="1"
            onClick={handleCancel}
          >
            Close
          </Button>,
        ]}
      >
        <ActivityLogDetails data={selectedActivityLog} />
      </Modal>
    </>
  );
};

export default Logs;
