import React from 'react';
import style from './style.module.scss';

function Fees({ clientTransactionData }) {
  const {
    feeInUsd = 0,
    networkFee = 0,
    networkFeeInUsd = 0,
    ninetyDaysNetworkFeeInUsd = 0,
    ninetyDaysfeeInUsd = 0,
    sixtyDaysNetworkFeeInUsd = 0,
    sixtyDaysfeeInUsd = 0,
    thirtyDaysNetworkFeeInUsd = 0,
    thirtyDaysfeeInUsd = 0,
    totalfee = 0,
  } = clientTransactionData?.totalFeeData || {};

  const coinFeeData = clientTransactionData?.feeCoinsData || [];

  const calPercentage = (feeInUsd, networkFeeInUsd) => {
    const value =
      networkFeeInUsd === 0
        ? ((Number(feeInUsd) - Number(networkFeeInUsd)) / Number(feeInUsd)) *
          100
        : Number(feeInUsd) - Number(networkFeeInUsd) === 0 &&
          Number(feeInUsd) == 0
        ? 0
        : ((Number(feeInUsd) - Number(networkFeeInUsd)) /
            Number(networkFeeInUsd)) *
          100;
    return setPrecision(value, 2);
  };

  const removeMinus = (value)=>{
   return value < 0
    ? value.replace('-','')
    : value;
  }

  const calculatePercentageWithArrow = (feeInUsd, networkFeeInUsd) => {
    const percentage = calPercentage(feeInUsd, networkFeeInUsd);
    const difference = Number(feeInUsd) - Number(networkFeeInUsd);
    const formattedDifference =
      difference < 0
        ? `-$${setPrecision(Math.abs(difference), 2)}`
        : `$${setPrecision(difference, 2)}`;
    // const isPercentagePositive =
    console.log(
      'formattedDifference::',
      formattedDifference,
      'difference::',
      difference
    );

    if (difference > 0) {
      return (
        <>
          {formattedDifference}
          <span style={{ marginLeft: '10px' }} className={style.positiveDelta}>
            ↑ {removeMinus(percentage)}%
          </span>
        </>
      );
    } else if (difference < 0) {
      return (
        <>
          {formattedDifference}
          <span style={{ marginLeft: '10px' }} className={style.negativeDelta}>
            ↓ {removeMinus(percentage)}%
          </span>
        </>
      );
    } else {
      return (
        <>
          {formattedDifference}
          <span style={{ marginLeft: '10px' }} className={style.neutralDelta}>
            {removeMinus(percentage)}%
          </span>
        </>
      );
    }
  };

  const monthName = new Date().toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });

  const setPrecision = (value, toFix) => {
    if (value == 0) {
      return Number(value).toFixed(toFix);
    }
    return Number(value).toFixed(toFix);
  };

  return (
    <section className={style.dashboardFee}>
      <h2>Fees</h2>
      <div className={style.feeCards}>
        <div className={style.card}>
          <p className={style.cardTitle}>{monthName}, Current Month</p>
          <table className={style.feeTable}>
            <thead>
              <tr>
                <th></th>
                <th>Expenses</th>
                <th>Income</th>
                <th>Delta</th>
              </tr>
            </thead>
            <tbody style={{ wordBreak: 'break-all' }}>
              <tr>
                <td>Total</td>
                <td>${setPrecision(networkFeeInUsd, 2)}</td>
                <td>${setPrecision(feeInUsd, 2)}</td>
                <td>
                  {calculatePercentageWithArrow(feeInUsd, networkFeeInUsd)}
                </td>
              </tr>
              {coinFeeData
                .sort((a, b) => a.chain.localeCompare(b.chain))
                .map((item) => (
                  <tr>
                    <td>{item.coin.toUpperCase()}</td>
                    <td>
                      {item.chain.toUpperCase() === 'EUR'
                        ? setPrecision(item.networkFee, 2)
                        : setPrecision(item.networkFee, 6)}{' '}
                      {item.chain.toUpperCase()} ≈ $
                      {setPrecision(item?.networkFeeInUsd, 2)}
                    </td>
                    <td>
                      {item.chain.toUpperCase() === 'EUR'
                        ? `$${setPrecision(item.fee, 2)}`
                        : `${setPrecision(
                            Number(item.fee) / 100000000,
                            6
                          )}`}{' '}
                      ≈ ${setPrecision(item?.feeInUsd, 2)}
                    </td>
                    <td>
                      {calculatePercentageWithArrow(
                        item.feeInUsd,
                        item.networkFeeInUsd
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className={style.card}>
          <p className={style.cardTitle}>Previous months, Totals</p>
          <table className={style.feeTable}>
            <thead>
              <tr>
                <th></th>
                <th>Expenses</th>
                <th>Income</th>
                <th>Delta</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Last 30 days</td>
                <td>${setPrecision(thirtyDaysNetworkFeeInUsd, 2)}</td>
                <td>${setPrecision(thirtyDaysfeeInUsd, 2)}</td>
                <td>
                  {calculatePercentageWithArrow(
                    thirtyDaysfeeInUsd,
                    thirtyDaysNetworkFeeInUsd
                  )}
                </td>
              </tr>
              <tr>
                <td>Last 60 days</td>
                <td>${setPrecision(sixtyDaysNetworkFeeInUsd, 2)}</td>
                <td>${setPrecision(sixtyDaysfeeInUsd, 2)}</td>
                <td>
                  {calculatePercentageWithArrow(
                    sixtyDaysfeeInUsd,
                    sixtyDaysNetworkFeeInUsd
                  )}
                </td>
              </tr>
              <tr>
                <td>Last 90 days</td>
                <td>${setPrecision(ninetyDaysNetworkFeeInUsd, 2)}</td>
                <td>${setPrecision(ninetyDaysfeeInUsd, 2)}</td>
                <td>
                  {calculatePercentageWithArrow(
                    ninetyDaysfeeInUsd,
                    ninetyDaysNetworkFeeInUsd
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default Fees;
