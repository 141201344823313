import React, { useState } from "react";
import { useEffect } from "react";
import { Table, Pagination } from "antd";
import { useDispatch } from "react-redux";
import ButtonCommon from "../../../Components/Buttoncommon/ButtonCommon";
import { DownloadOutlined } from "@ant-design/icons";
import dollar from "../../../Assets/Images/dollar.png";
import euro from "../../../Assets/Images/euro.png";
import pound from "../../../Assets/Images/bnb.png";
import { WalletActions } from "../../../redux/features/wallet/walletActions";
import { TransactionFeeService } from "../../../services/transaction-fee.service";

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const CryptoWallet = (props) => {
  console.log({ props });

  const columns = [
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (_, record) => (
        <div>
          <span
            style={{ display: "inline-flex", gap: "5px", alignItems: "center" }}
          >
            <img
              src={record.currency}
              style={{ width: "30px", height: "auto" }}
            />
            {record.coin.toUpperCase()}
          </span>
        </div>
      ),
    },
    {
      title: "Total Balance",
      dataIndex: "totalBalance",
      key: "totalBalance",
      render: (_, record) => {
        const value = record.avlBalance + record.blocked;
        return TransactionFeeService.checkIsNumberExponential(TransactionFeeService.getFeeAmountAfterPrecision(
          record.coin,
          value
        ));
      },
    },
    {
      title: "Avl Balance",
      dataIndex: "avlBalance",
      key: "avlBalance",
      render: (_, record) => {
        const value = record.avlBalance;
        return TransactionFeeService.checkIsNumberExponential(TransactionFeeService.getFeeAmountAfterPrecision(
          record.coin,
          value
        ));
      },
    },
    {
      title: "Blocked",
      dataIndex: "blocked",
      key: "blocked",
      render: (_, record) => {
        const value = record.blocked;
        return TransactionFeeService.getFeeAmountAfterPrecision(
          record.coin,
          value
        );
      },
    },

    // {
    //   title: "Reserved",
    //   dataIndex: "reserved",
    //   key: "reserved",
    // },
  ];
  return (
    <>
      <div className="table-design">
        <div className="title-filter">
          <h3>Crypto Wallets</h3>
          <div className="title-filter_rightSec">
            <ButtonCommon custmcls="height40">
              <DownloadOutlined style={{ color: "grey" }} />
            </ButtonCommon>
          </div>
        </div>
        <div className="custom-table">
          <Table
            dataSource={props.data?.list}
            columns={columns}
            pagination={false}
            //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>
      {/* <Pagination
        // defaultCurrent={page}
        // total={users.totalCount}
        pageSize={10}
        // onChange={handlePageChange}
      /> */}
    </>
  );
};

export default CryptoWallet;
