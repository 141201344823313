import React from "react";
import style from "./style.module.scss";

function Currencycard({ img, heading, data, rightdata,balance }) {
  return (
    <div className={style.currencycard}>
      <div className={style.leftdata}>
        <span>{img}</span>
        <p>
          <h4>{heading}</h4>
          {data}
        </p>
      </div>
      <div style={{display:'flex',flexDirection:'column',gap:'5px',textAlign:'right'}}>
      <h4>{balance}</h4>
      <h4>{rightdata}</h4>
      </div>
    </div>
  );
}

export default Currencycard;
