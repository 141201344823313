import { Divider } from 'antd';
import React, { useState } from 'react';
import UserInfo from '../../Pages/KycVerification/UserInformation/UserInfo.jsx';
import { useDispatch } from 'react-redux';
import {
  finishLoading,
  startLoading,
} from '../../redux/features/loading/loadingSlice';
import CustomUpload from '../customUpload/CustomUpload.jsx';
import { KycActions } from '../../redux/features/kyc/kycActions';
import { ComplianceActions } from '../../redux/features/compliance/complianceAtion';
import { setDocList } from '../../redux/features/kyc/kycSlice.js';
import Title from 'antd/es/typography/Title.js';
import PermissionCheck from '../PermissionCheck/PermissionCheck.jsx';

const KycUploadKYT = ({ userData, txnId }) => {
  const mb20 = { margin: '20px 0' };
  const dispatch = useDispatch();
  const [docTitle, setDocTitle] = useState('');
  const [showDocTitleModal, setShowDocTitleModal] = useState(false);
  const uploadFile = async (file) => {
    try {
      const formData = new FormData();

      // formData.append("file", file);
      // const fileType = file.name.split(".").pop();
      // formData.append("fileType", fileType);
      // formData.append("userId", userData?.userId);
      // formData.append("docName", docTitle);
      // formData.append("txnId", txnId)
      // console.log("file:::",file)
      formData.append('file', file);
      // const fileType = file.name.split(".").pop();
      formData.append('mimeType', file.type);
      formData.append('userId', userData?.userId);
      formData.append('title', docTitle);
      formData.append('mediaId', txnId);
      formData.append('keyName', 'ADDITIONAL_TRANSACTION_PROOF');
      formData.append('service', 'KYT');

      let res = null;
      dispatch(startLoading());
      res = await dispatch(ComplianceActions.uploadKycDoc(formData)).unwrap();
      let data = await getUploadedDocList({
        limit: '10',
        offset: '0',
        userId: userData?.userId,
        txnId: txnId,
        docType: 'ADDITIONAL',
      });

      dispatch(setDocList(data));
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  const getUploadedDocList = async (payload) => {
    try {
      const data = await dispatch(
        KycActions.getKytDocListByTxnId(payload)
      ).unwrap();
      return data;
    } catch (error) {
      return {};
    }
  };

  const allowedTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'image/svg',
    'application/pdf',
  ];

  const doc = [
    {
      id: 1,
      title: (
        <>
          Upload Additional Document
          <br style={{ marginBottom: '10px' }} />
          <CustomUpload
            allowedTypes={allowedTypes}
            uploadFile={uploadFile}
            setDocTitle={setDocTitle}
            docTitle={docTitle}
            setShowDocTitleModal={setShowDocTitleModal}
            showDocTitleModal={showDocTitleModal}
          />
        </>
      ),
    },
  ];

  return (
    <>
      {doc.length > 0 && (
        <>
          <Divider style={mb20} />
          <PermissionCheck>
            <UserInfo
              checkdata={<Title level={3}>Document Upload</Title>}
              list={doc}
              level='3'
            />
          </PermissionCheck>
          {/* <UserInfo checkdata="" list={doc} /> */}
        </>
      )}
    </>
  );
};

export default KycUploadKYT;
