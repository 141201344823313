import React, { useState } from 'react';
import { Table, Modal, Switch } from 'antd';
import { handleHideId } from '../../../utils/common.utils';
import CopyToClipboard from '../../../Components/CopyToClipboard/CopyToClipboard';
import ScreenBreakpoints from '../../../Components/CustomHook/ScreenBreakpoints';
import { useDispatch } from 'react-redux';
import {
  finishLoading,
  startLoading,
} from '../../../redux/features/loading/loadingSlice';
import { UserActions } from '../../../redux/features/user/userActions';
import toast from '../../../services/toast.service';
import { CONSTANTS } from '../../../constants';
import { Utils } from '../../../utils';
import Beneficary from './Beneficary';
import TableFilterTittle from '../../../Components/TableFilterTittle/TableFilterTittle';
import TextArea from 'antd/es/input/TextArea';
import PermissionCheck from '../../../Components/PermissionCheck/PermissionCheck';

const FiatWallet = ({ data, getBeneficiaryData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const [isLargeScreen, isSmallScreen] = ScreenBreakpoints();
  const [selectedData, setSelectedData] = useState(null);
  const [selectedAction, setSelectedAction] = useState();
  const [rejectionReason, setRejectionReason] = useState('');

  const { formatFiatValues } = Utils.common;

  const handleOk = () => {
    if (!rejectionReason) {
      toast.error('Please enter rejection reason');
    } else {
      updateAccountStatus();

      const foundIndex = data.findIndex(
        (x) => x.userId === selectedData.userId
      );
      if (foundIndex !== -1) {
        data[foundIndex].isBlocked = selectedAction;
      }
      setRejectionReason('')
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOnChange = (checked, record) => {
    askDisable(checked, record);
  };

  const askDisable = (checked, record) => {
    setIsModalOpen(true);
    setSelectedData(record);
    setSelectedAction(checked);
  };

  const updateAccountStatus = async () => {
    try {
      dispatch(startLoading());
      const data = {
        userId: selectedData.userId,
        blockAccount: selectedAction,
        blockReason: rejectionReason,
      };
      const res = await dispatch(
        UserActions.updateAccountStatus(data)
      ).unwrap();
      toast.success('Account updated successfully');
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  const columns = [
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      render: (_, record) => (
        <div>
          <span
            style={{ display: 'inline-flex', gap: '5px', alignItems: 'center' }}
          >
            <img
              src={`${
                CONSTANTS.ENV.KYC_DOCS_URL
              }/stage-fcbank/all_icons/${record.currency?.toLowerCase()}.png`}
              style={{ width: '30px', height: 'auto' }}
            />
            {record.currency}
          </span>
        </div>
      ),
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      render: (_, record) =>
        record?.userId ? (
          <>
            {handleHideId(record?.userId)}
            <CopyToClipboard userId={record?.userId} position="top" />
          </>
        ) : (
          '-'
        ),
    },
    {
      title: 'Account Number',
      dataIndex: 'accountNumber',
      key: 'accountNumber',
      render: (_, record) =>
        record?.accountNumber ? (
          <>
            {handleHideId(record?.accountNumber)}
            <CopyToClipboard userId={record?.accountNumber} position="top" />
          </>
        ) : (
          '-'
        ),
    },
    {
      title: 'Bank Name',
      dataIndex: 'bankName',
      key: 'bankName',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Blocked',
      dataIndex: 'isBlocked',
      key: 'isBlocked',
      render: (_, record) => <>{record.isBlocked ? 'Yes' : 'No'}</>,
    },

    {
      title: 'Locked Balance',
      dataIndex: 'lockedBalance',
      key: 'lockedBalance',
      render: (_, record) => {
        return formatFiatValues(record.lockedBalance);
      },
    },

    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (_, record) => {
        return formatFiatValues(record.balance);
      },
    },

    {
      title: 'Actions',
      render: (_, record) => (
       <PermissionCheck>
         <Switch
          size={isLargeScreen ? 'default' : 'small'}
          checkedChildren="Blocked"
          unCheckedChildren="Block"
          checked={record.isBlocked}
          onChange={(checked) => {
            handleOnChange(checked, record);
          }}
        />
       </PermissionCheck>
      ),
    },
  ];

  const checkDataSource = (data) => {
    if (data && data.length > 0) {
      return data;
    }
    return [];
  };
  return (
    <>
      <div className="table-design">
        <TableFilterTittle title="Fiat Wallet" />

        <div className="custom-table">
          <Table
            style={{ height: '200px' }}
            dataSource={checkDataSource(data)}
            columns={columns}
            pagination={false}
          />
        </div>
      </div>
      <div>
        <Beneficary />
      </div>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          className="custom-modal verificationmodal"
          width={403}
          centered
          closeIcon={true}
          maskClosable={false}
        >
          <p style={{ padding: '48px auto 18px auto', textAlign: 'center' }}>
            Are you sure you want to {selectedAction ? 'block' : 'unblock'} this
            account ?
          </p>

          <TextArea
            rows={4}
            placeholder="Enter Reason"
            onChange={(e) => setRejectionReason(e.target.value)}
          />
        </Modal>
      )}
    </>
  );
};

export default FiatWallet;
