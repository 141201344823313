import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiService } from "../services/api.service";
import toast from "../services/toast.service";
import { tokenExpired } from "../redux/features/auth/authSlice";
import { TransactionService } from "../services/transaction.service";
import { CONSTANTS } from "../constants";
import EncryptionService from "../services/encryption.service";

const { INVALID_SIGNATURE, JWT_EXPIRED, SESSION_EXPIRED } =
  CONSTANTS.ERROR_401_MESSAGE;

export const baseApiCall = async (
  { endpoint, data = {}, method = "POST", assignedHeaders, params },
  { rejectWithValue, dispatch }
) => {
  console.log({ endpoint });
  try {
    const { payload: headers } = await dispatch(getHeaders(assignedHeaders));
    let response = null;
    response = await callApiMethod(endpoint, data, method, headers, params);
    if (response.status !== 200) {
      throw new Error("API call failed");
    }
    const apiData = response.data?.response?.data;
    if (method === "POST" || method === "PUT" || method === "PATCH" || method === "DELETE") {
      const message =
        response.data?.response?.message ||
        response.data?.response?.data?.message;
      if (message) {
        toast.success(message);
      }
    }
    return apiData;
  } catch (error) {
    console.log({ error });
    let message = "";
    let noErrorEndPoints = [
      "wallet/get-central-balance",
      "user/get-user-counts",
      "user/get-support-tickets",
    ];

    for (const obj of noErrorEndPoints) {
      if (endpoint.indexOf(obj) !== -1) {
        return;
      }
    }

    if (endpoint === "wallet/transaction/get-crypto-transactions") {
      message = TransactionService.handleErrors(error);
    } else {
      message = handleApiError(error);
    }
    if (error?.code === "ERR_NETWORK") {
      error.message = "Network error";
      toast.error(message);
    }
    if (error.response && error.response.status !== 401) {
      toast.error(message);
    }
    if (isSessionOrJwtMessage(message)) {
      await dispatch(tokenExpired(message));
    }
    return rejectWithValue(message);
  }
};

const isSessionOrJwtMessage = (message) =>
  message === JWT_EXPIRED ||
  message === SESSION_EXPIRED ||
  message === INVALID_SIGNATURE;

export const getHeaders = createAsyncThunk(
  "auth/getHeaders",
  async (assignedHeaders, { getState }) => {
    console.log({ assignedHeaders });
    const state = await getState();
    const { withToken, isMultipart, apiAccessKey } = assignedHeaders;
    if (withToken) {
      const { jwtToken } = state.auth;
      if (isMultipart) {
        return {
          "Content-Type": "application/x-www-form-urlencoded",
          "api-access-token": jwtToken,
          "api-access-key": encryptAccessKey(apiAccessKey),
          "Origin": CONSTANTS.ENV.BASE_API_URL
        };
      } else {
        return {
          "Content-Type": "application/json",
          "api-access-token": jwtToken,
          "api-access-key": encryptAccessKey(apiAccessKey),
          "Origin": CONSTANTS.ENV.BASE_API_URL
        };
      }
    }
    return { "Content-Type": "application/json", "Origin": CONSTANTS.ENV.BASE_API_URL };
  }
);

export const callApiMethod = async (
  endpoint,
  data = {},
  method,
  headers = {},
  params = {}
) => {
  try {
    const apiServiceMethod = method.toLowerCase();
    let response = null;
    if (apiServiceMethod === "post") {
      response = await ApiService.postMethod(endpoint, data, headers, params);
    } else if (apiServiceMethod === "put") {
      response = await ApiService.putMethod(
        endpoint,
        JSON.stringify(data),
        headers,
        params
      );
    } else if (apiServiceMethod === "delete") {
      response = await ApiService.deleteMethod(
        endpoint,
        JSON.stringify(data),
        headers,
        params
      );
    } else if (apiServiceMethod === "patch") {
      response = await ApiService.patchMethod(
        endpoint,
        JSON.stringify(data),
        headers,
        params
      );
    } else {
      response = await ApiService.getMethod(
        endpoint,
        JSON.stringify(data),
        headers,
        params
      );
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const handleApiError = (error) => {
  let message = "";
  if (error.response?.data?.response?.message) {
    message = error.response.data.response.message;
  } else if (error.message) {
    message = error.message;
  }
  return message;
};

const encryptAccessKey = (key) => {
  if (key) {
    let encryptedKey = EncryptionService.encrypt(key);
    return encryptedKey;
  }
  return undefined;
};
