import React from 'react';
import './InfoCard.scss';

const InfoCard = (point) => {

    const {title, detail, titleSub} = point;
    
    return (
        <div className='infoCard'>
            <span className='fieldNameValue ant-typography'>
                <div className='fieldName ant-typography'>{title}</div>
                <div className='fieldValue ant-typography'>{detail}</div>
            </span>
            {titleSub && <div className='fieldSubValue'>{titleSub}</div>}
            {/* {point.detail && <div className='fieldDetail'>{point.detail}</div>} */}
        </div>
    );
}


export default InfoCard;