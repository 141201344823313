import { Table } from "antd";
import React from "react";
import { CONSTANTS } from "../../../constants";
import { TransactionFeeService } from "../../../services/transaction-fee.service";
import { checkEmptyString } from "../../../utils/common.utils";

function LiquiditySwap({ data }) {
 
  const columns = [
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      width:'200px',
      render: (_, record) => {
        return (
          <span className="inlineFlex" style={{ gap: "5px" }}>
            <img
              src={TransactionFeeService.getImageByTokenName(record.currency)}
              style={{ width: "30px", height: "auto" }}
            />
            {checkEmptyString(record.currency?.toUpperCase())}
          </span>
        );
      },
    },

    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      
    },
  ];
  return (
    <>
      <div className="table-design">
        <div className="custom-table">
          <Table
            columns={columns}
            pagination={false}
            dataSource={data}
            // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>
    </>
  );
}

export default LiquiditySwap;
