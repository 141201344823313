import React from 'react';
import styles from './style.module.scss';
import greeenArrow from '../../../Assets/Images/arrowGreen.svg';
import { calculatePercentage } from '../../../utils/common.utils';
function Client({ clientTransactionData }) {
  const { verificationStats } = clientTransactionData;
  
  const {
    totalKyc = 0,
    approvedKycByAdmin = 0,
    approvedKycBySumsub = 0,
    approvedKycByAdminAndSumsub = 0,
    inProcessKyc = 0,
    pendingKyc = 0,
    rejectedKycByAdmin = 0,
    rejectedKycBySumsub = 0,
    totalUsers = 0,
    activatedUsers = 0,
    deletedUsers = 0,
    isBlockedUsers = 0,
    countUsersLast7Days = 0,
    countUsersLast30Days = 0,
    avgMonthlyUsers = 0,
    totalAssetInUsd = 0,
    totalIndividualAssetInUsd = 0,
    totalBusinessAssetInUsd = 0,
    totalIndividualUsers = 0,
    totalBusinessUsers = 0,
    totalUsersWithoutType = 0
  } = verificationStats || {};

  
  return (
    <>
      <div className={styles.dashboard}>
        <main className={styles.content}>
          <section className={styles.clientSection}>
            <h2>Clients</h2>
            <div className={styles.clientCards}>
              <div className={styles.card}>
                <h3>Account Status</h3>
                <div className={styles.statusDetails}>
                  <p>
                    <span className={styles.percetage}>{calculatePercentage(activatedUsers,totalUsers)}%</span>{' '}
                    <span>Active</span>
                    {activatedUsers}
                  </p>
                  <p>
                    <span className={styles.percetage}>{calculatePercentage(totalUsersWithoutType,totalUsers)}%</span>{' '}
                    <span>In-Verification</span> {totalUsersWithoutType}
                  </p>
                  <p>
                    <span className={styles.percetage}>{calculatePercentage(isBlockedUsers,totalUsers)}%</span>{' '}
                    <span>Blocked</span> {isBlockedUsers}
                  </p>
                  <p>
                    <span className={styles.percetage}>{calculatePercentage(deletedUsers,totalUsers)}%</span>
                    <span>Deleted</span> {deletedUsers}
                  </p>
                </div>
                <h3>Verification Status</h3>
                <div className={styles.verificationStatus}>
                  <p>
                    <span className={styles.percetage}>{calculatePercentage(pendingKyc,totalKyc)}%</span>{' '}
                    <span>Pending</span>
                    {pendingKyc}
                  </p>
                  <p>
                    <span className={styles.percetage}>{calculatePercentage(inProcessKyc,totalKyc)}%</span>
                    <span>In-Process</span> {inProcessKyc}
                  </p>
                  <p>
                    <span className={styles.percetage}>{calculatePercentage(approvedKycByAdmin,totalKyc)}%</span>{' '}
                    <span>Verified</span> {approvedKycByAdmin}
                  </p>
                  <p>
                    <span className={styles.percetage}>{calculatePercentage(rejectedKycByAdmin,totalKyc)}%</span>{' '}
                    <span>Rejected</span> {rejectedKycByAdmin}
                  </p>
                </div>
                <h3>
                  Client Distribution <span>Active</span>
                </h3>
                <div className={styles.clientDistribution}>
                  <p>
                    <span className={styles.percetage}>{calculatePercentage(totalIndividualUsers,totalUsers)}%</span>{' '}
                    <span>Individual</span> {totalIndividualUsers}
                  </p>
                  <p>
                    <span className={styles.percetage}>{calculatePercentage(totalBusinessUsers,totalUsers)}%</span>
                    <span>Bussiness</span> {totalBusinessUsers}
                  </p>
                </div>
              </div>
              {/* **************************Acquisition active*************************************** */}
              <div className={styles.card}>
                <h3>
                  Acquisition <span>Active</span>
                </h3>
                <div className={styles.acquisition}>
                  <p>
                    Last 7 Days{' '}
                    <span className={styles.acquisitionCount}>{Number(countUsersLast7Days).toFixed(0)}</span>
                    <span className={styles.increase}>
                      <img
                        style={{ marginRight: '2px' }}
                        src={greeenArrow}
                        alt='icon'
                      />{' '}
                      {calculatePercentage(countUsersLast7Days,totalUsers)}%
                    </span>
                  </p>
                  <p>
                    Last 30 Days{' '}
                    <span className={styles.acquisitionCount}>{Number(countUsersLast30Days).toFixed(0)}</span>
                    <span className={styles.increase}>
                      <img
                        style={{ marginRight: '2px' }}
                        src={greeenArrow}
                        alt='icon'
                      />{' '}
                      {calculatePercentage(countUsersLast30Days,totalUsers)}%
                    </span>
                  </p>
                  <p>
                    Avg Per Month{' '}
                    <span className={styles.acquisitionCount}>{Number(avgMonthlyUsers).toFixed(0)}</span>
                    <span className={styles.increase}>
                      <img
                        style={{ marginRight: '2px' }}
                        src={greeenArrow}
                        alt='icon'
                      />{' '}
                      {calculatePercentage(avgMonthlyUsers,totalUsers)}%
                    </span>
                  </p>
                </div>
              </div>

              {/* **************************Value $*************************************** */}
              <div className={styles.card}>
                <h3>Value $</h3>
                <div className={styles.value}>
                  <p>
                    <span>${Number(totalAssetInUsd).toFixed(2)}</span> Total Value
                  </p>
                  <p>
                    <span>${Number(totalBusinessAssetInUsd).toFixed(2)}</span> AVG Business Account Value
                  </p>
                  <p>
                    <span>${Number(totalIndividualAssetInUsd).toFixed(2)}</span> AVG Individual Account Value
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default Client;
