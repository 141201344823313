import CopyToClipboard from "../Components/CopyToClipboard/CopyToClipboard";
import {
  checkEmptyString,
  formatDateTime,
  handleHideId,
} from "../utils/common.utils";
import { showName } from "./user.service";

const getUserInformationData = (data) => {
  console.log({ kycData: data });
  const isAddress =
    data.accountType && data.accountType === "INDIVIDUAL" ? true : false;

  const userInfoArray = [
    {
      id: 1,
      title: "User ID",
      about: (
        <>
          {data?.userId ?  data?.userId : (
            "-"
          )}
        </>
      ),
    },
    { id: 2, title: "Created", about: formatDateTime(data?.createdAt) },
    { id: 3, title: "Updated", about: formatDateTime(data?.updatedAt) },
    {
      id: 4,
      title: "Name",
      about: showName(data),
    },
    {
      id: 4,
      title: "Email",
      about: checkEmptyString(data?.email),
    },
    {
      id: 5,
      title: "Phone number",
      about: checkEmptyString(data?.phoneNumber),
    },
    {
      id: 6,
      title: "Date of Birth",
      about: checkEmptyString(getDobByAccountType(data)),
    },

    { id: 8, title: "Country", about: checkEmptyString(data?.nationality) },
  ];
  console.log(isAddress);
  if (isAddress) {
    userInfoArray.push({
      id: 7,
      title: "Address",
      about: checkEmptyString(data?.address),
    });
  }

  return userInfoArray.sort((a, b) => a.id - b.id);
};

export const getDobByAccountType = (data) => {
  if (data?.accountType) {
    if (data.accountType === "BUSINESS") {
      return data.incorporationDate;
    } else {
      return data?.dateOfBirth || data?.dob;
    }
  }
};

const getOrganizationData = (data) => [
  {
    id: 1,
    title: "Name of the organisation",
    about: checkEmptyString(data?.businessName),
  },
  // { id: 2, title: "Area of activity", about: "-" },
  // {
  //   id: 3,
  //   title: "State registration number",
  //   about: "-",
  // },
  // { id: 4, title: "ID number", about: data?.taxVatId },
  {
    id: 5,
    title: "Contact number",
    about: checkEmptyString(data?.phoneNumber),
  },
];

const getPermanentAddressData = (data) => {
  let list = [
    {
      id: 1,
      title: "Country",
      about: checkEmptyString(data?.nationality),
    },
    { id: 2, title: "City", about: checkEmptyString(data?.city) },
    // {
    //   id: 3,
    //   title: "District",
    //   about: "_",
    // },
    // { id: 4, title: "Khoroo", about: "_" },
    // { id: 5, title: "Apartment", about: "_" },
    // { id: 6, title: "Door", about: "_" },
  ];

  return data.accountType === "BUSINESS"
    ? list.filter((x) => x.id !== 2).sort()
    : list;
};

export const KYCService = {
  getUserInformationData,
  getOrganizationData,
  getPermanentAddressData,
  getDobByAccountType,
};
