import { Enviroments } from './environment';
import { API_ENDPOINTS } from './apiUrls';

const USER_TYPE = {
  ALL: 'ALL',
  BUSINESS: 'BUSINESS',
  INDIVIDUAL: 'INDIVIDUAL',
};

const VERIFICATION_STATUS = {
  All: 'All',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  PENDING: 'Pending',
};

const CLIENT_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  DELETED: 'Deleted',
  BLOCKED: 'Blocked',
};
const UPDATED_CLIENT_STATUS = {
  All: 'All',
  ACTIVE: 'Active',
  DELETED: 'Deleted',
  BLOCKED: 'Blocked',
};

const VALIDATION_ERRORS = {
  INVALID_EMAIL: 'Invalid email',
  EMAIL_REQUIRED: 'Email is required',
  PASSWORD_REQUIRED: 'Password is required',
  OLD_PASSWORD_REQUIRED: 'Old password is required',
  NEW_PASSWORD_REQUIRED: 'New password is required',
  RE_ENTER_PASSWORD_REQUIRED: 'Re-enter password is required',
  PASSWORDS_MATCH: 'Passwords must match',
  FIRST_NAME_REQUIRED: 'First name is required',
  MINIMUM_LENGTH_FOR_FIRST_NAME:
    'Minimum length for first name is 3 characters',
  MINIMUM_LENGTH_FOR_LAST_NAME: 'Minimum length for last name is 3 characters',
  LAST_NAME_REQUIRED: 'Last name is required',
  TWOFA_ENABLED: '2FA must be true or false',
  START_DATE_GREATER_THAN_END_DATE: "Start date can't be more than end date",
  UNDEFINED_CLIENTID: 'Client ID does not exists',
  OTP_REQUIRED: 'OTP is required',
  TRANSACTION_REFERENCE_REQUIRED: 'Transaction reference id is required',
  TRANSACTION_AMOUNT_REQUIRED: 'Transaction amount is required',
};

const DATE_FORMAT = {
  TIME: 'DD MMM YYYY | HH:mm:ss',
  DATE: 'YYYY/MM/DD',
  TXNDATE: 'YYYY-MM-DD',
};

const MODAL_TEXTS = {
  SIGNIN: 'You are attempting to logout from all devices.',
};

const TRANSACTION_STATUS = {
  0: 'PENDING',
  1: 'UNCONFIRMED',
  2: 'COMPLETED',
  3: 'FAILED',
  4: 'FAILED',
};
const KYT_TXN_STATUS = {
  0: 'APPROVED',
  1: 'REJECTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

const TRANSATION_TYPE = {
  ALL: 'ALL',
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
};

const KYC_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  INITIATED: 'INITIATED',
  FINAL_REJECTED: 'FINAL_REJECTED',
  TEMPORARY_REJECTED: 'TEMPORARY_REJECTED',
};

const FILE_UPLOAD_SETTINGS = {
  BANNER_SIZE_LIMIT: 25 * 1024 * 1024, // in MB,
  KYC_SIZE_LIMIT: 6 * 1024 * 1024, // in MB,
};

const BANNER_TYPE = {
  MOBILE: 'MOBILE',
  WEB: 'WEB',
};

const BANNER_DIMENSIONS = {
  MOBILE: {
    WIDTH: 1434,
    HEIGHT: 512,
  },
  WEB: {
    WIDTH: 1434,
    HEIGHT: 512,
  },
};

const ERROR_401_MESSAGE = {
  JWT_EXPIRED: 'jwt expired',
  SESSION_EXPIRED: 'Session has been expired.',
  INVALID_SIGNATURE: 'invalid signature',
};

const PERMISSION_TYPE = {
  READ: 'READ',
  READWRITE: 'READ-WRITE',
};

const SMALLEST_UNIT = {
  ETH: 100000000,
  TRON: 100000000,
  MATIC: 100000000,
  BTC: 100000000,
  BNB: 100000000,
};

const CRYPTO_CSV_HEADERS = [
  { label: 'Currency', key: 'currency' },
  { label: 'Amount', key: 'amount' },
  { label: 'Type', key: 'type' },
  { label: 'status', key: 'status' },
  { label: 'Created_On', key: 'created' },
];

const SWAP_CSV_HEADERS = [
  { label: 'Order_ID', key: 'orderId' },
  { label: 'From', key: 'fromCurrency' },
  { label: 'To', key: 'toCurrency' },
  { label: 'From_(Amt)', key: 'fromAmount' },
  { label: 'To_(Amt)', key: 'toAmount' },
  { label: 'Fee', key: 'fee' },
  { label: 'Status', key: 'status' },
  { label: 'User_ID', key: 'userId' },
  { label: 'Created_On', key: 'createdAt' },
];

const MODULE_ROUTES = {
  DASHBOARD: '/',
  CLIENT_DIRECTORY: '/user-directory',
  CLIENT_VERIFICATION: '/user-verification',
  FIAT_TRANSACTIONS: '/fiat-transaction',
  CRYPTO_TRANSACTIONS: '/crypto-transaction',
  SWAP_TRANSACTIONS: '/swap-transaction',
  CLIENT_SUPPORT: '/client-support-communication',
  LOGS: '/logs',
  CONTENT: '/content',
  BANNER: '/banner',
  FEE_SETTING: '/transaction-limits',
  USER_MANAGEMENT: '/user-management',
  ROLE_MANAGEMENT: '/role-management',
  SANCTION_REPORT: '/sanction-report',
  TRANSACTIONS_SCREENING: '/transactions-main',
  VERIFICATIONS_SCREENING: '/sanction-report',
};

const MAX_PAGE_LIMIT_CSV = 100;

const COINS_TOKENS = {
  eth: 'eth',
  'usdt(erc-20)': 'usdt',
  'usdc(erc-20)': 'usdc',
  'mat-usdt(erc-20)': 'usdt',
  'mat-usdc(erc-20)': 'usdc',
  bnb: 'bnb',
  'usdt(bep-20)': 'usdt',
  'usdc(bep-20)': 'usdc',
  matic: 'matic',
  'usdc(trc-20)': 'usdc',
  'usdt(trc-20)': 'usdt',
  trx: 'tron',
  btc: 'btc',
  eur: 'eur',
};

const COMPLIANCE_KYC_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

const SWAP_TYPES = [7, 8, 16, 17, 23, 24];

export const CONSTANTS = {
  COMPLIANCE_KYC_STATUS,
  MAX_PAGE_LIMIT_CSV,
  ENV: Enviroments,
  API_ENDPOINTS,
  USER_TYPE,
  VERIFICATION_STATUS,
  CLIENT_STATUS,
  VALIDATION_ERRORS,
  DATE_FORMAT,
  MODAL_TEXTS,
  TRANSACTION_STATUS,
  TRANSATION_TYPE,
  KYC_STATUS,
  FILE_UPLOAD_SETTINGS,
  BANNER_TYPE,
  ERROR_401_MESSAGE,
  SMALLEST_UNIT,
  BANNER_DIMENSIONS,
  CRYPTO_CSV_HEADERS,
  SWAP_CSV_HEADERS,
  PERMISSION_TYPE,
  MODULE_ROUTES,
  COINS_TOKENS,
  UPDATED_CLIENT_STATUS,
  KYT_TXN_STATUS,
  SWAP_TYPES
};
