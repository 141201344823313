import React, { useEffect, useState } from 'react';
import CustomTable from '../../Components/CustomTable/CustomTable';
import { useDispatch } from 'react-redux';
import {
  startLoading,
  finishLoading,
} from '../../redux/features/loading/loadingSlice';
import { SettingActions } from '../../redux/features/setting/settingActions';
import { formatDateTime, handleHideId } from '../../utils/common.utils';
import { Button, Pagination, Space, Tooltip } from 'antd';
import Edit from '../../Assets/Images/greyEdit.svg';
import CustomModal from '../../Components/CustomModal/CustomModal';
import UpdateKmsManagement from './UpdateKmsManagement';
import { Validations } from '../../validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import PermissionCheck from '../../Components/PermissionCheck/PermissionCheck';
import CopyToClipboard from '../../Components/CopyToClipboard/CopyToClipboard';
import { refreshIcon } from '../../Assets/Icons/Icons';
const KmsManagement = () => {
  const form = useForm({
    resolver: yupResolver(Validations.kmsSchema),
    mode: 'all',
  });
  const dispatch = useDispatch();
  const [kmsData, setKmsData] = useState({
    list: [],
    totalCount: 0,
  });

  const [page, setPage] = useState(1);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const pageSize = 100;

  const columns = [
    {
      title: 'Key Name',
      dataIndex: 'keyName',
      key: 'keyName',
    },
    {
      title: 'Key Value',
      dataIndex: 'keyValue',
      key: 'keyValue',
      render: (_) => {
        return (
          <>
            <span style={{ cursor: 'pointer' }}>{handleHideId(_)}</span>

            <CopyToClipboard userId={_} position='top' />
          </>
        );
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => {
        return formatDateTime(record.createdAt);
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => {
        return formatDateTime(record.updatedAt);
      },
    },
    {
      title: 'Action',

      render: (_, record) => (
        <>
          <PermissionCheck>
            <img
              src={Edit}
              alt='icon'
              style={{ cursor: 'pointer' }}
              onClick={() => editKey(record)}
            />
          </PermissionCheck>
        </>
      ),
    },
  ];

  const editKey = (obj) => {
    setSelectedKey(obj);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOk = async (values) => {
    console.log({ values });
    // closeModal();
    try {
      dispatch(startLoading());
      const data = {
        keyName: selectedKey?.keyName,
        keyValue: values.kmsText,
      };
      const res = await dispatch(SettingActions.updateKms(data)).unwrap();
      closeModal();
      const apiData = {
        offset: 0,
        size: pageSize,
      };
      getKmsList(apiData);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };
  const handleCancel = () => {
    closeModal();
  };

  const getKmsList = async (data) => {
    try {
      dispatch(startLoading());
      const res = await dispatch(SettingActions.getKmsList(data)).unwrap();
      if (res && res.rows && res.rows.length > 0) {
        setKmsData({
          list: res.rows || [],
          totalCount: res.count || 0,
        });
      } else {
        setKmsData({
          list: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const handlePageChange = async (page) => {
    setPage(page);
    const data = {
      offset: (page - 1) * pageSize,
      size: pageSize,
    };
    getKmsList(data);
  };

  useEffect(() => {
    const data = {
      offset: 0,
      size: pageSize,
    };
    getKmsList(data);
  }, []);

  const handlerefresh = async () => {
    try {
      dispatch(startLoading());
      const res = await dispatch(SettingActions.refreshKms()).unwrap();
      setIsRefresh(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefresh(false);
      dispatch(finishLoading());
    }
  };
  return (
    <>
      <div className='table-design'>
        <div className='title-filter'>
          <h3>KMS Management</h3>
          <Tooltip title='Refresh'>
            <Button
              type='primary'
              icon={<div style={{ marginTop: '5px' }}>{refreshIcon()}</div>}
              // loading={loadings[2]}
              onClick={() => setIsRefresh(true)}
            />
          </Tooltip>
        </div>

        <div className='custom-table'>
          <CustomTable dataSource={kmsData.list} columns={columns} />
        </div>
      </div>

      <div className='pagination_block'>
        <span className='records' style={{ marginTop: '10px' }}>
          Total Records:{kmsData.totalCount}
        </span>
        {kmsData.totalCount > pageSize && (
          <Pagination
            defaultCurrent={page}
            total={kmsData.totalCount}
            pageSize={pageSize}
            onChange={handlePageChange}
            current={page}
          />
        )}
      </div>

      <CustomModal
        open={isModalOpen}
        footer={[
          <Space key='footer-space'>
            <Button type='default' htmlType='button' onClick={handleCancel}>
              Cancel
            </Button>

            <Button
              type='primary'
              htmlType='submit'
              key='submit-button'
              onClick={form.handleSubmit(handleOk)}
            >
              Update
            </Button>
          </Space>,
        ]}
        title={'Update KMS Management'}
      >
        <UpdateKmsManagement form={form} selectedKey={selectedKey} />
      </CustomModal>
      <CustomModal
        open={isRefresh}
        footer={[
          <Space key='footer-space'>
            <Button
              type='default'
              htmlType='button'
              onClick={() => setIsRefresh(false)}
            >
              No
            </Button>

            <Button
              type='primary'
              htmlType='submit'
              key='submit-button'
              onClick={() => handlerefresh()}
            >
              Yes
            </Button>
          </Space>,
        ]}
        title={'Refresh KMS Management'}
      >
        <p>Are you sure want refresh?</p>
        {/* <UpdateKmsManagement form={form} selectedKey={selectedKey} /> */}
      </CustomModal>
    </>
  );
};

export default KmsManagement;
