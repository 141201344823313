import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import FaitTransationSystem from './FaitTransationSystem';
import CryptoTransationSystem from './CryptoTransationSystem';
import { SettingActions } from '../../../redux/features/setting/settingActions';
import { useDispatch } from 'react-redux';
import {
  startLoading,
  finishLoading,
} from '../../../redux/features/loading/loadingSlice';
import SwapTransationSystem from './SwapTransationSystem';
import { BankingActions } from '../../../redux/features/banking/bankingActions';
import TransactionLimitSetting from './TransactionLimitSetting';

const TransationLimits = () => {
  const [key, setKey] = useState('1');
  const dispatch = useDispatch();
  const [settings, setSettings] = useState([]);

  const [swapSettings, setSwapSettings] = useState({
    list: [],
    totalCount: 0,
  });

  const [fiatFeeSettings, setFiatFeeSettings] = useState([]);
  const onChange = (key) => {
    setKey(key);
  };

  const getWithdrawFee = async (data) => {
    try {
      await dispatch(startLoading());

      const res = await dispatch(
        SettingActions.getWithdrawFee({ apiAccessKey: 'FEE_SETTING' })
      ).unwrap();
      if (res) {
        setSettings(res);
      } else {
        setSettings([]);
      }
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const getSwapWithdrawFee = async (data) => {
    try {
      await dispatch(startLoading());

      const res = await dispatch(
        SettingActions.getSwapWithdrawFee(data)
      ).unwrap();
      if (res?.coinsInfo && res?.coinsInfo.length > 0) {
        setSwapSettings({
          list: res?.coinsInfo,
          totalCount: 0,
        });
      } else {
        setSwapSettings({
          list: [],
          totalCount: 0,
        });
      }
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const getFiatFee = async () => {
    try {
      dispatch(startLoading());
      let res = await dispatch(BankingActions.getFiatFeeList()).unwrap();
      console.log({ fiatFeeSettings: res });
      if (res && res.data && res.data.length > 0) {
        setFiatFeeSettings(res.data);
      } else {
        setFiatFeeSettings([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    if (key === '1') {
      getFiatFee();
    }
    if (key === '2') {
      const data = {
        status: 1,
      };
      getWithdrawFee(data);
    }
    if (key === '3') {
      getSwapWithdrawFee();
    }
  }, [key]);

  const items = [
    {
      key: '1',
      label: 'Fiat Transactions Fee',
      children: (
        <>
          <FaitTransationSystem
            settings={fiatFeeSettings}
            getFiatFee={getFiatFee}
          />
        </>
      ),
    },
    {
      key: '2',
      label: 'Crypto Transactions Fee ',
      children: (
        <>
          <CryptoTransationSystem
            settings={settings}
            getWithdrawFee={getWithdrawFee}
          />
        </>
      ),
    },
    // {
    //   key: '3',
    //   label: 'Swap Transactions Fee',
    //   children: (
    //     <>
    //       <SwapTransationSystem
    //         settings={swapSettings}
    //         getWithdrawFee={getSwapWithdrawFee}
    //       />
    //     </>
    //   ),
    // },
    {
      key: '4',
      label: 'Daily Transactions Limit',
      children: (
        <>
          <TransactionLimitSetting key={key} />
        </>
      ),
      // children: <CardInfo />,
    },
    // {
    //   key: "4",
    //   label: "Setup",
    //   children: (
    //     <>
    //       <SetupSettings />
    //     </>
    //   ),
    //   // children: <CardInfo />,
    // },
    // {
    //   key: "5",
    //   label: "Accounts",
    //   children: (
    //     <>
    //       <AccountSettings />
    //     </>
    //   ),
    //   // children: <CardInfo />,
    // },
    // {
    //   key: "6",
    //   label: "Other",
    //   children: (
    //     <>
    //       <OtherSettings />
    //     </>
    //   ),
    //   // children: <CardInfo />,
    // },
  ];
  return (
    <>
      <div className='custom-tabs'>
        <Tabs
          defaultActiveKey='1'
          items={items}
          onChange={onChange}
          activeKey={key}
        />
      </div>
    </>
  );
};

export default TransationLimits;
