import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Select, DatePicker } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import toast from '../../../services/toast.service';
import { CONSTANTS } from '../../../constants';
import { formatTxnDate } from '../../../utils/common.utils';
import dayjs from 'dayjs';
import ButtonCommon from '../../../Components/Buttoncommon/ButtonCommon';
import { useDispatch } from 'react-redux';
import { SettingActions } from '../../../redux/features/setting/settingActions';
import {
  finishLoading,
  startLoading,
} from '../../../redux/features/loading/loadingSlice';
import InputCustom from '../../../Components/InputCustom/InputCustom';

const AllTransactionFilters = ({
  setPage,
  setFilterData,
  pageSize,
  getAllTransactions,
  clientId,
  data,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm({
    mode: 'all',
  });

  const location = useLocation();
  const dispatch = useDispatch();
  const obj = { ...location.state };
  const [trans, setTrans] = useState('all');
  const [currencyArr, setCurrencyArr] = useState([]);
  /** setting default filters coing from props */
  const [filter, setFilter] = useState({
    coin: obj.coin,
    page: obj.page,
    fromDate: obj.fromDate,
    toDate: obj.toDate,
    txStatus: obj.txStatus,
    type: obj.type,
  });
  const [subTypes, setSubTypes] = useState([]);

  const onSubmit = (values) => {
    // receive,send,internal-deposit,internal-withdraw
    if (values.trxnType === 'FIAT') {
      delete values.coin;
    }
    let isFiltersValid = validateFilters(values);
    if (!isFiltersValid) return;
    values = convertFilterValues(values);
    if (Object.keys(values).length > 0) {
      console.log({ filterValues: values });
      setFilterData(values);
      getAllTransactions(values);
      setPage(1);
    }
  };

  const convertFilterValues = (data) => {
    let values = { ...data };

    if (values.fromDate && !values.toDate)
      values.toDate = formatTxnDate(new Date().toDateString());
    if (values.toDate && !values.fromDate)
      return toast.error('Please select from date');
    values.page = 1;
    values.size = pageSize;
    values.txStatus = values.status;
    values.coin = values.coin;
    values.searchBy = values.searchBy;
    values.clientId = location.pathname !== '/all-transaction' ? clientId : '';

    if (values.fromDate) values.fromDate = formatTxnDate(values.fromDate);
    if (values.toDate) {
      values.toDate = formatTxnDate(values.toDate);
      setValue('toDate', dayjs(values.toDate));
    }
    if (typeof values.status === 'undefined') values.txStatus = 'ALL';
    if (typeof values.type === 'undefined') values.type = 'all';
    if (typeof values.trxnType === 'undefined') values.trxnType = 'ALL';
    if (typeof values.coin === 'undefined') values.coin = '';

    return values;
  };

  const checkEmptyFilters = (data) => {
    let filterMessage = '';
    let isFiltersValid = true;
    isFiltersValid = Object.values(data).some((value) => !!value);
    if (!isFiltersValid) {
      if (data && data.length === 0) {
        isFiltersValid = true;
      } else {
        filterMessage = 'Please select filters first';
        toast.error(filterMessage);
      }
    }

    return isFiltersValid;
  };

  const validateFilters = (data) => {
    const { VALIDATION_ERRORS } = CONSTANTS;
    let isFiltersValid = checkEmptyFilters(data);
    if (data.fromDate && data.toDate) {
      if (
        new Date(data.fromDate).getDate() > new Date(data.toDate).getDate() &&
        new Date(data.fromDate).getMonth() > new Date(data.toDate).getMonth()
      ) {
        isFiltersValid = false;
        toast.error(VALIDATION_ERRORS.START_DATE_GREATER_THAN_END_DATE);
      }
    }

    return isFiltersValid;
  };
  console.log('FIAT:::', getValues('trxnType'));
  const clearFilters = () => {
    let isFiltersValid = checkEmptyFilters(getValues());
    console.log({ filterState: filter });
    if (isFiltersValid) {
      reset();
      setValue('searchBy', '');
      const data = {
        clientId: '',
        coin: '',
        page: 1,
        size: pageSize,
        fromDate: '',
        toDate: '',
        txStatus: 'ALL',
        type: 'all',
        trxnType: 'ALL',
      };
      setPage(1);
      getAllTransactions(data);
      let newData = { ...data };
      delete newData.clientId;

      setFilterData(newData);
    }
  };

  const setRangePickerValue = () => {
    return {
      fromDate: dayjs(filter.fromDate),
      toDate: dayjs(filter.toDate),
    };
  };

  const handleTypeChange = (e) => {
    console.log({ tnxType: e });
    setValue('trxnType', e);
    setValue('type', 'all');
    setValue('coin', []);
    let types = getSubTypesBasedOnType(e);
    console.log({ types });
    setSubTypes(types);
  };

  const getSubTypesBasedOnType = (type) => {
    if (type) {
      if (type === 'CRYPTO') {
        return [
          {
            value: 'all',
            label: 'ALL',
          },
          {
            value: 'internal-deposit',
            label: 'INTERNAL-IN',
          },
          {
            value: 'receive',
            label: 'CREDIT',
          },
          {
            value: 'internal-withdraw',
            label: 'INTERNAL-OUT',
          },
          {
            value: 'send',
            label: 'DEBIT',
          },

          {
            value: 'swap',
            label: 'SWAP',
          },
        ];
      }
      if (type === 'FIAT') {
        return [
          {
            value: 'all',
            label: 'ALL',
          },
          {
            value: 'credit',
            label: 'CREDIT',
          },
          {
            value: 'debit',
            label: 'DEBIT',
          },
          {
            value: 'internal-transfer',
            label: 'INTERNAL-TRANSFER',
          },

          { value: 'fiat-swap', label: 'SWAP' },
        ];
      }
    }
    return [];
  };

  const getWithdrawFee = async () => {
    try {
      await dispatch(startLoading());

      const res = await dispatch(
        SettingActions.getWithdrawFee({ apiAccessKey: 'ALL_TRANSACTIONS' })
      ).unwrap();
      if (res) {
        const result = res.map((item) => {
          console.log('item::', item);
          return {
            label: item.coin.toUpperCase(),
            value: item.coin.toUpperCase(),
          };
        });
        setCurrencyArr(result);
      } else {
        setCurrencyArr([]);
      }
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  useEffect(() => {
    console.log('filters:- ', filter);
    // setValue('coin', filter.coin);
    if (filter.fromDate) {
      setValue('fromDate', setRangePickerValue().fromDate);
    }
    if (filter.toDate) {
      setValue('toDate', setRangePickerValue().toDate);
    }
    // setValue('type', filter.type);
    setValue('status', filter.txStatus);
    // setValue()
  }, [filter]);

  useEffect(() => {
    getWithdrawFee();
  }, []);

  return (
    <form
      style={{ display: 'inline-flex', gap: '15px', flexFlow: 'wrap' }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      {/* <Controller
        name="search"
        control={control}
        render={({ field }) => (
          <Input
            style={{ width: "230px" }}
            prefix={<SearchOutlined />}
            type="search"
            {...field}
            placeholder="Search by: TXN ID, Address"
          />
        )}
      /> */}

      <Controller
        name='fromDate'
        control={control}
        render={({ field }) => (
          <DatePicker {...field} placeholder='From date' />
        )}
      />

      <Controller
        name='toDate'
        control={control}
        render={({ field }) => <DatePicker {...field} placeholder='To date' />}
      />

      <div style={{ minWidth: '320px' }}>
        <Controller
          name='searchBy'
          control={control}
          render={({ field }) => (
            <>
              <InputCustom
                type='text'
                settingInput='settingInput'
                custommClass1='custommClass1'
                placeholder={' Client Id / Txn Id'}
                borderRadius={10}
                height={48}
                {...field}
              />
            </>
          )}
        />
      </div>

      <Controller
        name='trxnType'
        control={control}
        render={({ field }) => (
          <Select
            placeholder='Type'
            {...field}
            onChange={handleTypeChange}
            style={{
              minWidth: '150px',
            }}
            options={[
              {
                value: 'ALL',
                label: 'All',
              },
              {
                value: 'FIAT',
                label: 'Fiat',
              },
              {
                value: 'CRYPTO',
                label: 'Crypto',
              },
            ]}
          />
        )}
      />

      <Controller
        name='type'
        control={control}
        disabled={subTypes.length === 0}
        render={({ field }) => (
          <Select
            placeholder='Sub types'
            {...field}
            style={{
              minWidth: '150px',
            }}
            options={subTypes}
          />
        )}
      />
      <Controller
        name='coin'
        control={control}
        render={({ field }) => (
          <Select
            placeholder='Currencies'
            {...field}
            style={{
              minWidth: '150px',
            }}
            disabled={getValues('trxnType') === 'FIAT'}
            options={[
              // {
              //   value: 'ALL',
              //   label: 'All',
              // },
              {
                value: 'EUR',
                label: 'EUR',
              },
              ...currencyArr,
            ]}
          />
        )}
      />

      <Controller
        name='status'
        control={control}
        render={({ field }) => (
          <Select
            placeholder='Status'
            {...field}
            style={{
              minWidth: '150px',
            }}
            options={[
              // { value: "", label: "", disabled: true },
              {
                value: 'ALL',
                label: 'ALL',
              },
              {
                value: 'PENDING',
                label: 'PENDING',
              },
              {
                value: 'INPROGRESS',
                label: 'INPROGRESS',
              },
              {
                value: 'COMPLETED',
                label: 'COMPLETED',
              },
              {
                value: 'CANCELLED',
                label: 'CANCELLED',
              },

              {
                value: 'FAILED',
                label: 'FAILED',
              },
            ]}
          />
        )}
      />

      {/* <Button
        className="filter-btn"
        // onClick={toggleFilterVisibility}
        type={"default"}
        // onClick={clearFilters}
        htmlType="submit"
      >
        Submit
      </Button> */}

      <ButtonCommon
        text='Submit'
        type='primary'
        transBtn='transBtn'
        htmlType={'submit'}
      />

      <ButtonCommon
        className='filter-btn'
        // onClick={toggleFilterVisibility}
        type={'default'}
        reject={'reject'}
        onClick={clearFilters}
        htmlType='button'
      >
        <CloseOutlined />
      </ButtonCommon>
    </form>
  );
};

export default AllTransactionFilters;
