import { Badge, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
  finishLoading,
  startLoading,
} from "../../../redux/features/loading/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../../../redux/features/user/userActions";
import CopyToClipboard from "../../../Components/CopyToClipboard/CopyToClipboard";
import {
  checkEmptyString,
  formatDateTime,
  handleHideId,
  renderConditionalElement,
} from "../../../utils/common.utils";
import TableFilterTittle from "../../../Components/TableFilterTittle/TableFilterTittle";
import TextStatusBadge from "../../../Components/TextStatusBadge/TextStatusBadge";

function Beneficary() {
  const selectedUserData = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const { Text } = Typography;

  const [getBeneficiaryData, setGetBeneficiaryData] = useState([]);

  const column = [
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
      render: (_, record) => {
        return (
          <>
            {record?.userId ? (
              <>
                {handleHideId(record?.userId)}
                <CopyToClipboard userId={record?.userId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "BeneficiaryId",
      dataIndex: "benefId",
      key: "benefId",
      render: (_, record) => {
        return (
          <>
            {record?.benefId ? (
              <>
                {handleHideId(record?.benefId)}
                <CopyToClipboard userId={record?.benefId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "BeneficiaryType",
      dataIndex: "beneficiaryType",
      key: "beneficiaryType",
      render: (_, record) => checkEmptyString(record.beneficiaryType),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => checkEmptyString(record.name),
    },
    {
      title: "Account Number",
      dataIndex: " account Number",
      key: " account Number",
      render: (_, record) => checkEmptyString(record.accountNumber),
    },
    {
      title: "Bank Name",
      dataIndex: " bank name",
      key: " bank name",
      render: (_, record) => checkEmptyString(record.bankName),
    },
    {
      title: "Address",
      dataIndex: " address",
      key: " address",
      render: (_, record) => checkEmptyString(record.address),
    },
    {
      title: "City",
      dataIndex: " City",
      key: " City",
      render: (_, record) => checkEmptyString(record.city),
    },
    {
      title: "Country",
      dataIndex: " country",
      key: " country",
      render: (_, record) => checkEmptyString(record.country),
    },
    {
      title: "Zipcode",
      dataIndex: " zipCode",
      key: " zipCode",
      render: (_, record) => checkEmptyString(record.zipCode),
    },
    {
      title: "Status",
      dataIndex: " status",
      key: " status",
      render: (_, record) => (
        <TextStatusBadge
          condition={record.status === true}
          trueText={"ACTIVE"}
          falseText={"INACTIVE"}
        />
      ),
    },

    {
      title: "UpdatedAt",
      dataIndex: " updatedAt",
      key: " updatedAt",
      render: (_, record) => formatDateTime(record.updatedAt),
    },
  ];

  const getBeneficiartDetals = async (userId) => {
    try {
      const data = {
        userId,
      };
      dispatch(startLoading());
      const response = await dispatch(
        UserActions.getBankAccount(data)
      ).unwrap();
      if (response.accounts?.length > 0) {
        setGetBeneficiaryData(response.beneficiaries);
      } else {
        setGetBeneficiaryData([]);
      }
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  const getTableMaxHeight = (data) => {
    if (data) {
      if (data.length <= 1) {
        return "200px";
      }
      return "auto";
    }
  };
  useEffect(() => {
    const userId = selectedUserData?.user?.userId;
    getBeneficiartDetals(userId);
  }, []);
  console.log(getBeneficiaryData, "getBeneficiaryData");
  const checkDataSource = () => {};
  return (
    <div className="table-design" style={{ marginTop: "10px" }}>
      <TableFilterTittle title="Beneficiaries" />

      <div className="custom-table">
        <Table
          dataSource={getBeneficiaryData}
          columns={column}
          pagination={false}
          style={{ height: getTableMaxHeight(getBeneficiaryData) }}
        />
      </div>
    </div>
  );
}

export default Beneficary;
