import React from 'react';
import InfoCard from '../../Components/Card/InfoCard';
import style from "./style.module.scss";
import { Typography } from 'antd';

const {Text, Title} = Typography;

const KycVerificationDetailsSection = ({listkyc}) => {
    return (
        <div className={style.detailmodalContent_kyc}>
            <Title level={5}>KYC Verification Details</Title>
                {listkyc.map((point) => 
                    <InfoCard key={point.id} title={point.title} titleSub={point.titleSub} detail={point.detail} />
                )}
        </div>)
}

export default KycVerificationDetailsSection;