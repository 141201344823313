import React, { useEffect, useState } from "react";
import CustomInput from "../../Components/CustomInput/CustomInput";
import { Form, Select } from "antd";
import CustomSelect from "../../Components/CustomSelect/CustomSelect";
import ButtonCommon from "../../Components/Buttoncommon/ButtonCommon";
import { useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";
import { FormError } from "../../Components/FormErrors/FormError.jsx";
import { useDispatch } from "react-redux";
import {
  finishLoading,
  startLoading,
} from "../../redux/features/loading/loadingSlice";
import { AdminActions } from "../../redux/features/admin/adminActions";

const ModalContentMangement = ({ form }) => {
  const navigate = useNavigate();
  const [roles, setRoles] = useState({
    list: [],
  });
  const [selectedRole, setSelectedRole] = useState();

  const {
    formState: { errors },
  } = form;

  const dispatch = useDispatch();

  const handleOk = () => {
    navigate("/role-management/set-permission");
  };

  const getRoles = async (data) => {
    try {
      dispatch(startLoading());
      const res = await dispatch(AdminActions.getRoles(data)).unwrap();
      console.log(res);
      if (res && res.list && res.list.length) {
        setRoles({
          list: [
            ...res.list,
          
          ],
        });
      } else {
        setRoles({
          list: [],
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    const data = {
      limit: 10,
      page: 1,
    };
    getRoles(data);
  }, []);

  const formatRoles = (data) => {
    if (data && data.list?.length > 0) {
      return data.list.map((obj) => {
        return {
          value: JSON.stringify(obj),
          label: obj.name,
        };
      });
    }
    return [];
  };

  const handleChange = (e) => {
    console.log(e);
    form.setValue("role", e);
    let roleData = JSON.parse(e);

    setSelectedRole(roleData.role);
  };

  // useEffect(() => {
  //   console.log({ selectedRole });
  // }, [selectedRole]);
  console.log("form::",form.getValues('email'));

  return (
    <>
      <Form layout="vertical">
        <Controller
          name="firstName"
          control={form.control}
          render={({ field }) => (
            <CustomInput
              label="First Name"
              placeholder="First Name"
              {...field}
            />
          )}
        />

        <FormError message={errors?.firstName?.message} />

        <Controller
          name="lastName"
          control={form.control}
          render={({ field }) => (
            <CustomInput label="Last Name" placeholder="Last Name" {...field} />
          )}
        />

        <FormError message={errors?.lastName?.message} />
        <Controller
          name="email"
          control={form.control}
          render={({ field }) => (
            <CustomInput
              label="Email"
              placeholder="example@gmail.com"
              {...field}
            />
          )}
        />
        <FormError message={errors?.email?.message} />

        <Controller
          name="role"
          control={form.control}
          render={({ field }) => (
            <Select
              defaultValue=""
              placeholder="Admin"
              className="width100"

              {...field}
              onChange={handleChange}
              options={formatRoles(roles)}
            />
          )}
        />
        <FormError message={errors?.role?.message} />

        {selectedRole && selectedRole === "OTHER" && (
          <>
            <Controller
              name="roleName"
              control={form.control}
              render={({ field }) => (
                <CustomInput
                  label="Role Name"
                  placeholder="Support Executive"
                  {...field}
                />
              )}
            />
            <FormError message={errors?.roleName?.message} />
          </>
        )}
      </Form>
    </>
  );
};

export default ModalContentMangement;
