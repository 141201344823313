import { createSlice } from "@reduxjs/toolkit";
import { AdminActions } from "./adminActions";

const initialState = {
  data: null,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setNameOnProfileUpdate: (state, action) => {
      state.data.firstName = action.payload.firstName;
      state.data.lastName = action.payload.lastName;
    },

    logout: (state, action) => {
      console.log(action);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(AdminActions.getDetails.fulfilled, (state, action) => {
        let data = action.payload;

        const {
          email,
          firstName,
          lastName,
          adminRole,
          status,
          sessions,
          title,
          lastLoginTime,
          is2FaEnable,
          role
        } = data;
        console.log({data,adminRole});
        const {rolePermissions} = role
        const {name} = role
        let newState = { ...state };
        newState.data = {
          email,
          firstName,
          lastName,
          adminRole,
          status,
          sessions,
          lastLoginTime,
          title,
          is2FaEnable,
          permissions:rolePermissions,
          roleTitle:name
        };
        return newState;
      })
      .addCase(AdminActions.getDetails.rejected, (state, action) => {
        state.data = null;
      });
  },
});

export const { setNameOnProfileUpdate, logout } = adminSlice.actions;

export default adminSlice.reducer;
