import React, { useEffect, useState } from 'react';
import { MessageIcon, NotificationIcon } from '../../Assets/Icons/Icons';
import { Card, Pagination, Table, Typography } from 'antd';
import style from './style.module.scss';
import style1 from '../headingview/style.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import {
  setSelectedSanctionTab,
  setSelectedSanctionType,
} from '../../redux/features/compliance/complianceSlice';
import { useDispatch } from 'react-redux';
import {
  formatDateTime,
  handleHideId,
  iconColor,
  iconSize,
} from '../../utils/common.utils';
import ButtonCommon from '../Buttoncommon/ButtonCommon';
import { UserActions } from '../../redux/features/user/userActions';
import { setSelectedApiData } from '../../redux/features/user/userSlice';
import { EyeOutlined } from '@ant-design/icons';
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard';
import { toast } from 'react-toastify';

const { Title } = Typography;
function RecentActivity({
  setShowVerificationScreen,
  data,
  type,
  getComplianceAnalytics,
  setIsMngView,
  setSelectedData,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const columns = [
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      render: (_, obj) => {
        return obj.userId ? (
          <>
            <span style={{cursor:"pointer"}} onClick={() => getUserDetailsInfo(obj.userId)}>
              {handleHideId(obj.userId)}
            </span>
            <CopyToClipboard userId={obj.userId} position='top' />
          </>
        ) : (
          '-'
        );
      },
    },
    {
      title: 'Applicant Id ',
      dataIndex: 'applicantId',
      key: 'applicantId',
      render: (_, obj) => {
        return obj.applicantId ? (
          <>
            {handleHideId(obj.applicantId)}
            <CopyToClipboard userId={obj.applicantId} position='top' />
          </>
        ) : (
          '-'
        );
      },
    },

    {
      title: 'Alert',
      dataIndex: 'alert',
      key: 'alert',
      render: (_, obj) => {
        if (obj.alert && obj.alert.length > 0) {
          return (
            <div
              style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'normal',
                maxWidth: '900px',
                minWidth: '200px',
              }}
            >
              {obj.alert.join('.')}
            </div>
          );
        }
        return '-';
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, obj) => {
        return formatDateTime(obj.createdAt);
      },
    },
    {
      title: 'Action',

      render: (_, obj) => (
        <>
          {/* <ButtonCommon
            text="More Details"
            height={46}
            type={"primary"}
            htmlType={"button"}
            onClick={() => goToUserKyc(obj)}
          /> */}
          <EyeOutlined
            style={{ color: iconColor, fontSize: iconSize }}
            onClick={() => goToUserKyc(obj)}
          />
        </>
      ),
    },
  ];

  const goToVerificationScreen = () => {
    // setShowVerificationScreen(true);
    navigate('/sanction-report-verifications-screening');
    // dispatch(setSelectedSanctionTab("Rejected"));
    dispatch(setSelectedSanctionType(type));
  };

  const handlePageChange = async (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    const data = {
      userType: type,
      limit: pageSize,
      offset: (page - 1) * pageSize,
    };
    getComplianceAnalytics(data);
  };

  const goToUserKyc = (obj) => {
    getUserDetails(obj.userId);
  };

  const getUserDetails = async (id) => {
    try {
      const data = await dispatch(
        UserActions.getUserDetail({ userId: id })
      ).unwrap();

      navigate('/sanction-report-kyc', {
        state: { data },
      });

      dispatch(setSelectedApiData(data));
    } catch (error) {}
  };

  const getUserDetailsInfo = async (id) => {
    try {
      if (!id) return;
      const data = await dispatch(
        UserActions.getUserDetail({ userId: id })
      ).unwrap();
      if (data && data?.user && data?.kyc) {
        dispatch(setSelectedApiData(data));
        console.log({ viewData: data });
        navigate('/user-directory-view');
      } else {
        toast.error('No data found');
      }
    } catch (error) {}
  };

  return (
    <>
      <div className={`${style.recentactivity} RecentAlerts `}>
        <Card
          title='Recent Alerts'
          extra={
            <div className={`${style1.headview} recentalerts`}>
              <div onClick={goToVerificationScreen}>View All</div>
            </div>
          }
        >
          <div className='custom-table'>
            <Table
              dataSource={data.rows}
              columns={columns}
              pagination={false}
              //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
            />
          </div>

          <div className='pagination_block'>
            <span className='records'>
              Total Records: {data?.count > 0 ? data?.count : 0}
            </span>
            {data?.count > 10 && (
              <Pagination
                defaultCurrent={page}
                total={data?.count}
                pageSize={pageSize}
                onChange={handlePageChange}
                current={page}
              />
            )}
          </div>
        </Card>
      </div>
    </>
  );
}

export default RecentActivity;
