import React, { useState } from 'react';
import { Table, Space, Pagination, Button } from 'antd';
import TableFilterTittle from '../../../../Components/TableFilterTittle/TableFilterTittle';
import {
  handleHideId,
  iconColor,
  iconSize,
} from '../../../../utils/common.utils';
import CopyToClipboard from '../../../../Components/CopyToClipboard/CopyToClipboard';
import { useNavigate } from 'react-router-dom';
// import CompleteModalContent from "./CompleteModalContent";
import { EyeOutlined } from '@ant-design/icons';
import VerificationScreeningFilter from '../Filter/VerificationScreeningFilter';

const Rejected = ({
  complianceKyc,
  showVerificationDetails,
  getComplianceKyc,
  countryDropDownobj,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState({});

  console.log("filterData::",filterData);
  const columns = [
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      render: (_, record) => {
        return (
          <>
            {record?.userId ? (
              <>
                <span>{handleHideId(record?.userId)}</span>

                <CopyToClipboard userId={record?.userId} position='top' />
              </>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Email ID',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Nationality',
      dataIndex: 'nationality',
      key: 'nationality',
    },
    {
      title: 'Kyc Status(ADMIN)',
      dataIndex: 'adminKycStatus',
      key: 'adminKycStatus',
    },
    {
      title: 'Kyc Status(SUMSUB)',
      dataIndex: 'sumsubKycStatus',
      key: 'sumsubKycStatus',
    },
    {
      title: 'Account Type',
      dataIndex: 'accountType',
      key: 'accountType',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div className='approvereject cursorpointer'>
          {/* <span
            onClick={() => {
              {
                showVerificationDetails(record.userId);
              }
            }}
          >
            <Button type="primary">More Details</Button>
          </span> */}
          <EyeOutlined
            style={{ color: iconColor, fontSize: iconSize }}
            onClick={() => showVerificationDetails(record.userId)}
          />
        </div>
      ),
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    let data = {
      offset: (page - 1) * pageSize,
      limit: pageSize,
      type: 'REJECTED',
      searchBy:filterData.searchBy,
      startDate:filterData.startDate,
      endDate:filterData.endDate,
      nationality:filterData.nationality,
      accountType:filterData.accountType,
      kycStatus:filterData.kycStatus,
    };
    getComplianceKyc(data);
  };

  return (
    <>
      <div className='table-design'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TableFilterTittle title='Rejected Requests' />
          <div className='title-filter flexEnd'>
            <div className='title-filter_rightSec'>
              <VerificationScreeningFilter
                setFilterData={setFilterData}
                pageSize={pageSize}
                getComplianceKyc={getComplianceKyc}
                setPage={setPage}
                countryDropDownobj={countryDropDownobj}
              />
            </div>
          </div>
        </div>
        <div className='custom-table'>
          <Table
            dataSource={complianceKyc.list}
            columns={columns}
            pagination={false}
            // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>
      {complianceKyc.totalCount > 10 && (
        <div className='pagination_block'>
          <span className='records'>
            Total Records: {complianceKyc.totalCount}
          </span>
          <Pagination
            defaultCurrent={page}
            total={complianceKyc.totalCount}
            pageSize={pageSize}
            onChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default Rejected;
