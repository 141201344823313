import React from 'react';
import InfoCard from '../../Components/Card/InfoCard';
import style from "./style.module.scss";
import { Typography } from "antd";
import { Col } from 'antd';

const { Text, Title } = Typography;

const PersonalInfoSection = ({ list, title }) => {

    return (
        <Col xs={24} lg={12}>
            <div className={style.detailmodalContent}>
                <Title level={5}>{title}</Title>
                {list.map((point) => (
                    <InfoCard key={point.id} title={point.title} titleSub={point.titleSub} detail={point.detail} />
                ))}
            </div>
        </Col>)
}

export default PersonalInfoSection;