import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Badge, Input, Tag, Divider } from "antd";
import TransationTags from "./TransationTags";
// import TodoList from "./TodoList";
import ButtonCommon from "../../Components/Buttoncommon/ButtonCommon";
import style from "./style.module.scss";
import { useLocation } from "react-router-dom";
import {
  checkEmptyString,
  formatDateTime,
  handleHideId,
} from "../../utils/common.utils";
import CopyToClipboard from "../../Components/CopyToClipboard/CopyToClipboard";

const { Title } = Typography;

const TextList = ({ type, Detail }) => (
  <p className={style.list}>
    <span>{type}:</span>
    <span>{Detail}</span>
  </p>
);

const { TextArea } = Input;

const TransationInfo = ({data}) => {
  const [value, setValue] = useState("");
  const [show, setShow] = useState(true);
  const location = useLocation();
 
  const Info = [
    {
      id: 1,
      type: "Type",
      Detail: checkEmptyString(data?.data?.applicant?.type),
    },
    {
      id: 2,
      type: "External user ID",
      // Detail: checkEmptyString(data?.data?.applicant?.externalUserId),

      Detail: (
        <>
          {data?.data?.applicant?.externalUserId ? (
            <>
              {handleHideId(data?.data?.applicant?.externalUserId)}
              <CopyToClipboard
                userId={data?.data?.applicant?.externalUserId}
                position="top"
              />
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    { id: 3, type: "Address Country", Detail: "__" },
    { id: 4, type: "Formatted Address", Detail: "__" },
  ];

  const countryPart = [
    {
      id: 1,
      type: "Type",
      Detail: checkEmptyString(data?.data?.applicant?.type),
    },
    {
      id: 2,
      type: "External user ID",
      Detail: (
        <>
          {data?.data?.counterparty?.externalUserId ? (
            <>
              {handleHideId(data?.data?.counterparty?.externalUserId)}
              <CopyToClipboard
                userId={data?.data?.counterparty?.externalUserId}
                position="top"
              />
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    { id: 3, type: "Address Country", Detail: "__" },
    { id: 4, type: "Formatted Address", Detail: "__" },
  ];
  const Institution = [
    { id: 1, type: "Institution Code", Detail: "__" },
    { id: 2, type: "Institution Name", Detail: "__" },
    { id: 3, type: "Institution Country", Detail: "__" },
  ];
  const Payment = [
    { id: 1, type: "Payment Issuing Country", Detail: "__" },
    { id: 2, type: "Wallet Address", Detail: "__" },
    { id: 3, type: "Payment type", Detail: checkEmptyString(data?.data?.type) },
  ];
  console.log(data, "location");
  const Details = [
    { id: 1, type: "created at", Detail: formatDateTime(data?.createdAt) },
    {
      id: 2,
      type: "Transaction Date",
      Detail: formatDateTime(data?.data?.txnDate),
    },
    { id: 3, type: "BlockChain TXID", Detail: "__" },
    {
      id: 4,
      type: "Amount",
      Detail: ` ${
        data?.data?.info?.amount ? data?.data?.info?.amount : "-"
      } ${checkEmptyString(data?.data?.info?.currencyCode?.toUpperCase())} `,
    },
    {
      id: 5,
      type: "Direction",
      Detail: checkEmptyString(data?.data?.info?.direction),
    },
    { id: 6, type: "Transaction category", Detail: checkEmptyString(data?.data?.info.type)},
    { id: 6, type: "Payment Details", Detail: "__" },

    { id: 7, type: "Source key", Detail: "__" },
    {
      id: 8,
      type: "Tags",
      Detail: (
        <>
          <TransationTags />
        </>
      ),
    },
  ];
  console.log(data, "Transaction category");
  const InfoTexts = Info.map((info) => <TextList key={info.id} {...info} />);
  const InstitutionTexts = Institution.map((info) => (
    <TextList key={info.id} {...info} />
  ));
  const countryPartTexts = countryPart.map((info) => (
    <TextList key={info.id} {...info} />
  ));
  const InstitutionText = Institution.map((info) => (
    <TextList key={info.id} {...info} />
  ));

  const PaymentTexts = Payment.map((info) => (
    <TextList key={info.id} {...info} />
  ));
  const countryPaymentTexts = Payment.map((info) => (
    <TextList key={info.id} {...info} />
  ));
  const TransationDetail = Details.map((info) => (
    <TextList key={info.id} {...info} />
  ));
  var count = 0;
  return (
    <div className={style.spaceMange}>
      <Row gutter={[50, 30]}>
        <Col xs={24} lg={16}>
          <Title level={3}>Remitter & Beneficiary Info</Title>
          <div className={style.spaceMange_textData} style={{ width: "100%" }}>
            <div className={style.spaceMange_textData_Col}>
              <Title level={4}>
                <span>Applicant</span>
                <br />
                {checkEmptyString(data?.data?.applicant?.fullName)}
                {/* <Tag style={{ marginLeft: "5px" }} color="green">
                  <Badge color="green" status="success" text="Approved" />
                </Tag> */}
              </Title>
              <div className={style.spaceMange_textData_Col_Content}>
                <div> {InfoTexts}</div>
                <strong>Institution</strong>
                <div> {InstitutionTexts}</div>
                <strong>Payment Method</strong>
                <div> {PaymentTexts}</div>
              </div>
            </div>
            <div className={style.spaceMange_textData_Col}>
              <Title level={4}>
                <span>CounterParty</span>
                <br />
                <>
                  {data?.data?.counterparty?.externalUserId ? (
                    <>
                      {handleHideId(data?.data?.counterparty?.externalUserId)}
                      <CopyToClipboard
                        userId={data?.data?.counterparty?.externalUserId}
                        position="top"
                      />
                    </>
                  ) : (
                    "-"
                  )}
                </>
              </Title>
              <div className={style.spaceMange_textData_Col_Content}>
                <div> {countryPartTexts}</div>
                <strong>Institution</strong>
                <div>{InstitutionText}</div>
                <strong>Payment Method</strong>
                <div> {countryPaymentTexts}</div>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={8}>
          <Title level={3}>Transaction Details</Title>
          <section className={style.spaceMange_textData_Col}>
            <div className={style.spaceMange_textData_Col_Content}>
              {TransationDetail}
            </div>
            <div className={style.notes}>
            </div>
            <Divider />
          </section>
        </Col>
      </Row>
    </div>
  );
};

export default TransationInfo;
