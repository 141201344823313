import React, { useEffect, useState } from "react";
import { MessageIcon, NotificationIcon } from "../../Assets/Icons/Icons";
import { Card, Pagination, Table, Typography } from "antd";
import style from "./style.module.scss";
import style1 from "../headingview/style.module.scss";
import { Link, useLocation } from "react-router-dom";
import { setSelectedSanctionTab } from "../../redux/features/compliance/complianceSlice";
import { useDispatch } from "react-redux";
import {
  checkEmptyString,
  formatDateTime,
  handleHideId,
} from "../../utils/common.utils";
import {
  finishLoading,
  startLoading,
} from "../../redux/features/loading/loadingSlice";
import { ComplianceActions } from "../../redux/features/compliance/complianceAtion";

const { Title } = Typography;
function RecentActivityFull({ type, data, getComplianceAnalytics }) {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const columns = [
    // {
    //   title: "Id",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Applicant Id",
      dataIndex: "applicantId",
      key: "applicantId",
      render: (_, obj) => {
        return handleHideId(obj.applicantId);
      },
    },
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
      render: (_, obj) => {
        return handleHideId(obj.userId);
      },
    },
    {
      title: "Alert",
      dataIndex: "alert",
      key: "alert",
      render: (_, obj) => {
        if (obj.alert && obj.alert.length > 0) {
          return obj.alert.join(".");
        }
        return "-";
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, obj) => {
        return formatDateTime(obj.createdAt);
      },
    },
  ];

  const handlePageChange = async (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    getComplianceAnalytics({ type });
  };

  return (
    <>
      <div className={`${style.recentactivity} RecentAlerts `}>
        <Card title="Recent Alerts">
          <div className="custom-table">
            <Table
              dataSource={data.rows}
              columns={columns}
              pagination={false}
              //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
            />
          </div>

          <div className="pagination_block">
            <span className="records">
              Total Records: {data.count > 0 ? data.count : 0}
            </span>
            {data.count > 10 && (
              <Pagination
                defaultCurrent={page}
                total={data.count}
                pageSize={pageSize}
                onChange={handlePageChange}
                current={page}
              />
            )}
          </div>
        </Card>
      </div>
    </>
  );
}

export default RecentActivityFull;
