export const handleErrors = (error) => {
  let message = '';
  let errorData = error?.response?.data?.response?.data;
  if (errorData && errorData.length > 0) {
    message = errorData[0].message;
  }
  return message;
};

export const formatTxnType = (type) => {
  switch (type) {
    case 0:
      return 'RECEIVE';
    case 1:
    case 18:
      return 'SEND';
    case 3:
    case 4:
      return 'BUY';
    case 5:
    case 6:
      return 'SELL';
    case 7:
    case 8:
      return 'SWAP';
    case 9:
    case 25:
      return 'INTERNAL-DEPOSIT';
    case 10:
    case 26:
      return 'INTERNAL-WITHDRAW';
    case 11:
      return 'DEPOSIT';
    case 12:
      return 'CREDIT';
    case 13:
      return 'DEBIT';
    case 14:
      return 'INTERNAL-CREDIT';
    case 15:
      return 'INTERNAL-DEBIT';
    case 16:
    case 23:
      return 'SWAP-IN';
    case 17:
    case 24:
      return 'SWAP-OUT';
    default:
      return '';
  }
};

export const formatCryptoTypeParam = (typeValue) => {
  switch (typeValue) {
    case 'CRYPTO_DEPOSIT':
      return 0;
    case 'WITHDRAW':
      return 10;
    default:
      return 0;
  }
};

const getCryptoUserId = (txnType) => {
  switch (txnType) {
  }
};

const getFiatUserId = (txnType, record) => {
  switch (txnType) {
    case 'CREDIT':
    case 'INTERNAL-CREDIT':
      return record.toUserId || '-';

    case 'DEBIT':
    case 'INTERNAL-DEBIT':
      return record.fromUserId || '-';
    default:
      return '';
  }
};

export const getUserIdFromTransaction = (record, type, txnType) => {
  let textTxnType = formatTxnType(txnType);
  if (type === 'CRYPTO') {
  } else if (type === 'FIAT') {
    return getFiatUserId(textTxnType, record);
  }
};

export const formatCryptoTypeId = (typeId) => {
  if (typeId) {
    if (typeId.indexOf('_previousTypeId_') !== -1) {
      let typeIdParts = typeId.split('_previousTypeId_');
      let newTypeId = '';
      if (typeIdParts.length > 0) {
        newTypeId = typeIdParts[1];
      }
      return newTypeId;
    } else {
      return typeId;
    }
  }
  return typeId;
};

export const TransactionService = {
  handleErrors,
};
