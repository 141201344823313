import React, { useEffect, useRef } from "react";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import { Col, Row } from "antd";
import { Controller, useForm } from "react-hook-form"; // Import React Hook Form
import InputCustom from "../../../Components/InputCustom/InputCustom";
import CustomInput from "../../../Components/CustomInput/CustomInput";
import ButtonCommon from "../../../Components/Buttoncommon/ButtonCommon";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormError } from "../../../Components/FormErrors/FormError";
import { useDispatch } from "react-redux";
import { MarkettingActions } from "../../../redux/features/marketting/markettingActions";
const schema = yup.object().shape({
  link: yup
    .string()
    // .required("Please enter banner link")
    .url("Please enter valid link")
});

function BannerLink({
  showBannerLinkModal,
  setShowBannerLinkModal,
  bannerId,
  setBanners,
  type,
  banners,
}) {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode:"all"
  });
  const handleOk = () => {};

  const handleClear = () => {
    reset({
      link: "",
    });
  };

  console.log({errors})

  const onSubmit = async (data) => {
    try {
      await dispatch(
        MarkettingActions.updateBanner({
          bannerId: bannerId,
          marketingLink: data?.link,
        })
      ).unwrap();

      const oldBanners = banners.list.slice();
      const targetBannerIndex = oldBanners.findIndex(
        (x) => x.bannerId == bannerId
      );
      oldBanners[targetBannerIndex].marketingLink = data?.link;
      setBanners({
        list: oldBanners,
        totalCounts:banners.totalCounts
      });
      setShowBannerLinkModal(false);
    } catch (error) {}
  };


  const closeModal = () => {
    setShowBannerLinkModal(false);
    
  }

  useEffect(() => {
    const targetBanner = banners.list.find((x) => x.bannerId === bannerId);
    handleClear();
    setValue("link", targetBanner?.marketingLink || "");
  }, [bannerId, showBannerLinkModal]);

  return (
    <div>
      <CustomModal
        title="Banner Link"
        open={showBannerLinkModal}
        onOk={handleOk}
        onCancel={() => closeModal()}
        footer={null}
      >
        <div style={{ position: "relative" }}>
          <span
            style={{
              position: "absolute",
              bottom: "10rem",
              right: "0",
              cursor: "pointer",
              fontSize: "10px",
            }}
            onClick={() => {
              setShowBannerLinkModal(false);
            }}
          >
            &#10006; {/* Close icon (X symbol) */}
          </span>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Row gutter={10}>
                <Col span={24}>
                  <Controller
                    name="link"
                    control={control}
                    render={({ field }) => (
                      <InputCustom
                        placeholder={
                          banners?.marketingLink == null
                            ? "https://"
                            : banners?.marketingLink
                        }
                        {...field}
                      />
                    )}
                  />
                 
                  <FormError message={errors?.link?.message} />
                  
                </Col>
              </Row>
              <Row style={{ marginTop: "15px", justifyContent: "flex-end" }}>
                <Col span={5}>
                  <ButtonCommon
                    text="Submit"
                    type="primary"
                    transBtn="transBtn"
                    htmlType="submit"
                  />
                </Col>
                <Col span={5}>
                  <ButtonCommon
                    text="Reset"
                    type={"primary"}
                    danger
                    customBlueBtn="customBlueBtn"
                    htmlType={"button"}
                    onClick={handleClear}
                  />
                </Col>
              </Row>
            </div>
          </form>
        </div>
      </CustomModal>
    </div>
  );
}

export default BannerLink;
