import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Image, Modal, Space, Tooltip } from 'antd';
import style from './style.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  finishLoading,
  startLoading,
} from '../../redux/features/loading/loadingSlice';
import { ClientSupportActions } from '../../redux/features/clientSupport/clientSupportActions';
import { useDispatch } from 'react-redux';
import {
  checkEmptyString,
  createFullName,
  formatAdminNameWithRole,
  formatDate,
  formatDateTime,
  handleHideId,
} from '../../utils/common.utils';
import { FilePdfOutlined } from '@ant-design/icons';
import { Enviroments } from '../../constants/environment';
import CopyToClipboard from '../../Components/CopyToClipboard/CopyToClipboard';
import { showName } from '../../services/user.service';
import { renderConditionalElement } from '../../utils/common.utils';
import { setSelecteClientSupportTab } from '../../redux/features/clientSupport/clientSupportSlice';
import TextArea from 'antd/es/input/TextArea';
import { toast } from 'react-toastify';
import ButtonCommon from '../../Components/Buttoncommon/ButtonCommon';
import ShowLessElipsis from '../../Components/Common/ShowLessElipsis/ShowLessElipsis.jsx';

const DetailInfo = ({ showClientSupportModal, setShowClientSupportModal }) => {
  const { Text, Title } = Typography;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const resolvedStatus = location?.state?.record?.status === 'RESOLVED';
  const pendingKycData = location.state?.record;
  const ticketId = location.state?.record?.ticketId;
  const [ticketDetails, setTicketDetails] = useState({
    name: '',
    title: '',
    updatedAt: '',
  });

  const [details, setDetails] = useState({
    userList: {},
    kycList: {},
  });

  const [showTooltip, setShowTooltip] = useState({
    description: false,
    comments: false,
  });
  const [approveReason, setApproveReason] = useState('');
  const createTooltip = (name, status) => {
    setShowTooltip({
      [name]: status,
    });
  };
  // getUserDetail
  const getUserDetailsByUserId = async () => {
    try {
      const data = {
        userId: location.state.userId,
      };
      await dispatch(startLoading());
      const res = await dispatch(
        ClientSupportActions.getUserDetail(data)
      ).unwrap();

      if (res) {
        setDetails({
          userList: res?.user,
          kycList: res?.kyc?.individualProfile,
        });
      }
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const getTicketDetails = async () => {
    try {
      const data = {
        ticketId,
      };
      await dispatch(startLoading());

      let res = await dispatch(
        ClientSupportActions.getTicketDetail(data)
      ).unwrap();
      if (res && res.admin) {
        let adminData = res.admin;
        console.log('adminData::', adminData);
        let { firstName, lastName, title, updatedAt } = adminData;
        setTicketDetails({
          name: createFullName(firstName, lastName),
          title,
          updatedAt: res.updatedAt,
        });
      }
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  useEffect(() => {
    getUserDetailsByUserId();
    getTicketDetails();
  }, [location.state?.userId]);

  const handleApproveReason = async () => {
    try {
      if (!approveReason) throw 'Please enter reason';

      handleUpdateSupportTicket();
      // setApproveReason("");
      // setShowClientSupportModal(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUpdateSupportTicket = async () => {
    try {
      let params = {
        ticketId: pendingKycData?.ticketId,
        status:
          pendingKycData?.status == 'PENDING'
            ? 'RESOLVED'
            : pendingKycData?.status == 'RESOLVED' && 'PENDING',
        comment: approveReason,
      };
      await dispatch(startLoading());
      const res = await dispatch(
        ClientSupportActions.updateSupportTicketStatus(params)
      ).unwrap();
      // const data = {
      //   userId: location.state.userId,
      // };
      // getUserDetailsByUserId(data);
      setShowClientSupportModal(false);
      setApproveReason('');
      navigate('/client-support-communication');
      if (location?.state?.record?.status == 'PENDING') {
        dispatch(setSelecteClientSupportTab('Resolved'));
      } else {
        dispatch(setSelecteClientSupportTab('Pending'));
      }
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };
  const handleCancel = () => {
    setShowClientSupportModal(false);
    setApproveReason('');
  };

  const listkyc = [
    {
      id: 1,
      titleSub: 'Name:',
      detail: checkEmptyString(showName(details?.userList)),
    },
    {
      id: 2,
      titleSub: 'Email:',
      detail: checkEmptyString(details?.userList?.email),
    },

    {
      id: 3,
      titleSub: 'Risely ID:',
      detail: (
        <>
          {details.userList.uidString ? (
            <>
              {handleHideId(details.userList?.uidString)}
              <CopyToClipboard
                userId={details.userList.uidString}
                position='top'
              />
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      id: 4,
      titleSub: 'Country:',
      detail: checkEmptyString(details?.userList?.nationality),
    },
    {
      id: 5,
      titleSub: 'Phone Number:',
      detail: checkEmptyString(details?.userList?.phoneNumber),
    },
    {
      id: 6,
      titleSub: 'DOB:',
      detail: checkEmptyString(details?.userList?.dateOfBirth),
    },
    {
      id: 7,
      titleSub: 'Address:',
      detail: checkEmptyString(details?.userList?.address),
    },
    {
      id: 8,
      titleSub: 'Registered On:',
      detail: formatDateTime(details?.userList?.createdAt),
    },
    {
      id: 9,
      titleSub: 'KYC Status:',
      detail: renderConditionalElement(
        !!details?.kycList?.adminKycStatus,
        <strong>
          <Text type='success'>{details?.kycList?.adminKycStatus}</Text>
        </strong>,
        <strong>
          <Text type='danger'>PENDING</Text>
        </strong>
      ),
    },
    {
      id: 8,
      titleSub: 'Account Type:',
      detail: checkEmptyString(details?.userList?.accountType),
    },
  ];

  let list = [
    {
      id: 1,
      title: 'Created on:',
      detail: formatDateTime(location?.state?.record?.createdAt),
    },
    // {
    //   id: 2,
    //   title: "Type:",
    //   detail: checkEmptyString(location?.state?.record?.ticketType),
    // },

    {
      id: 3,
      title: 'Description:',
      detail: (
        <>
          {location?.state?.record?.description ? (
            <div
              onMouseEnter={() => createTooltip('description', true)}
              onMouseLeave={() => createTooltip('description', false)}
            >
              <Tooltip
                title={location?.state?.record?.description}
                open={showTooltip.description}
              />
              <ShowLessElipsis text={location?.state?.record?.description} />
            </div>
          ) : (
            <span>
              {checkEmptyString(location?.state?.record?.description)}
            </span>
          )}
        </>
      ),
    },

    resolvedStatus && {
      id: 4,
      title: 'Resolved On:',
      detail: formatDateTime(ticketDetails.updatedAt),
    },
    resolvedStatus && {
      id: 5,
      title: 'Resolved By:',
      detail: formatAdminNameWithRole(ticketDetails.name, ticketDetails.title),
    },
    {
      id: 6,
      title: 'Resolved Comments:',
      detail: (
        <>
          {location?.state?.record?.comments ? (
            <div
              onMouseEnter={() => createTooltip('comments', true)}
              onMouseLeave={() => createTooltip('comments', false)}
            >
              <Tooltip
                title={location?.state?.record?.comments}
                open={showTooltip.comments}
              />
              <ShowLessElipsis text={location?.state?.record?.comments} />
            </div>
          ) : (
            <span>{checkEmptyString(location?.state?.record?.comments)}</span>
          )}
        </>
      ),
    },
  ];

  console.log(location?.state?.record, 'recordResolved');

  const getDocumentTypeData = (doc) => {
    console.log(doc, 'docdoc');
    let fullDocPath = Enviroments.KYC_DOCS_URL + doc;
    let ext = fullDocPath.substring(fullDocPath.lastIndexOf('.')).toLowerCase();
    let isImage = false;

    console.log(fullDocPath, 'fullDocPath');
    const validImageTypes = [
      '.gif',
      '.jpeg',
      '.png',
      '.jpg',
      '.avif',
      '.svg',
      '.tiff',
      '.tif',
      '.webp',
    ];

    if (validImageTypes.includes(ext)) {
      isImage = true;
    }

    return isImage ? (
      <>
        <Image
          // style={{ width: "100%", height: "auto" }}
          src={`${Enviroments.KYC_DOCS_URL}${doc}`}
        />
      </>
    ) : (
      <>
        <a href={fullDocPath} target='_blank'>
          <FilePdfOutlined style={{ fontSize: '50px' }} />
        </a>
      </>
    );
  };
  console.log(location.state?.record, 'recordrecord');
  return (
    <>
      <div className={style.ticketInfo}>
        <Row gutter={10}>
          <Col xs={24} lg={12}>
            <div className={style.ticketInfo_ticket}>
              <Title level={5}>Ticket Details</Title>
              <ul style={{ padding: 'unset' }}>
                {list.map((point) => (
                  <li key={point.id}>
                    <span>{point.title}</span>
                    <span>{point.detail}</span>
                  </li>
                ))}
              </ul>
              {location.state?.record?.attachment &&
                location.state?.record?.attachment.length > 0 && (
                  <>
                    <Title level={5}>Uploaded Attachment</Title>
                    <div
                      style={{ marginBottom: '20px' }}
                      className='upload-document'
                    >
                      {location.state?.record?.attachment?.map((item, index) =>
                        getDocumentTypeData(item)
                      )}
                    </div>
                  </>
                )}
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className={style.ticketInfo_profile}>
              <Title level={5}>Profile Information</Title>
              <ul style={{ padding: 'unset' }}>
                {listkyc.map((point) => (
                  <li key={point.id}>
                    <span>{point.titleSub}</span>
                    <span>{point.detail}</span>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>

        <Modal
          className='custom-modal verificationmodal'
          okText='Approve'
          width={500}
          open={showClientSupportModal}
          onOk={handleApproveReason}
          onCancel={handleCancel}
          centered
          closeIcon={true}
          maskClosable={false}
          footer={null}
        >
          <p style={{ padding: '48px auto 18px auto', textAlign: 'center' }}>
            <h3>
              {' '}
              Are you sure you want to{' '}
              {pendingKycData?.status == 'PENDING'
                ? 'approve?'
                : pendingKycData?.status == 'RESOLVED' && 'pending'}{' '}
            </h3>
          </p>
          <TextArea
            rows={4}
            placeholder='Enter Reason'
            value={approveReason}
            onChange={(e) => setApproveReason(e.target.value)}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Space size={20}>
              <ButtonCommon text='Cancel' onClick={handleCancel} />
              <ButtonCommon
                type='primary'
                text='Approve'
                onClick={handleApproveReason}
              />
            </Space>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default DetailInfo;
